import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    width: '100%',
  },

  '@media (min-width: 600px)': {
    '& .MuiDrawer-paper': {
      width: '361px',
    },
  },

  '@media (min-width: 1024px)': {
    '& .MuiDrawer-paper': {
      width: '480px',
    },
  },
}));
