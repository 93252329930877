import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { H1, H5, P3 } from 'components/common/typography/styles';
import RegisterForm from 'components/common/forms/register';
import colors from 'theme/colors';
import api from 'services/api/api';
import { Strings } from 'constants/strings';
import { AuthContainer } from '../index.styled';

interface GuestUserDetails {
  email: string;
  cuenta: string;
}

const Register: React.FC = observer(() => {
  const [searchParams] = useSearchParams();
  const [guestUser, setGuestUser] = useState<GuestUserDetails>({
    email: '',
    cuenta: '',
  });

  const token = searchParams.get('codigo')?.split('?')[0];
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getGuestUser = async () => {
    if (token) {
      const response = await api.invite.getInvitation(token);
      if (response.kind === 'token-not-found-or-invalid') {
        enqueueSnackbar(Strings.notifications.expiredTokenInvite, {
          variant: 'error',
        });
        navigate('/portal/iniciar-sesion', { replace: true });
      }
      const userDetail = { ...response };
      setGuestUser(userDetail);
    }
  };

  useEffect(() => {
    getGuestUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContainer>
      <Box className="wrapper-auth">
        <H1 className="activate-title">Activá tu cuenta Agrology</H1>
        <P3 style={{ marginBottom: '10px', width: '100%' }}>Invitado por</P3>
        <H5
          style={{
            marginBottom: '16px',
            color: colors.primaryBase,
            width: '100%',
          }}
        >
          {guestUser.cuenta}
        </H5>
        {guestUser.email && (
          <RegisterForm email={guestUser.email} token={token} />
        )}
      </Box>
    </AuthContainer>
  );
});

export default Register;
