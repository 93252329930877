import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import colors from 'theme/colors';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { H5, P2 } from '../../typography/styles';
import Button from '../../buttons/button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type Props = {
  description: string;
  open: boolean;
  handleClose: () => void;
  title: string | null;
  icon: React.ReactNode;
  withButton: boolean;
  url: string;
};

export const OnesOilModal = ({
  open,
  description,
  handleClose,
  title,
  icon,
  withButton,
  url,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      scroll="paper"
      PaperProps={{ elevation: 24 }}
      maxWidth="xs"
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}
      >
        <Box>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: colors.tertiaryLow }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack
          gap="24px"
          mb="20px"
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          {icon}
          {title && <H5>{title}</H5>}
          <P2 color={colors.tertiaryMedium}>{description}</P2>
          {withButton && (
            <Button
              variant="contained"
              text="Conócelo"
              preset="normal"
              size="small"
              onClick={() => {
                window.open(url);
                handleClose();
              }}
              startIcon={<OpenInNewIcon sx={{ color: '#fff' }} />}
            />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

