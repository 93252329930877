import { DarumaAction, InitialStateDaruma } from './index.types';

export default function darumaReducer(
  initialValues: InitialStateDaruma,
  action: DarumaAction,
) {
  switch (action.type) {
    case 'set-body': {
      return { ...initialValues, body: action.value };
    }

    case 'set-firmante': {
      return { ...initialValues, firmante: action.value };
    }

    case 'set-countries': {
      return { ...initialValues, countries: action.value };
    }

    case 'set-cuit': {
      return { ...initialValues, cuit: action.value };
    }

    case 'set-cuits': {
      return { ...initialValues, cuits: action.value };
    }

    case 'set-isCompleted': {
      return { ...initialValues, isCompleted: action.value };
    }

    case 'set-stateProcedure': {
      return { ...initialValues, stateProcedure: action.value };
    }

    case 'set-refused': {
      return { ...initialValues, refused: action.value };
    }

    case 'set-moveForward': {
      return { ...initialValues, moveForward: action.value };
    }

    case 'set-representanteStep': {
      return { ...initialValues, representanteStep: action.value };
    }

    case 'set-estadoSolicitudBody': {
      return { ...initialValues, estadoSolicitudBody: action.value };
    }

    case 'set-productName': {
      return { ...initialValues, productName: action.value };
    }

    case 'set-productsIds': {
      return { ...initialValues, productsIds: action.value };
    }

    case 'set-fromArgenpymes': {
      return { ...initialValues, fromArgenpymes: action.value };
    }

    case 'set-infoAdicionalSplit': {
      return { ...initialValues, infoAdicionalSplit: action.value };
    }

    case 'set-empresaSolicitante': {
      return { ...initialValues, empresaSolicitante: action.value };
    }

    case 'set-representante': {
      return { ...initialValues, representante: action.value };
    }

    default:
      return initialValues;
  }
}
