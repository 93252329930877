import styled from 'styled-components';
import { P3, P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { MenuItem } from '@mui/material';

export const ItemSelectP4 = styled(P4)`
  max-width: 280px;
  width: auto;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: inherit;
`;

export const ItemSelectP3 = styled(P3)`
  max-width: 280px;
  width: 100%;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: inherit;
`;

export const ItemSelectContainer = styled(MenuItem)`
  &.MuiMenuItem-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 16px;
    color: ${colors.tertiaryBase};

    &:hover {
      background-color: ${colors.white};
    }
    &:focus {
      background-color: ${colors.white};
      color: ${colors.primaryBase};
    }
  }

  &:hover {
    color: ${colors.primaryBase};
  }
`;
