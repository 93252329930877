import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import api from 'services/api/api';
import { NewPasswordValidationSchema } from 'utils/schema-validations';
import { useSnackbar } from 'notistack';
import { Strings } from 'constants/strings';
import DynamicForm from '../dynamic';
import { FieldNames, inputsForm } from './index.inputs';
import { PasswordsForm } from 'models/dynamicForms';

const NewPasswordForm: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    [FieldNames.fieldPassword]: '',
    [FieldNames.fieldRepeatPassword]: '',
  };

  const token = searchParams.get('codigo');

  const modifyPassword = async (values: PasswordsForm) => {
    if (values.password === values.repeatNewPassword && token) {
      const body = {
        password: values.password,
        token,
      };
      const response = await api.passwords.newPassowrd(body);
      if (response.kind === 'token-not-found-or-invalid') {
        enqueueSnackbar(Strings.notifications.tokenNotFoundOrInvalid, {
          variant: 'error',
        });
      }
      if (response.status && response.status === 'password_reseted') {
        enqueueSnackbar(Strings.notifications.success, {
          variant: 'success',
        });
        navigate('/portal/iniciar-sesion');
      }
    }
  };

  return (
    <DynamicForm
      initialValues={initialValues}
      inputs={inputsForm}
      handleSubmitFormData={(data) => modifyPassword(data as PasswordsForm)}
      validationSchema={NewPasswordValidationSchema}
    />
  );
};

export default NewPasswordForm;
