import { Box } from '@mui/material';
import api from 'services/api/api';
import { useData } from 'hooks/useData';
import { ICarrusel } from 'store/home-model/types';
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import { SwiperHome, SwiperSlideHome } from 'theme/carruselStyles';
import prevButton from 'assets/icons/arrowPrev.svg';
import nextButton from 'assets/icons/arrowNext.svg';
import BannerArgenpymes from './container';

export const Carrusel = () => {
  const fetcher = async () => {
    const res = await api.home.getCarrusel();
    return res;
  };

  const { data } = useData(fetcher, 'carrusel');
  const response: ICarrusel[] = data;

  return (
    <Box width="100%">
      {response && (
        <SwiperHome
          buttonprev={prevButton}
          buttonnext={nextButton}
          navigation
          pagination={{ clickable: true }}
          loop={response.length > 1}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          centeredSlides
          modules={[Navigation, Pagination, Autoplay]}
          slidesPerView={1}
        >
          {response.map((item: ICarrusel) => {
            return (
              <SwiperSlideHome key={item.imagen}>
                <BannerArgenpymes
                  linkBoton={item.linkBoton}
                  imagenMobile={item.imagenMobile}
                  imagen={item.imagen}
                />
              </SwiperSlideHome>
            );
          })}
        </SwiperHome>
      )}
    </Box>
  );
};