import React from 'react';
import styled from 'styled-components';
import SpinnerBigImg from 'assets/icons/spinnerBig.png';
import SpinnerSmallImg from 'assets/icons/spinnerSmall.png';
import SpinnerBigWhite from 'assets/icons/spinnerBigWhite.png';
import SpinnerSmallWhite from 'assets/icons/spinnerSmallWhite.png';

const SpinnerBig = () => {
  return (
    <img
      loading="lazy"
      src={SpinnerBigImg}
      alt="spinner"
      className="rotating"
    />
  );
};

interface SpinnerSMProps {
  width?: string;
}
export const SpinnerSM = ({ width }: SpinnerSMProps) => {
  return (
    <SpinnerSmall
      width={width}
      src={SpinnerSmallImg}
      alt="spinner"
      className="rotating"
    />
  );
};

const SpinnerBigW = () => {
  return (
    <img
      loading="lazy"
      src={SpinnerBigWhite}
      alt="spinner"
      className="rotating"
    />
  );
};

const SpinnerSmallW = () => {
  return (
    <img
      loading="lazy"
      src={SpinnerSmallWhite}
      alt="spinner"
      className="rotating"
    />
  );
};

export const SpinnerXL = styled(SpinnerBig)`
  height: 40;
  width: 40;
`;

export const SpinnerGreenXL = ({
  height,
  width,
}: {
  height: string;
  width: string;
}) => {
  return (
    <img
      loading="lazy"
      src={SpinnerBigImg}
      alt="spinner"
      className="rotating"
      width={width}
      height={height}
    />
  );
};

interface SpinnerSmallProps {
  width?: string;
}

export const SpinnerSmall = styled.img<SpinnerSmallProps>`
  height: 20;
  width: ${(props) => {
    return props.width ? props.width : 20;
  }};
`;

export const SpinnerXLW = styled(SpinnerBigW)`
  height: 40;
  width: 40;
`;

export const SpinnerSMW = styled(SpinnerSmallW)`
  height: 20;
  width: 20;
`;

SpinnerSM.defaultProps = {
  width: 20,
};
