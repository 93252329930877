import { lazy } from 'react';

export const LazySeleccionarEmpresaScreen = lazy(
  () => import('./SeleccionarEmpresa'),
);
export const LazyProductosScreen = lazy(() => import('./SeleccionarProductos'));
export const LazySolicitudEnviada = lazy(() => import('./EnvioSolicitud'));
export const LazySolicitudFinalizada = lazy(
  () => import('./SolicitudFinalizada'),
);
