import React, { createContext, useReducer } from 'react';
import argenpymesReducer from './index.reducer';
import { ArgenpymesDispatch, InitialStateArgenpymes } from './index.types';
import { initialStateArgenpymes } from './initialValues';

export const ArgenpymeStateContext = createContext<InitialStateArgenpymes>(
  initialStateArgenpymes,
);
export const ArgenpymeDispatchContext = createContext<ArgenpymesDispatch>(
  {} as ArgenpymesDispatch,
);

interface IAgenpymesContext {
  children: React.ReactNode;
}

export const ArgenpymeContext: React.FC<IAgenpymesContext> = ({ children }) => {
  const [darumaValues, dispatch] = useReducer(
    argenpymesReducer,
    initialStateArgenpymes,
  );
  return (
    <ArgenpymeDispatchContext.Provider value={dispatch}>
      <ArgenpymeStateContext.Provider value={darumaValues}>
        {children}
      </ArgenpymeStateContext.Provider>
    </ArgenpymeDispatchContext.Provider>
  );
};
