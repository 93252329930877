import React from 'react';
import WindDir from 'assets/icons/icons-weather/dir-viento.svg';
import { P4 } from 'components/common/typography/styles';
import { ContainerWind } from './weather.styled';
import { IWind } from './typesWeather';

export const WindComponent: React.FC<IWind> = ({
  windDirection,
  windSpeed,
}) => {
  return (
    <ContainerWind>
      <img
        style={{
          transform: `rotate(${windDirection}deg)`,
          marginRight: '6px',
        }}
        src={WindDir}
        alt=""
        width="10px"
        height="10px"
        loading="lazy"
      />
      <P4>{`${Math.round(windSpeed)}km/h`}</P4>
    </ContainerWind>
  );
};
