import React, { Suspense, useMemo, useState } from 'react';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import PrivateRoute from 'components/layouts/privateRoute';
import { RootStoreProvider } from 'store/root-store/root-store-context';
import {
  Navigate,
  Route,
  Routes,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { es } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material';
import Auth from 'screens/portal';
import Main from 'screens';
import './App.css';
import OnesOilSnackbar from 'components/common/onesOilSnackBar';
import persist from 'mst-persist';
import api from 'services/api/api';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import ErrorBoundaryUI from 'components/layouts/errorBoundary';
import { SnackbarProvider } from 'notistack';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import TagManager from 'react-gtm-module';
import useWindowDimensions from 'hooks/useWindowsSize';
import MobileErrorScreen from 'components/sections/errorMsgScreen/mobileErrorScreen';
import { observer } from 'mobx-react';
import Maintenance from 'screens/mantenimiento';
import {
  SplitFactory,
  withSplitFactory,
  SplitTreatments,
} from '@splitsoftware/splitio-react';
import { rootStore } from 'store/root-store/root-store';
import rollbarConfig from './utils/rollbar-config';
import { agroTheme } from 'theme';
import ValidateMobileToken from 'screens/validationTokenMobile';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobileS: true;
    mobileM: true;
    mobileL: true;
    tabletS: true;
    tablet: true;
    laptop: true;
    laptopL: true;
  }
}

declare module 'notistack' {
  interface VariantOverrides {
    onesOil: {
      url: string;
    };
  }
}

const CONFIG = {
  core: {
    authorizationKey: import.meta.env.VITE_SPLIT_AUTH_TOKEN,
    key: 'agrology',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RenderContent: React.FC<{ treatments: any; width: number }> = observer(
  ({ treatments, width }) => {
    const maintenance = treatments.maintenance.treatment;
    const mobile = treatments.mobile.treatment;

    if (maintenance === 'on') {
      return (
        <Routes>
          <Route path="/" element={<Maintenance />} />
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
      );
    }
    if (width >= 1024 || mobile === 'on') {
      return (
        <Routes>
          <Route path="/portal/*" element={<Auth />} />
          <Route path="/mobile" element={<ValidateMobileToken />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Main treatments={treatments} />
              </PrivateRoute>
            }
          />
        </Routes>
      );
    }

    return (
      <Routes>
        <Route path="/*" element={<MobileErrorScreen />} />
      </Routes>
    );
  },
);

const Root: React.FC = observer(() => {
  const resize = useWindowDimensions();
  const [appReady, setAppReady] = useState(false);

  persist('agrology', rootStore)
    .then(() => {
      if (rootStore.sessionStore.token && rootStore.sessionStore.token !== '') {
        api.api.setToken(rootStore.sessionStore.token);
      }
      setAppReady(true);
    })
    .catch(() => {
      // En caso de error, limpiamos el storage para evitar inconsistencias
      rootStore.clearStorage();
    });

  const tagManagerArgs = {
    gtmId:
      import.meta.env.VITE_ENVIRONMENT === 'production'
        ? import.meta.env.VITE_GTAG
        : '',
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => TagManager.initialize(tagManagerArgs), []);
  if (!appReady) {
    return <SpinnerXL />;
  }

  const featureName = [
    'maintenance',
    'financing',
    'insurance',
    'mobile',
    'tabLotes',
    'argenpymes',
    'clima',
    'bannerDaruma',
    'oneSoil',
  ];

  const treatmentAttributes = {
    deal_size: 10000,
  };

  return (
    <ThemeProvider theme={agroTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <RollbarProvider config={rollbarConfig}>
          <ErrorBoundary fallbackUI={ErrorBoundaryUI as any}>
            <SnackbarProvider
              maxSnack={3}
              Components={{
                onesOil: OnesOilSnackbar,
              }}
            >
              <RootStoreProvider value={rootStore}>
                <Suspense fallback={SpinnerXL}>
                  <SplitFactory config={CONFIG} updateOnSdkUpdate>
                    <SplitTreatments
                      names={featureName}
                      attributes={treatmentAttributes}
                    >
                      {({ isReady, treatments }) =>
                        isReady ? (
                          <RenderContent
                            treatments={treatments}
                            width={resize.width}
                          />
                        ) : null
                      }
                    </SplitTreatments>
                  </SplitFactory>
                </Suspense>
              </RootStoreProvider>
            </SnackbarProvider>
            <div id="snackbarhelper" />
          </ErrorBoundary>
        </RollbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
});

const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

// 4️⃣ RouterProvider added
const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default withSplitFactory(CONFIG)(App);
