import React from 'react';
import {
  CardMedia,
  IconButton,
  Box,
  Stack,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { H3, H4, H5, P4 } from 'components/common/typography/styles';
import MdToMui from 'components/common/mdToMui';
import colors from 'theme/colors';
import { DialogContainer, DialogHeader, DialogBody } from './index.styled';
import { useStores } from 'store/root-store/root-store-context';
import Button from '../buttons/button';
import { useNavigate } from 'react-router-dom';
import ComputerIcon from '@mui/icons-material/Computer';
import { isProcessingAllowed } from 'utils/AllowedProcedures';

interface ProcedureModalProps {
  imgHeader: string;
  redirectTo: string;
  markdownTemplate: string;
  logos: string[] | null | undefined;
  title: string;
  subtitle: string;
  open: boolean;
  onClose: () => void;
  onlyRead: boolean;
  alias: string;
}

const ProcedureModal: React.FC<ProcedureModalProps> = ({
  imgHeader,
  redirectTo,
  markdownTemplate,
  logos,
  title,
  subtitle,
  open,
  onClose,
  onlyRead,
  alias,
}) => {
  const LOGO_RISKGROUP = '/media/entidad_logo/Logo_RiskGroupArg.png';
  const {
    cuitsStore: { cuitsByEstados },
  } = useStores();
  const navigate = useNavigate();
  const isNotDesktop = useMediaQuery('(max-width:768px)');

  const approvedCompanies = cuitsByEstados();

  return (
    <>
      <DialogContainer open={open} onClose={onClose} scroll="body">
        <DialogHeader
          sx={{
            backgroundImage: `url(${
              import.meta.env.VITE_API_BASE_URL
            }${imgHeader})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top-center',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0.59) 0%, rgba(0, 0, 0, 0.00) 100%)',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            },
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 16,
              right: 27,
              color: colors.white,
            }}
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
          <Stack
            direction="row"
            alignItems="center"
            gap="8px"
            sx={{ zIndex: 1000 }}
          >
            <Stack direction="row" gap="14px">
              {logos?.map((logo) => (
                <CardMedia
                  sx={{
                    width: 'auto',
                    maxHeight: '48px',
                    marginTop: logo === LOGO_RISKGROUP ? '0px' : '8px',
                  }}
                  component="img"
                  image={`${import.meta.env.VITE_API_BASE_URL}${logo}` || ''}
                  key={logo}
                />
              ))}
            </Stack>
            <H5 style={{ color: colors.white }}>{subtitle}</H5>
          </Stack>
          <Box
            display={{
              xs: 'none',
              laptop: 'block',
            }}
            zIndex={1000}
          >
            <H3
              style={{
                color: colors.white,
                margin: '16px 0 8px 0',
                zIndex: 1000,
              }}
            >
              {title}
            </H3>
          </Box>
          <Box
            display={{
              xs: 'block',
              laptop: 'none',
            }}
          >
            <H4
              style={{
                color: colors.white,
                margin: '8px 0',
                zIndex: 1000,
              }}
            >
              {title}
            </H4>
          </Box>
        </DialogHeader>
        <DialogBody>
          <Box my="16px">
            <MdToMui md={markdownTemplate} />
          </Box>

          <Box textAlign="center">
            {!isNotDesktop || !isProcessingAllowed(alias) ? (
              <>
                {!onlyRead ? (
                  <Button
                    preset="normal"
                    size="large"
                    text="Iniciar solicitud"
                    variant="contained"
                    type="button"
                    disabled={approvedCompanies.length <= 0}
                    onClick={() => {
                      onClose();
                      navigate(redirectTo);
                    }}
                  />
                ) : null}
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                gap="8px"
                mt="12px"
              >
                <ComputerIcon sx={{ color: colors.tertiaryMedium }} />
                <P4
                  color={colors.tertiaryMedium}
                  style={{ maxWidth: '280px', marginTop: '2px' }}
                >
                  Visitá <b>app.agrology.com</b> desde tu computadora para
                  iniciar o ver el detalle de los trámites.
                </P4>
              </Box>
            )}
          </Box>
        </DialogBody>
      </DialogContainer>
    </>
  );
};

export default ProcedureModal;
