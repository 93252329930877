import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import colors from 'theme/colors';
import Button from '../buttons/button';
import { H5, P3 } from '../typography/styles';

export interface IConfirmMsgProps {
  body: string;
  open: boolean;
  title: string;
  textBtnRejection: string;
  textBtnSuccess: string;
  onClose: () => void;
  onClick: () => void;
  onClickButtonRejection: () => void;
}

export const ConfirmAbandonment: React.FC<IConfirmMsgProps> = ({
  body,
  open,
  textBtnRejection,
  textBtnSuccess,
  title,
  onClick,
  onClose,
  onClickButtonRejection,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ p: '24px' }}>
        <H5 color={colors.complementaryGreen}>{title}</H5>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <P3 color={colors.tertiaryMedium}>{body}</P3>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: '8px 24px 24px 24px' }}>
        <Button
          variant="outlined"
          preset="danger"
          size="small"
          onClick={onClickButtonRejection}
          text={textBtnRejection}
        />
        <Button
          variant="contained"
          preset="normal"
          size="small"
          onClick={onClick}
          text={textBtnSuccess}
        />
      </DialogActions>
    </Dialog>
  );
};
