import { Box, Grid } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { H5 } from 'components/common/typography/styles';
import React, { useState } from 'react';
import api from 'services/api/api';
import { useStores } from 'store/root-store/root-store-context';
import colors from 'theme/colors';
import ProcedureProcessCard from 'components/common/cards/procedures/Process';
import { useData } from 'hooks/useData';
import { ProcedureInProcess } from 'models/procedure';
import { ProceduresEmpty } from '../proceduresEmpty/ProceduresEmpty';
import {
  ARRAY_EMPTY,
  PROCEDURES_ONPROCESS_EMPTY,
} from '../proceduresEmpty/messages.procedures';
import Button from 'components/common/buttons/button';

type TProceduresOnProcess = {
  setOpenAlert: (v: boolean) => void;
};

export const ProceduresOnProcess = ({ setOpenAlert }: TProceduresOnProcess) => {
  const { sessionStore } = useStores();
  const fetcher = async () =>
    // eslint-disable-next-line no-return-await
    await api.procedure.getProcedures('certificacion', 0);
  const { data, isLoading } = useData(
    fetcher,
    `procedureOnProcessCerts/${sessionStore?.currentUser?.cuentaActual}`,
  );

  const [showAll, setShowAll] = useState(false);

  const handleShow = () => {
    setShowAll(!showAll);
  };
  const displayedData = showAll ? data : data?.slice(0, 9);

  return (
    <>
      <H5 id="cert-inprocess-title" style={{ color: colors.tertiaryMedium }}>
        En proceso
      </H5>
      {(data !== undefined && data.length === ARRAY_EMPTY) ||
      (data === undefined && !isLoading) ? (
        <ProceduresEmpty message={PROCEDURES_ONPROCESS_EMPTY} />
      ) : (
        <Grid
          height={isLoading ? '251px' : ''}
          container
          alignItems="stretch"
          spacing="12px"
          mt="8px"
          mb="24px"
        >
          {isLoading && data === undefined ? (
            <Box
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                height: '40px',
                margin: 'auto',
              }}
            >
              <SpinnerXL />
            </Box>
          ) : (
            displayedData.map((procedure: ProcedureInProcess) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={`${procedure.codigo}-certifications`}
                >
                  <ProcedureProcessCard
                    trace={procedure}
                    waitingTime={[
                      'envio_solicitud',
                      'validacion_campos',
                    ].includes(procedure.estado)}
                    procedure="certificaciones"
                    handleOnAlert={() => setOpenAlert(true)}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      )}
      {data?.length > 9 && (
        <Button
          text={showAll ? 'Ver menos' : `Ver más (${data.length - 9})`}
          preset="normal"
          size="large"
          onClick={handleShow}
          sx={{ width: '100%' }}
          variant="outlined"
        />
      )}
    </>
  );
};
