import styled from 'styled-components';
import { Drawer } from '@mui/material';
import colors from 'theme/colors';

export const StyledMapWrapper = styled(Drawer)`
  .drawer-container {
    width: 90vw;
    display: flex;
    @media (min-width: 1750px) {
      min-width: 1440px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      overflow-y: hidden;
      width: 100vw;
    }

    @media (min-width: 768px) {
      display: flex;
    }
  }

  .drawer-title {
    margin-bottom: 8px;
    color: ${colors.tertiaryMedium};
    @media (max-width: 767px) {
      color: ${colors.white};
      margin-bottom: 0px;
    }
  }

  .drawer-description {
    color: ${colors.tertiaryMedium};
    max-width: 1060px;
  }

  .drawer-header {
    height: 80px;
    padding: 22px 19px;
    background-color: ${colors.tertiaryHigh};
    @media (max-width: 767px) {
      padding: 10px 0px 10px 6px;
      position: absolute;
      top: 0;
      left: 0;
      justify-content: space-between;
      display: flex;
      align-items: center;
      width: 68%;
      max-width: 400px;
      z-index: 1;
      height: 50px;
      background-color: transparent;
    }
  }
`;
