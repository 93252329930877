/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-await */
/* eslint-disable prettier/prettier */
import React from 'react';
import { Box } from '@mui/material';
import useSWRImmutable from 'swr/immutable';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { SwiperLoginHome, SwiperSlideLoginHome } from 'theme/carruselStyles';
import buttonNext from 'assets/icons/arrowNext.svg';
import buttonPrev from 'assets/icons/arrowPrev.svg';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  StyledContainer,
  StyledSliderLogin,
  StyledWrapper,
} from './index.styled';
import { SpinnerXL } from 'components/common/icon/icons/spinner';

const API_URL = import.meta.env.VITE_API_BASE_URL || 'https://api.agrology.com';
const LOGIN_CAROUSEL_URL = `${API_URL}/agrology/carrousel/?categoria=login`;
const getCarousel = (url: string) => fetch(url).then((r) => r.json());

const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data, isLoading } = useSWRImmutable(LOGIN_CAROUSEL_URL, getCarousel, {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      if (error.status === 404 || error.status === 400)
        return revalidate({ retryCount });
      // Only retry up to 10 times.
      if (retryCount >= 5) return;

      // Retry after 1 second.
      setTimeout(() => revalidate({ retryCount }), 1000);
    },
  });
  const width = useMediaQuery('(max-width:767px)');

  return (
    <StyledContainer>
      {!isLoading && data !== undefined ? (
        <>
          <SwiperLoginHome
            lengtharr={data.data.length}
            navigation
            buttonprev={buttonPrev}
            buttonnext={buttonNext}
            pagination={{ clickable: true }}
            loop={data.data.length > 1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            centeredSlides
            modules={[Navigation, Pagination, Autoplay]}
            slidesPerView={1}
          >
            {data.data.map((item: any) => {
              return (
                <SwiperSlideLoginHome key={item.imagen}>
                  <StyledSliderLogin>
                    <Box className="logo">
                      <img
                        src={`${import.meta.env.VITE_API_BASE_URL}${
                          width ? item.imagen_mobile : item.imagen
                        }`}
                        alt="carousel-img"
                        className="slider-img"
                        loading="lazy"
                      />
                    </Box>
                  </StyledSliderLogin>
                </SwiperSlideLoginHome>
              );
            })}
          </SwiperLoginHome>
        </>
      ) : (
        <Box className="spinner-logo">
          <SpinnerXL />
        </Box>
      )}

      <StyledWrapper>
        <Box margin="auto" className="children-wrapper">
          {children}
        </Box>
      </StyledWrapper>
    </StyledContainer>
  );
};

export default AuthLayout;