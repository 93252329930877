import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Title } from '../alerts/index.styled';
import colors from 'theme/colors';
import { P3 } from '../typography/styles';
import ModifyFieldNameForm from '../forms/modifyFieldName';

type Props = {
  open: boolean;
  name: string;
  handleClose: () => void;
  handleChangeName: (name: string) => void;
};

const ModifyFieldNameModal = ({
  open,
  name,
  handleClose,
  handleChangeName,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      PaperProps={{ elevation: 24 }}
    >
      <DialogTitle sx={{ paddingX: '34px' }}>
        <Title sx={{ color: colors.complementaryGreen }}>
          Modificar nombre
        </Title>
      </DialogTitle>

      <DialogContent
        sx={{
          paddingX: '34px',
          width: {
            md: 'calc(482px - 68px)',
          },
        }}
      >
        <P3 color={colors.tertiaryMedium}>
          Ingresa en nuevo nombre del campo:
        </P3>
        <ModifyFieldNameForm
          name={name}
          handleClose={handleClose}
          handleChangeName={handleChangeName}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ModifyFieldNameModal;
