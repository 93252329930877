/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { ChevronRight } from '@mui/icons-material';
import TextInput from 'components/common/inputs/text';
import api from 'services/api/api';
import {
  ChangePasswordSchema,
  CreatePasswordSchema,
} from 'utils/schema-validations';
import { useSnackbar } from 'notistack';
import { Strings } from 'constants/strings';
import { Box, useMediaQuery } from '@mui/material';
import Button from 'components/common/buttons/button';
import {
  capitalLetter,
  checks,
  hasNumber,
  moreEightChar,
  lowercaseLetter,
  pipe,
  specialChar,
} from 'utils/checklistPassUtils';
import { CheckItem } from 'utils/types';
import { TooltipPassword } from '../tooltipPassword/TooltipPassword';
import { ChekcListPass } from '../tooltipPassword/ChekcListPass';
import { useStores } from 'store/root-store/root-store-context';

enum FieldNames {
  fieldCurrentPassword = 'oldPassword',
  fieldNewPassword = 'newPassword',
  fieldRepeatPassword = 'repeatNewPassword',
}

export interface PasswordsForm {
  [FieldNames.fieldCurrentPassword]: string;
  [FieldNames.fieldNewPassword]: string;
  [FieldNames.fieldRepeatPassword]: string;
}

interface IModifyPasswordFormProps {
  onClose: (value: boolean) => void;
}

const ModifyPasswordForm = (props: IModifyPasswordFormProps) => {
  const { onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [warning, setWarning] = useState<string | undefined>();
  const width = useMediaQuery('(min-width:500px)');
  const [isSubmit, setIsSubmit] = useState(false);
  const [checkListArr, setCheckListArr] = useState<CheckItem[]>(checks);
  const [progress, setProgress] = useState(39);
  const [valuePass, setValuePass] = useState<string>('');
  const [openPopUp, setOpenPopUp] = useState(false);
  const {
    sessionStore,
    sessionStore: { currentUser },
  } = useStores();

  const initialValues = {
    [FieldNames.fieldCurrentPassword]: '',
    [FieldNames.fieldNewPassword]: '',
    [FieldNames.fieldRepeatPassword]: '',
  };

  const createPass = async (newPassword: string) => {
    const response = await api.passwords.createPassword(newPassword);
    if (!response.kind) {
      enqueueSnackbar(Strings.notifications.success, {
        variant: 'success',
      });
      await sessionStore.getMeUser();
      onClose(false);
    }
  };

  const modifyPassword = async (values: PasswordsForm) => {
    if (!currentUser?.hasPassword) return createPass(values.newPassword);

    if (values.newPassword === values.repeatNewPassword) {
      const body = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      const response = await api.passwords.changePassword(body);
      if (!response.kind) {
        enqueueSnackbar(Strings.notifications.success, {
          variant: 'success',
        });
        onClose(false);
      } else if (response.kind === 'bad-data') {
        setWarning('La contraseña que ingresaste es incorrecta');
      }
    }
  };

  const handleFocus = () => {
    setOpenPopUp(true);
  };
  const handleBlur = () => {
    setOpenPopUp(false);
  };

  const handleMatch = () => {
    const iterable = pipe(
      specialChar,
      hasNumber,
      lowercaseLetter,
      capitalLetter,
      moreEightChar,
    )(valuePass);

    const checksListMatched = checks.map((e) => {
      const matchingItem = iterable.find((v) => v.key === e.key);
      if (matchingItem) {
        e.checked = matchingItem.checked;
      }
      return { ...e };
    });
    return checksListMatched;
  };

  const valueProgress = () =>
    checkListArr.filter((e: CheckItem) => e.checked === true);

  const isMobile = useMediaQuery('(max-width:500px)');

  useEffect(() => {
    setCheckListArr(handleMatch());
    setProgress(() => valueProgress().length * 20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuePass, valueProgress().length]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={modifyPassword}
      validationSchema={
        currentUser?.hasPassword ? ChangePasswordSchema : CreatePasswordSchema
      }
      validateOnBlur={false}
      validateOnChange={isSubmit}
    >
      {({ handleSubmit, isSubmitting, values }) => {
        if (isSubmitting && !isSubmit) {
          setIsSubmit(true);
        }
        setValuePass(values?.newPassword);
        return (
          <>
            {currentUser?.hasPassword && (
              <Box margin="24px 0 24px 0">
                <TextInput
                  id={FieldNames.fieldCurrentPassword}
                  label="Contraseña actual"
                  disabled={false}
                  maxLength={16}
                  name={FieldNames.fieldCurrentPassword}
                  width="400px"
                  height="48px"
                  type="password"
                  warning={warning}
                  handleSubmit={handleSubmit}
                  placeholder="Ingresá tu contraseña actual"
                />
              </Box>
            )}
            <TooltipPassword
              open={openPopUp}
              onClose={() => setOpenPopUp(false)}
              onOpen={() => setOpenPopUp(true)}
              title={
                <>
                  <ChekcListPass
                    checksList={checkListArr}
                    progress={progress}
                  />
                </>
              }
              placement={isMobile ? 'top' : 'left-start'}
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Box
                margin={{
                  xs: '0px 0px 40px 0px',
                  mobileM: '0px 0px 22px 0px',
                }}
              >
                <TextInput
                  id={FieldNames.fieldNewPassword}
                  label="Nueva contraseña"
                  disabled={false}
                  maxLength={16}
                  name={FieldNames.fieldNewPassword}
                  width="400px"
                  height="48px"
                  type="password"
                  handleSubmit={handleSubmit}
                  placeholder="Tu nueva contraseña"
                  handleFocus={handleFocus}
                  handleBlur={handleBlur}
                />
              </Box>
            </TooltipPassword>
            <Box marginBottom="24px">
              <TextInput
                id={FieldNames.fieldRepeatPassword}
                label=""
                disabled={false}
                maxLength={16}
                name={FieldNames.fieldRepeatPassword}
                width="400px"
                height="48px"
                type="password"
                handleSubmit={handleSubmit}
                placeholder="Repetí tu contraseña"
              />
            </Box>
            <Box width={width ? '235px' : '100%'} marginBottom="24px">
              <Button
                variant="contained"
                preset="normal"
                size="large"
                onClick={handleSubmit}
                text="Guardar cambios"
                endIcon={<ChevronRight />}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

export default ModifyPasswordForm;
