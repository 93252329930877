import * as React from 'react';
import { StyledLabel, StyledInput, StyledError } from '../index.styled';
import { P3, P4 } from '../../typography/styles';
import colors from 'theme/colors';
import { useField } from 'formik';
import { Mask } from '../mask';
import WarningIcon from '@mui/icons-material/Warning';
import LockIcon from '@mui/icons-material/Lock';

interface InputsCuitProps {
  label: string;
  name: string;
  placeholder: string;
  disabled: boolean;
  id: string;
  width: string;
  height: string;
  handleSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

function CuitInputComponent(props: any) {
  const { inputRef, ...other } = props;

  return <input {...other} ref={inputRef} />;
}

export const InputCuit: React.FC<InputsCuitProps> = ({
  label,
  name,
  placeholder,
  disabled,
  handleSubmit,
}) => {
  const [field, meta] = useField(name);

  return (
    <>
      <StyledLabel>
        <P3 color={colors.tertiaryBase}>{label}</P3>
      </StyledLabel>
      <Mask
        {...field}
        maskChar={null}
        disabled={disabled}
        mask="99-99999999-9"
        alwaysShowMask={false}
      >
        {(props) => (
          <StyledInput
            onKeyPress={(event) =>
              handleSubmit && event.key === 'Enter' && handleSubmit()
            }
            {...props}
            disabled={disabled}
            error={Boolean(meta.touched && meta.error) || undefined}
            inputComponent={CuitInputComponent as any}
            placeholder={placeholder}
          />
        )}
      </Mask>
      {disabled && !meta.error && (
        <LockIcon
          sx={{
            position: 'relative',
            bottom: '38px',
            left: '89%',
            color: colors.tertiaryMedium,
          }}
        />
      )}
      {meta.touched && meta.error && (
        <WarningIcon
          sx={{
            color: colors.complementaryError,
            position: 'absolute',
            right: '18px',
            top: '12px',
          }}
        />
      )}
      {meta.touched && meta.error && (
        <StyledError>
          <P4 color={colors.complementaryError}>{meta.error}</P4>
        </StyledError>
      )}
    </>
  );
};
