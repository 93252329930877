/* eslint-disable consistent-return */
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import {
  LazySeleccionarEmpresaScreen,
  LazyProductosScreen,
} from '../lazyAgree';
import { Box } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { useAgreeContext } from '../agreeUseContext';

export const RoutesWithoutProcedure = () => {
  const {
    state: { routeState },
  } = useAgreeContext();

  if (routeState === 'seleccion_empresa')
    return (
      <ProcedureStepSuspense>
        <LazySeleccionarEmpresaScreen />
      </ProcedureStepSuspense>
    );

  if (routeState === 'seleccion_productos')
    return (
      <ProcedureStepSuspense>
        <LazyProductosScreen />
      </ProcedureStepSuspense>
    );

  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
};
