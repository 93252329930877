/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Box } from '@mui/material';
import { P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { addFormikFieldProps } from 'hooks/useAddFormikFieldProps';
import { StyledLabel, StyledError, StyledTextarea } from '../index.styled';

interface InputTextareaProps {
  id: string;
  label: string;
  disabled: boolean;
  name: string;
  width: string;
  height: string;
  maxLength?: number;
  warning?: string | undefined;
  explanation?: string | undefined;
  placeholder: string;
  handleSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

const InputTextarea: React.FC<InputTextareaProps> = (props) => {
  const {
    id,
    label,
    disabled,
    name,
    width,
    height,
    placeholder,
    warning,
    explanation,
    maxLength,
    handleSubmit,
  } = props;
  const { error, value, onBlur, onChange } = addFormikFieldProps(name);

  return (
    <Box marginBottom={error || explanation || warning ? '16px' : '0px'}>
      <StyledLabel htmlFor={id}>
        <P4>{label}</P4>
      </StyledLabel>
      <Box position="relative" width="auto">
        <StyledTextarea
          disabled={disabled}
          id={id}
          value={value}
          onBlur={onBlur}
          onKeyPress={(event) =>
            handleSubmit && event.key === 'Enter' && handleSubmit()
          }
          onChange={onChange}
          name={name}
          maxLength={maxLength}
          width={width}
          height={height}
          error={error || warning || undefined}
          placeholder={placeholder}
        />
        {error && (
          <WarningIcon
            sx={{
              color: colors.complementaryError,
              position: 'absolute',
              right: '-15px',
              top: '12px',
            }}
          />
        )}
      </Box>
      {(error || warning) && (
        <StyledError>
          <P4 color={colors.complementaryError}>{error || warning}</P4>
        </StyledError>
      )}
      {explanation && (
        <StyledError>
          <P4 color={colors.tertiaryMedium}>{explanation}</P4>
        </StyledError>
      )}
    </Box>
  );
};

InputTextarea.defaultProps = {
  explanation: undefined,
  warning: undefined,
  maxLength: undefined,
  handleSubmit: undefined,
};
export default InputTextarea;
