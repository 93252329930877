import React from 'react';
import { Box, Slide, Stack } from '@mui/material';
import { Caps } from '../typography/styles';
import colors from 'theme/colors';
import ArrowLeftIcon from '../icon/icons/ArrowLeftIcon';

export interface TransitionContainerProps {
  steps: React.ReactElement[];
  activeStep: number;
  direction: 'left' | 'right';
  initialStep?: number;
  hideSteps?: boolean;
  hideGoBack?: boolean;
  unMountOnExit?: boolean;
  handlePrevItem?: () => void;
}

export const TransitionContainer = ({
  steps,
  direction,
  activeStep,
  hideSteps,
  hideGoBack,
  initialStep = 0,
  unMountOnExit = false,
  handlePrevItem,
}: TransitionContainerProps) => {
  return (
    <div>
      <Caps
        color={colors.tertiaryMedium}
        sx={{
          textAlign: 'start',
          width: '100%',
          marginBottom: '12px',
          display: hideGoBack ? 'none' : 'block',
        }}
      >
        <Stack
          width="35%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="8px"
        >
          <Box
            sx={{
              cursor: 'pointer',
              display: activeStep === initialStep ? 'none' : 'block',
            }}
            onClick={handlePrevItem}
          >
            <ArrowLeftIcon
              width="20px"
              height="20px"
              color={colors.tertiaryMedium}
            />
          </Box>
        </Stack>
      </Caps>
      {steps.map((step, index) => (
        <Slide
          direction={direction}
          in={activeStep === index}
          appear={activeStep !== index}
          mountOnEnter
          unmountOnExit={unMountOnExit}
          key={step.key}
          timeout={500}
        >
          <div
            key={step.key}
            style={{ display: index === activeStep ? 'block' : 'none' }}
          >
            {step}
          </div>
        </Slide>
      ))}
    </div>
  );
};
