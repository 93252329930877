import React from 'react';
import { Box } from '@mui/material';
import RecoverAccountForm from 'components/common/forms/recoverAccount';
import { H1, P2 } from 'components/common/typography/styles';
import { AuthContainer } from '../index.styled';

const RecoverAccount: React.FC = () => {
  return (
    <AuthContainer>
      <Box className="wrapper-auth">
        <H1 className="auth-title">Recuperar cuenta</H1>
        <P2 className="auth-parag" style={{ marginBottom: '24px' }}>
          Ingresá la dirección de email que utilizaste para registrarte en
          Agrology para recuperar tu contraseña.
        </P2>
        <RecoverAccountForm />
      </Box>
    </AuthContainer>
  );
};

export default RecoverAccount;
