import useSWR from 'swr';

export const useData = (
  fetcher: (v: number) => Promise<any>,
  catchingName: string,
  options?: any,
) => {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    `/api/user/${catchingName}`,
    fetcher,
    options,
  );

  return {
    data: data?.data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
};
