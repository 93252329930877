import { Box, Stack } from '@mui/material';
import { Formik } from 'formik';
import TextInput from '../inputs/text';
import Button from '../buttons/button';
import { CreateLoteSchema } from 'utils/schema-validations';
import { Perimeter } from 'utils/types';

enum FieldNames {
  loteName = 'name',
}

type Props = {
  polygon: Perimeter | null;
  polygons: Perimeter[];
  handleClose: () => void;
  handleCreate: (name: string) => void;
};

const CreateLoteForm = ({
  polygon,
  polygons,
  handleClose,
  handleCreate,
}: Props) => {
  const validationSchema = CreateLoteSchema(
    polygons.map((p) => p.name.toLocaleLowerCase()),
  );

  const initialValues = {
    [FieldNames.loteName]: polygon?.name || '',
  };

  const createNewLote = (values: { name: string }) => {
    handleCreate(values.name);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={createNewLote}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, isSubmitting, errors }) => {
        return (
          <Stack gap="32px" mt="16px">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="13px"
            >
              <TextInput
                id={FieldNames.loteName}
                label=""
                disabled={false}
                name={FieldNames.loteName}
                width="421px"
                height="48px"
                type="text"
                handleSubmit={handleSubmit}
                placeholder="Lote Soja 01"
              />

              <Box
                width="48px"
                height="48px"
                bgcolor={polygon?.color || '#000'}
                borderRadius="8px"
                mt="4px"
              />
            </Stack>
            <Stack
              sx={{
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
              gap={1}
              justifyContent="space-between"
            >
              <Button
                variant="outlined"
                preset="danger"
                size="large"
                onClick={handleClose}
                text="Descartar"
              />
              <Button
                variant="contained"
                preset="normal"
                size="large"
                onClick={handleSubmit}
                text="Confirmar lote"
                disabled={isSubmitting && !!errors}
                loading={isSubmitting}
              />
            </Stack>
          </Stack>
        );
      }}
    </Formik>
  );
};

export default CreateLoteForm;
