import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'store/root-store/root-store-context';
import { DarumaContainer } from 'screens/financiamiento/daruma';
import { FinanciamientoSimpleContainer } from 'screens/financiamiento/financiamientoSimple';
import { ArgenpymeContext } from 'contexts/Argenpymes';
import { AgreeContext } from 'contexts/Agree';
import { ArgenpymeContainer } from 'screens/financiamiento/argenpymes';
import { AgreeContainer } from 'screens/financiamiento/agree';
import api from 'services/api/api';
import { FinSimpleContext } from 'contexts/FinSimple';
import { DarumaContext } from 'contexts/Daruma';

const FinanceSubtipoRoutes: React.FC = () => {
  const [subtipo, setSubtipo] = React.useState<string | null>(null);
  const { sessionStore } = useStores();
  const { alias } = useParams();
  const navigate = useNavigate();

  const getCurrentProcedure = async () => {
    if (alias) {
      const response = await api.procedure.getSelectedProcedure(alias);
      if (!(response && response.alias === alias)) {
        navigate('/', { replace: true });
      }
      setSubtipo(response.subTipoTramite.alias);
    }
  };

  useEffect(() => {
    getCurrentProcedure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);

  return (
    <Routes>
      {subtipo === 'financiamiento_simple' && (
        <Route
          path="/*"
          element={
            sessionStore.isUserAdmin ? (
              <FinSimpleContext>
                <FinanciamientoSimpleContainer />
              </FinSimpleContext>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      )}
      {subtipo === 'daruma' && (
        <Route
          path="/*"
          element={
            sessionStore.isUserAdmin ? (
              <DarumaContext>
                <DarumaContainer />
              </DarumaContext>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      )}
      {subtipo === 'argenpymes' && (
        <Route
          path="/*"
          element={
            sessionStore.isUserAdmin ? (
              <ArgenpymeContext>
                <ArgenpymeContainer />
              </ArgenpymeContext>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      )}
      {subtipo === 'agree' && (
        <Route
          path="/*"
          element={
            sessionStore.isUserAdmin ? (
              <AgreeContext>
                <AgreeContainer />
              </AgreeContext>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      )}
    </Routes>
  );
};

export default observer(FinanceSubtipoRoutes);
