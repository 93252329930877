import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Divider } from '@mui/material';
import Content from 'components/layouts/content';
import { H3, P3 } from 'components/common/typography/styles';
import ProcedureModal from 'components/common/modals/procedureModal';
import { useStores } from 'store/root-store/root-store-context';
import colors from 'theme/colors';
import useWindowDimensions from 'hooks/useWindowsSize';
import DesktopAlert from 'components/common/alerts/desktopAlert';
import { ProceduresOnProcess } from 'components/sections/procedures/insurance/ProceduresOnProcess';
import { ProceduresFinished } from 'components/sections/procedures/insurance/ProceduresFinished';
import { Coverages } from 'components/sections/procedures/insurance/Coverages';
import { ProcedureAvailable } from 'models/procedure';
import UploadCompanyModal from 'components/common/modals/uploadCompanyModal';
import { useSancorContext } from 'contexts/Sancor/useSancorContext';

const InsuranceScreen: React.FC = observer(() => {
  const { procedureHeaderStore } = useStores();
  const { dispatch } = useSancorContext();
  const [openAlert, setOpenAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [openUploadCompanies, setOpenUploadCompanies] = useState(false);
  const [insuranceSelected, setInsuranceSelected] =
    useState<ProcedureAvailable | null>(null);
  const { width } = useWindowDimensions();
  const {
    sessionStore: { isExternal },
    cuitsStore: { cuitsByEstados },
  } = useStores();

  const approvedCompanies = cuitsByEstados();

  const handleClose = () => {
    setInsuranceSelected(null);
    setOpen(false);
  };

  const handleOpen = (
    seguro: ProcedureAvailable,
    isProcedureInfo?: boolean,
  ) => {
    setProcedureParams(seguro);
    setInsuranceSelected(seguro);
    if (isProcedureInfo) {
      setOpen(true);
    } else if (approvedCompanies?.length <= 0) {
      setOpenUploadCompanies(true);
    } else {
      setOpen(true);
    }
  };

  const setProcedureParams = (procedure: ProcedureAvailable) => {
    procedureHeaderStore.setProcedure('/seguros', 'Seguros', procedure.nombre);
    procedureHeaderStore.setAliasProduct({
      alias: procedure.alias,
      nombre: procedure.nombre,
    });
    if (procedure.extrasproducto) {
      dispatch({
        type: 'set-nameInsurance',
        value: procedure.extrasproducto.aseguradora.nombre,
      });
    }
  };

  const logos: string[] = [];

  if (insuranceSelected?.logo) {
    logos.push(insuranceSelected.logo);
  }

  if (insuranceSelected?.entidad.logo) {
    logos.push(insuranceSelected.entidad.logo);
  }

  return (
    <Content justifyContent="flex-start">
      <>
        {(isExternal || approvedCompanies?.length <= 0) && (
          <UploadCompanyModal
            imgHeader={insuranceSelected?.imgHeader ?? ''}
            redirectTo="/micuenta"
            logos={logos}
            title={insuranceSelected?.nombre ?? ''}
            subtitle={insuranceSelected?.entidad.nombre ?? ''}
            open={openUploadCompanies}
            onClose={() => setOpenUploadCompanies(false)}
            onlyRead={false}
            width={1150}
          />
        )}
        {insuranceSelected && (
          <ProcedureModal
            imgHeader={insuranceSelected.imgHeader}
            redirectTo={`/seguros/${insuranceSelected.alias}`}
            markdownTemplate={insuranceSelected.markdownTemplate}
            logos={logos}
            title={insuranceSelected.nombre}
            subtitle={insuranceSelected.entidad.nombre}
            open={open}
            onClose={handleClose}
            onlyRead={false}
            alias={insuranceSelected.alias}
          />
        )}
        <Box paddingBottom="24px" width="100%">
          <Box mb="16px">
            <Box marginBottom="16px">
              <H3 color={colors.tertiaryLow}>Seguros</H3>
            </Box>
            <Box marginBottom="24px">
              <P3 style={{ width: '100% ' }}>
                Te brindamos diferentes oportunidades para asegurar tus cultivos
                y activos de tu empresa.
              </P3>
            </Box>

            <Coverages
              width={width}
              handleOpen={handleOpen}
              setProcedureParams={setProcedureParams}
            />
          </Box>

          <Divider />

          <ProceduresOnProcess handleOnAlert={setOpenAlert} />
          <ProceduresFinished handleOnAlert={setOpenAlert} />
        </Box>

        <DesktopAlert
          showModal={openAlert}
          handleClose={() => setOpenAlert(false)}
        />
      </>
    </Content>
  );
});

export default InsuranceScreen;
