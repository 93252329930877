import React from 'react';
import { SvgProps } from '..';

const CompraIcon: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16'}
      height={height || '17'}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M2 1.65234H4C4.18667 1.65234 4.35333 1.72568 4.47333 1.84568L5.86 3.23901L6.39333 2.71234C6.66667 2.45234 7 2.31901 7.33333 2.31901H11.3333C11.6667 2.31901 12 2.45234 12.2733 2.71234L12.94 3.37901C13.2 3.65234 13.3333 3.98568 13.3333 4.31901V12.9857C13.3333 13.3393 13.1929 13.6784 12.9428 13.9285C12.6928 14.1785 12.3536 14.319 12 14.319H5.33333C4.97971 14.319 4.64057 14.1785 4.39052 13.9285C4.14048 13.6784 4 13.3393 4 12.9857V8.98568V8.31901V5.65234C4 5.31901 4.13333 4.98568 4.39333 4.71234L4.92 4.17901L3.72667 2.98568H2V1.65234ZM7.33333 3.65234V4.98568H11.3333V3.65234H7.33333ZM7.60667 7.65234L6.27333 6.31901H5.33333V7.25901L6.66667 8.59234V10.7123L5.33333 12.0457V12.9857H6.27333L7.60667 11.6523H9.72667L11.06 12.9857H12V12.0457L10.6667 10.7123V8.59234L12 7.25901V6.31901H11.06L9.72667 7.65234H7.60667ZM8 8.98568H9.33333V10.319H8V8.98568Z"
        fill={color || '#4B4846'}
      />
    </svg>
  );
};

export default CompraIcon;
