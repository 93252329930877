import { SxProps } from '@mui/material';
import colors from 'theme/colors';

export const dialogTitle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '12px',
  color: colors.complementaryGreen,

  '& svg': {
    fontSize: '32px',
  },

  '@media (max-width: 767px)': {
    gap: '8px',
    '& svg': {
      fontSize: '20px',
    },
  },
};
