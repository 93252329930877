type ErrorNames = 'ExternalLogin' | 'ExternalRegister' | 'OTCultivosFile';

export type CustomErrorParams = {
  message: string | undefined;
  name: ErrorNames;
  cause: {
    status: number | undefined;
    ok: boolean;
    problem: string;
  };
};

export class CustomApiError extends Error {
  constructor({ message, name, cause }: CustomErrorParams) {
    super(message);
    this.name = name;
    this.cause = cause;
  }
}
