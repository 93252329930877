import { useCallback, useEffect, useContext, useState } from 'react';

import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import StepProcedureContainer from 'components/common/cards/stepProcedure';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { Box } from '@mui/material';
import { DarumaEstadoSolicitud } from 'components/sections/procedures/finance/Daruma/DatosDelEstadoDeLaSolicitud';
import { ProcedureStatus } from 'models/procedure';
import api from 'services/api/api';
import { useStores } from 'store/root-store/root-store-context';
import { useParams } from 'react-router-dom';
import { DarumaDispatchContext, DarumaStateContext } from 'contexts/Daruma';
import {
  LazyDarumaProducts,
  LazyJuricalInversor,
  LazyPhysicalInvestor,
  LazySolicitudEnviada,
} from '../lazyDaruma';
import DarumaRepresentantScreen from '../Representante/darumaRepresentantScreen';

const routesWithProceduresAvailable = [
  'envio_solicitud',
  'solicitud_finalizada',
];
export const RoutesWithProcedure = () => {
  const { stateProcedure } = useContext(DarumaStateContext);
  const dispatch = useContext(DarumaDispatchContext);
  const [procedure, setProcedure] = useState<any | null>(null);
  const { code, alias } = useParams();

  const { procedureHeaderStore } = useStores();

  const getProcedureInfo = useCallback(async () => {
    if (code && alias) {
      const response = await api.procedure.getProcedureDetail(
        'financiamiento',
        code,
      );
      dispatch({ type: 'set-stateProcedure', value: response.estado });

      const logos = [];

      if (response.producto.logo) {
        logos.push(response.producto.logo);
      }

      if (response.producto.entidad.logo) {
        logos.push(response.producto.entidad.logo);
      }

      const procedureInfo = {
        alias: response.producto.alias as string,
        productName: response.producto.nombre as string,
        markdownTemplate: response.producto.markdownTemplate as string,
        images: logos as string[],
        status: (response.estado !== 'solicitud_finalizada'
          ? 'ongoing'
          : response.isAprobado
          ? 'finished'
          : 'rejected') as ProcedureStatus,
      };
      procedureHeaderStore.setProcedureInfo(procedureInfo);
      setProcedure(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProcedureInfo();
  }, [getProcedureInfo]);

  if (routesWithProceduresAvailable.includes(stateProcedure))
    return (
      <ProcedureStepSuspense>
        <LazySolicitudEnviada
          estadoSolicitudComponent={DarumaEstadoSolicitud}
        />
      </ProcedureStepSuspense>
    );

  if (stateProcedure === 'representante')
    return (
      <ProcedureStepSuspense>
        <StepProcedureContainer>
          <DarumaRepresentantScreen procedure={procedure} />
        </StepProcedureContainer>
      </ProcedureStepSuspense>
    );

  if (stateProcedure === 'info_adicional' && procedure && procedure.isJuridico)
    return (
      <ProcedureStepSuspense>
        <StepProcedureContainer>
          <LazyJuricalInversor procedure={procedure} />
        </StepProcedureContainer>
      </ProcedureStepSuspense>
    );

  if (stateProcedure === 'info_adicional' && procedure && !procedure.isJuridico)
    return (
      <ProcedureStepSuspense>
        <StepProcedureContainer>
          <LazyPhysicalInvestor procedure={procedure} />
        </StepProcedureContainer>
      </ProcedureStepSuspense>
    );

  if (stateProcedure === 'productos_adicionales')
    return (
      <ProcedureStepSuspense>
        <StepProcedureContainer>
          <LazyDarumaProducts />
        </StepProcedureContainer>
      </ProcedureStepSuspense>
    );

  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
};
