import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export interface ApiListResponse<T> {
  data: T[];
}

const shouldMock = false;

export class WeatherApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getWeatherInfo(id:number): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/clima/pronostico/?establecimiento_id=${id}`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }


}
