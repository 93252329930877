import React from 'react';
import { Box, IconButton } from '@mui/material';
import { H4, P2 } from 'components/common/typography/styles';
import CloseIcon from '@mui/icons-material/Close';
import colors from 'theme/colors';

import { DialogBody, DialogContainer, DialogHeader } from './index.styled';

export interface ConfirmMessageProps {
  open: boolean;
  onClose: () => void;
}

const ConfirmMessageModal: React.FC<ConfirmMessageProps> = ({
  open,
  onClose,
}) => {
  return (
    <DialogContainer open={open} onClose={onClose} scroll="body">
      <Box>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            zIndex: 10,
          }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogHeader style={{ padding: '25px 18px' }}>
          <H4 color={colors.complementaryGreen}>Contacto Enviado</H4>
        </DialogHeader>
      </Box>
      <DialogBody daruma style={{ display: 'flex', flexDirection: 'column' }}>
        <P2 color={colors.tertiaryMedium}>
          Recibimos tu consulta y te responderemos a la brevedad.
        </P2>
        <P2 color={colors.tertiaryMedium} style={{ marginTop: '25px' }}>
          ¡Gracias por ponerte en contacto!
        </P2>
      </DialogBody>
    </DialogContainer>
  );
};

export default ConfirmMessageModal;
