import React from 'react';
import styled from 'styled-components';
import { ListItem } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import colors from 'theme/colors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledListItem = muiStyled((props: any) => {
  return (
    <ListItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
})(() => ({
  paddingLeft: '15px',
  borderRadius: '4px',
  '&.Mui-selected': {
    border: '0px',
    background: `-webkit-linear-gradient(left, ${colors.primaryBase}, ${colors.complementaryGreen})`,
    borderRadius: '4px',
    color: colors.white,
  },
  '&.Mui-selected>p': {
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.white,
  },
}));

export const IconMenu = styled.div<{ current: boolean }>`
  color: ${({ current }) => (current ? colors.white : colors.black)};

  img {
    width: 24px;
    height: 24px;
    filter: ${({ current }) =>
      current ? 'brightness(0%) invert(1)' : 'brightness(0%) contrast(0.57)'};
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledSubItem = muiStyled((props: any) => {
  return (
    <ListItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
})(() => ({
  height: '32px',
  margin: '2px 0px',
  paddingLeft: '15px',
  borderRadius: '4px',
  '&.Mui-selected': {
    border: '0px',
    background: 'none',
    borderRadius: '4px',
    color: colors.white,

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  '&.Mui-selected>p': {
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.primaryBase,
  },
}));

export const DrawerFooter = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDrawer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 280px;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: white;
`;

export const StyledMenuDrawer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 284px;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: white;
`;

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  height: 798px;
  width: 280px;
  left: 0px;
  top: 0px;
`;

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  position: static;
  width: 280px;
  height: 48px;
  left: 0px;
  top: 136px;

  /* Inside auto layout */
  background-color: #ffffff;
  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 0px 0px;
  border: 0px;
`;
