import { FC } from 'react';
import colors from 'theme/colors';
import { Box } from '@mui/material';
import { CapsMedium, P3 } from 'components/common/typography/styles';

export const Estado: FC<{ estado: string }> = ({ estado }) => {
  return (
    <Box display="flex" flexDirection="column">
      <CapsMedium color={colors.tertiaryMedium} style={{ marginBottom: '8px' }}>
        Estado
      </CapsMedium>
      <P3 color={colors.tertiaryBase}>{estado}</P3>
    </Box>
  );
};
