import React from 'react';
import { SvgProps } from '..';

const SmallBulletModalIcon: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || '14'}
      height={height || '11'}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6947 0.898255C13.8822 1.08578 13.9875 1.34009 13.9875 1.60526C13.9875 1.87042 13.8822 2.12473 13.6947 2.31225L5.69471 10.3123C5.50718 10.4997 5.25288 10.605 4.98771 10.605C4.72255 10.605 4.46824 10.4997 4.28071 10.3123L0.280712 6.31225C0.0985537 6.12365 -0.00224062 5.87105 3.78026e-05 5.60885C0.00231622 5.34666 0.107485 5.09584 0.292893 4.91044C0.478301 4.72503 0.729114 4.61986 0.99131 4.61758C1.25351 4.6153 1.50611 4.7161 1.69471 4.89826L4.98771 8.19126L12.2807 0.898255C12.4682 0.710784 12.7225 0.605469 12.9877 0.605469C13.2529 0.605469 13.5072 0.710784 13.6947 0.898255Z"
          fill={color || '#928176'}
        />
      </g>
    </svg>
  );
};

export default SmallBulletModalIcon;
