import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { H5 } from 'components/common/typography/styles';
import api from 'services/api/api';
import colors from 'theme/colors';
import { useData } from 'hooks/useData';
import ProcedureFinishedCard from 'components/common/cards/procedures/Finished';
import { useStores } from 'store/root-store/root-store-context';
import { ProcedureInProcess } from 'models/procedure';
import { ProceduresEmpty } from '../proceduresEmpty/ProceduresEmpty';
import {
  ARRAY_EMPTY,
  PROCEDURES_FINISHED_EMPTY,
} from '../proceduresEmpty/messages.procedures';
import Button from 'components/common/buttons/button';

type TProceduresFinished = {
  setOpenAlert: (v: boolean) => void;
};

export const ProceduresFinished = ({ setOpenAlert }: TProceduresFinished) => {
  const { sessionStore } = useStores();
  const fetcher = async () =>
    // eslint-disable-next-line no-return-await
    await api.procedure.getProcedures('certificacion', 1);
  const { data, isLoading } = useData(
    fetcher,
    `tracesFinished/${sessionStore?.currentUser?.cuentaActual}`,
  );

  const [showAll, setShowAll] = useState(false);

  const handleShow = () => {
    setShowAll(!showAll);
  };
  const displayedData = showAll ? data : data?.slice(0, 9);

  return (
    <>
      <Box>
        <H5
          id="cert-finisheds-title"
          style={{ color: colors.tertiaryMedium, marginTop: '24px' }}
        >
          Finalizadas
        </H5>
        {(data !== undefined && data.length === ARRAY_EMPTY) ||
        (data === undefined && !isLoading) ? (
          <ProceduresEmpty message={PROCEDURES_FINISHED_EMPTY} />
        ) : (
          <Grid
            height={isLoading ? '251px' : ''}
            container
            spacing="12px"
            mt="8px"
            mb="24px"
          >
            {isLoading && data === undefined ? (
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  height: '40px',
                  margin: 'auto',
                }}
              >
                <SpinnerXL />
              </Box>
            ) : (
              displayedData.map((procedure: ProcedureInProcess) => {
                const { multiplexorClass } = procedure.producto;
                const approved =
                  multiplexorClass === 'puma_huella_carbono.Trazabilidad' ||
                  (multiplexorClass === 'viterra_2bsvs.Trazabilidad' &&
                    procedure.estado === 'finalizado' &&
                    procedure.establecimientos.filter(
                      (e) => e.estado === 'aprobado',
                    ).length > 0);

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={`${procedure.codigo}-certifications`}
                  >
                    <ProcedureFinishedCard
                      approved={approved}
                      procedure="certificaciones"
                      trace={procedure}
                      handleOnAlert={() => setOpenAlert(true)}
                    />
                  </Grid>
                );
              })
            )}
          </Grid>
        )}
        {data?.length > 9 && (
          <Button
            text={showAll ? 'Ver menos' : `Ver más (${data.length - 9})`}
            preset="normal"
            size="large"
            onClick={handleShow}
            sx={{ width: '100%' }}
            variant="outlined"
          />
        )}
      </Box>
    </>
  );
};
