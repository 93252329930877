import React from 'react';
import { Box } from '@mui/material';
import { P3, CapsMedium } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { IDatosRepresentante } from 'models/models.daruma';

export const DatosRepresentante: React.FC<IDatosRepresentante> = ({
  nombre,
  apellido,
  telefono,
  email,
  domicilio,
}) => {
  return (
    <Box>
      <CapsMedium color={colors.tertiaryMedium} style={{ marginBottom: '8px' }}>
        Datos del representante
      </CapsMedium>
      <P3 color={colors.tertiaryBase}>{`${nombre} ${apellido}`}</P3>
      <P3 color={colors.tertiaryBase}>Teléfono: {telefono}</P3>
      <P3 color={colors.tertiaryBase}>Email: {email}</P3>
      <P3 color={colors.tertiaryBase}>Domicilio de la Empresa: {domicilio}</P3>
    </Box>
  );
};
