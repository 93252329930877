export const initialStateDaruma = {
  body: {
    cuitApplicant: 0,
    nombre: '',
    apellido: '',
    email: '',
    telefono: '',
    cargo: '',
    payTaxes: false,
    cuits: [],
    client: 'false',
    razonSocialApplicant: '',
    pais: '',
    nif: '',
  },
  firmante: null,
  countries: [],
  cuit: '',
  cuits: [],
  isCompleted: {
    contactData: false,
    clientType: false,
    fields: false,
  },
  companyInfo: {
    id: 0,
    cuit: '',
    contacto: {},
    firmante: {},
    juridico: false,
    razonSocial: '',
    terceros: [],
  },
  empresaSolicitante: null,
  representante: null,
  stateProcedure: 'seleccion_empresa',
  refused: false,
  representanteStep: 0,
  infoAdicionalSplit: 0,
  estadoSolicitudBody: null,
  productName: '',
  productsIds: [],
  fromArgenpymes: true,
  moveForward: false,
};
