import Grass from '@mui/icons-material/Grass';
import { Box, Stack } from '@mui/material';
import Button from 'components/common/buttons/button';
import { H3, P1 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { NoFieldsContainer } from './index.styled';

type Props = {
  openModal: () => void;
};

const NoFields = ({ openModal }: Props) => (
  <Stack
    width="calc(100% - 48px)"
    sx={{
      height: {
        xs: '394.299px',
        md: '600px',
      },
      margin: {
        xs: '24px 16px 24px 16px',
        md: '24px',
      },
    }}
  >
    <NoFieldsContainer>
      <Stack
        alignItems="center"
        textAlign="center"
        gap="8px"
        width="100%"
        py="16px"
      >
        <Grass
          sx={{
            color: colors.tertiaryMedium,
            fontSize: {
              xs: '80px',
              md: '120px',
            },
          }}
        />
        <H3 color={colors.tertiaryMedium} className="title-container">
          Sin campos cargados
        </H3>
        <P1 color={colors.tertiaryMedium} className="text-container">
          Intenta con otros términos
        </P1>
        <Box
          mt="calc(24px - 8px)"
          width={{
            xs: '90%',
            tabletS: 'max-content',
          }}
        >
          <Button
            startIcon={<Grass />}
            variant="contained"
            fullWidth
            preset="normal"
            size="large"
            text="Agregar campo"
            onClick={openModal}
          />
        </Box>
      </Stack>
    </NoFieldsContainer>
  </Stack>
);

export default NoFields;
