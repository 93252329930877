import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useDropzone } from 'react-dropzone';
import apisauce from 'apisauce';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DeleteIcon from '@mui/icons-material/Delete';
import { ChevronRight } from '@mui/icons-material';
import Button from 'components/common/buttons/button';
import { P2, P3 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import getCroppedImg from 'utils/cropUtils';
import CustomTooltip from 'components/common/tooltips';
import api from 'services/api/api';
import { DEFAULT_API_CONFIG } from 'services/api/api-config';
import { ZoomSlider } from '../index.styled';

interface UploadImageProps {
  selectedImg: string;
  croppedImg: (img: string) => void;
}

type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

const UploadImage: React.FC<UploadImageProps> = ({
  selectedImg,
  croppedImg,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isCropping, setCropping] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    width: number;
    height: number;
    x: number;
    y: number;
  } | null>(null);
  const [imageURL, setImageURL] = useState<string>(selectedImg || '');
  const [loadSelectedImg, setLoadSelectedImg] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg'],
    },
  });

  const loadImage = async () => {
    const imageApi = apisauce.create({
      baseURL: DEFAULT_API_CONFIG.url,
      responseType: 'arraybuffer',
      timeout: 45000,
    });
    const response = await imageApi.get(
      imageURL,
      {},
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'image/jpeg',
          Authorization: `Token ${api.api.token}`,
        },
      },
    );
    const fileType = 'image/jpeg';
    const file = new Blob([response.data as unknown as Blob], {
      type: fileType,
    });
    const objUrl = URL.createObjectURL(file);
    setImageURL(objUrl);
  };

  const onChangeAcceptedFiles = () => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setImageURL(URL.createObjectURL(file));
      setIsLoaded(true);
    } else if (selectedImg && !loadSelectedImg) {
      if (!selectedImg.includes('blob:') && !selectedImg.includes('data:')) {
        loadImage();
      } else {
        setImageURL(selectedImg);
      }
      setIsLoaded(true);
      setLoadSelectedImg(true);
    } else {
      setImageURL('');
      setIsLoaded(false);
    }
  };

  useEffect(() => {
    onChangeAcceptedFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixelsRes: Area) => {
      setCroppedAreaPixels(croppedAreaPixelsRes);
    },
    [],
  );

  const showCroppedImage = useCallback(async () => {
    setCropping(true);
    try {
      if (croppedAreaPixels && imageURL) {
        const croppedImageResult: string | null = await getCroppedImg(
          imageURL,
          croppedAreaPixels,
          rotate,
        );
        if (croppedImageResult) {
          croppedImg(croppedImageResult);
        }
      }
    } catch (e) {
      throw new Error(e as string);
    } finally {
      setCropping(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels, rotate]);

  const handleZoomChange = (event: Event, newValue: number | number[]) => {
    setZoom(newValue as number);
  };

  const leftRotate = () => {
    if (rotate === 0) {
      setRotate(270);
    } else {
      setRotate(rotate - 90);
    }
  };

  const rightRotate = () => {
    if (rotate === 270) {
      setRotate(0);
    } else {
      setRotate(rotate + 90);
    }
  };

  const deleteFile = () => {
    setImageURL('');
    setIsLoaded(false);
    setCroppedAreaPixels(null);
    setZoom(1);
    setRotate(0);
  };

  return (
    <Box>
      {!isLoaded ? (
        <Box {...getRootProps({ className: 'dropzone' })}>
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              border: `2px dashed ${colors.tertiaryMedium}`,
              borderRadius: '8px',
              background: colors.tertiaryHigh,
              height: '135px',
              margin: '16px 0 24px 0',
              cursor: 'pointer',
            }}
          >
            <input {...getInputProps()} style={{ display: 'none' }} />
            <CloudUploadIcon
              fontSize="large"
              sx={{ color: colors.tertiaryBase }}
            />
            <P2 color={colors.tertiaryBase} style={{ textAlign: 'center' }}>
              Arrastrá o seleccioná una imagen para subirla a la plataforma
            </P2>
          </Paper>
        </Box>
      ) : (
        imageURL && (
          <Stack direction="column" marginTop="16px">
            <Paper
              sx={{
                background: colors.tertiaryHigh,
                borderRadius: '8px',
                padding: '12px',
                border: `1px solid ${colors.tertiaryMedium}}`,
              }}
              elevation={0}
            >
              <Box
                style={{
                  position: 'relative',
                  height: '248px',
                }}
              >
                <Cropper
                  image={imageURL}
                  crop={crop}
                  rotation={rotate}
                  zoom={zoom}
                  aspect={1.59 / 1}
                  onCropChange={setCrop}
                  onRotationChange={setRotate}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </Box>
            </Paper>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              marginTop="15px"
            >
              {/* Zoom image */}
              <Stack
                spacing={2}
                direction="row"
                sx={{ width: '280px' }}
                alignItems="center"
              >
                <CustomTooltip title="Alejar" arrowLocation="top">
                  <ZoomOutIcon
                    sx={{
                      color:
                        zoom !== 1
                          ? colors.tertiaryBase
                          : colors.tertiaryIntermediate,
                    }}
                  />
                </CustomTooltip>
                <ZoomSlider
                  min={1}
                  max={10}
                  step={0.1}
                  aria-label="Zoom"
                  value={zoom}
                  onChange={handleZoomChange}
                />
                <CustomTooltip title="Acercar" arrowLocation="top">
                  <ZoomInIcon
                    sx={{
                      color:
                        zoom !== 10
                          ? colors.tertiaryBase
                          : colors.tertiaryIntermediate,
                    }}
                  />
                </CustomTooltip>
              </Stack>

              {/* Rotate image */}
              <Stack spacing={1} direction="row" alignItems="center">
                <CustomTooltip title="Rotar a la izq." arrowLocation="top">
                  <IconButton
                    arial-label="Rotar a la izquierda"
                    onClick={leftRotate}
                  >
                    <RotateLeftIcon sx={{ color: colors.tertiaryBase }} />
                  </IconButton>
                </CustomTooltip>
                <CustomTooltip title="Rotar a la der." arrowLocation="top">
                  <IconButton
                    arial-label="Rotar a la derecha"
                    onClick={rightRotate}
                  >
                    <RotateRightIcon sx={{ color: colors.tertiaryBase }} />
                  </IconButton>
                </CustomTooltip>
              </Stack>

              {/* Delete image */}
              <Stack spacing={1} direction="row" alignItems="center">
                <CustomTooltip title="Eliminar" arrowLocation="top">
                  <IconButton aria-label="Eliminar" onClick={deleteFile}>
                    <DeleteIcon
                      sx={{
                        color: colors.tertiaryBase,
                        '&:hover': { color: colors.complementaryError },
                      }}
                    />
                  </IconButton>
                </CustomTooltip>
              </Stack>
            </Stack>
            <P3 color={colors.tertiaryBase} style={{ margin: '24px 0' }}>
              Usá los controles para posicionar la imagen correctamente dentro
              del recuadro
            </P3>
          </Stack>
        )
      )}
      <Button
        variant="contained"
        preset="normal"
        size="large"
        onClick={showCroppedImage}
        text="Guardar imagen"
        disabled={!isLoaded || isCropping}
        loading={isCropping}
        endIcon={<ChevronRight />}
      />
    </Box>
  );
};

export default UploadImage;
