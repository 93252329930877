import { MisEmpresasAction, InitialStateMisEmpresas } from './index.types';

export default function misEmpresasReducer(
  initialValues: InitialStateMisEmpresas,
  action: MisEmpresasAction,
) {
  switch (action.type) {
    case 'set-isUserExternal': {
      return { ...initialValues, isUserExternal: action.value };
    }
    default:
      return initialValues;
  }
}