/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useFormikContext } from 'formik';
import { SelectChangeEvent } from '@mui/material';

export const addFormikSelectProps = (
  name: string,
  options: { value: any; label: string }[],
  isMulti: boolean,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { errors, values, setFieldValue, handleBlur } = useFormikContext();
  const onChange = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    setFieldValue(name, event.target.value);
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? // @ts-ignore
          options.filter((option) => values[name].indexOf(option.value) >= 0)
        : // @ts-ignore
          options.find((option) => option.value === values[name])?.value;
    }
    return isMulti ? [] : ('' as any);
  };
  // @ts-ignore
  const error = errors[name];
  const value = getValue();
  return {
    error,
    value,
    onChange,
    onBlur: handleBlur,
  };
};
