import React from 'react';
import { SvgProps } from '..';

const LaptopIcon: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66667 3.9974H13.3333V10.6641H2.66667M13.3333 11.9974C13.687 11.9974 14.0261 11.8569 14.2761 11.6069C14.5262 11.3568 14.6667 11.0177 14.6667 10.6641V3.9974C14.6667 3.2574 14.0667 2.66406 13.3333 2.66406H2.66667C1.92667 2.66406 1.33333 3.2574 1.33333 3.9974V10.6641C1.33333 11.0177 1.47381 11.3568 1.72386 11.6069C1.97391 11.8569 2.31304 11.9974 2.66667 11.9974H0V13.3307H16V11.9974H13.3333Z"
        fill={color || '#928176'}
      />
    </svg>
  );
};

export default LaptopIcon;
