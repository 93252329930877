import { ViterraDispatchContext, ViterraStateContext } from 'contexts/Viterra';
import { useContext } from 'react';

export function useViterraContext() {
  const state = useContext(ViterraStateContext);
  const dispatch = useContext(ViterraDispatchContext);
  if (!state || !dispatch) {
    throw new Error('usePumaContext debe ser usado con PumaProvider');
  }
  return {
    state,
    dispatch,
  };
}
