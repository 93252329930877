import React from 'react';
import { Box, ListItemIcon } from '@mui/material';
import {
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';
import CustomTooltip from 'components/common/tooltips';
import { StyledListItem, IconMenu } from '../styles';
import { Menu } from 'store/session-store/generalModels';

interface ItemMenuProps {
  to: string;
  icon: string;
  id: string;
  text: string;
  openMenu?: boolean;
  setOpenMenu?: (a: boolean) => void;
  setOpenDropdown?: (a: boolean) => void;
}

interface ItemMenuDesplegableProps {
  menuOption: Menu;
  setOpenMenu: (a: boolean) => void;
  setOpenDropdown?: (codigo: string, state: boolean) => void;
}

export const ItemMenu: React.FC<ItemMenuProps> = ({
  to,
  icon,
  id,
  text,
  openMenu,
  setOpenMenu,
  setOpenDropdown,
}) => {
  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const location = useLocation();
  const current = !!match || (location.pathname.includes(to) && to !== '/');

  return (
    <CustomTooltip title={text} arrowLocation="right">
      <Box
        width="43px"
        height="43px"
        borderRadius="4px"
        overflow="hidden"
        my="2px"
      >
        <StyledListItem
          selected={current}
          button
          id={id}
          onClick={() => {
            if (to) {
              navigate(to);
            }
            setOpenMenu?.(!!openMenu);
            setOpenDropdown?.(true);
          }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ListItemIcon sx={{ minWidth: 0 }}>
            <IconMenu current={current}>
              <img loading="lazy" src={icon} alt={text} />
            </IconMenu>
          </ListItemIcon>
        </StyledListItem>
      </Box>
    </CustomTooltip>
  );
};

export const ItemMenuDesplegable = ({
  menuOption,
  setOpenMenu,
  setOpenDropdown,
}: ItemMenuDesplegableProps) => {
  const location = useLocation();
  const { codigo, icono, seccion, urlFront } = menuOption;
  const current = location.pathname.includes(urlFront)

  return (
    <CustomTooltip title={seccion} arrowLocation="right">
      <Box
        width="43px"
        height="43px"
        borderRadius="4px"
        overflow="hidden"
        my="2px"
      >
        <StyledListItem
          selected={current}
          button
          id={codigo}
          onClick={() => {
            setOpenMenu(true);
            setOpenDropdown?.(codigo, true);
          }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ListItemIcon sx={{ minWidth: 0 }}>
            <IconMenu current={current}>
              <img loading="lazy" src={icono} alt={codigo} />
            </IconMenu>
          </ListItemIcon>
        </StyledListItem>
      </Box>
    </CustomTooltip>
  );
};
