import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { H5 } from 'components/common/typography/styles';
import colors from 'theme/colors';

interface BtnModalProps {
  text: string;
  onClick: () => void;
  to: string | null;
  type?: 'primary' | 'secondary';
  size?: 'lg' | 'md';
  id?: string;
}

const ColorButton = styled(Button)(({ theme, style, size }) => ({
  boxShadow: 'none',
  height: '44px',
  padding: '0',
  paddingBottom: '12px',
  paddingTop: '12px',
  paddingLeft: '16px',
  paddingRight: '16px',
  borderRadius: '4px',
  background: `-webkit-linear-gradient(left, ${colors.primaryBase}, ${colors.complementaryGreen})`,
}));

const BtnModal: React.FC<BtnModalProps> = ({
  text,
  onClick,
  to,
  type = 'primary',
  size = 'lg',
  id = 'modalBtn',
}) => {
  return (
    <Link to={to as any} style={{ textDecoration: 'none' }}>
      <ColorButton onClick={() => onClick()} id={id}>
        <H5
          style={{
            color: type === 'primary' ? 'white' : colors.primaryBase,
            fontSize: '18px',
          }}
        >
          {text}
        </H5>
      </ColorButton>
    </Link>
  );
};

export default BtnModal;
