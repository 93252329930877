import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import TextInput from 'components/common/inputs/text';
import { P3 } from 'components/common/typography/styles';
import { ToSModal } from 'components/common/modals/tosModal';
import api from 'services/api/api';
import { RegisterSchema } from 'utils/schema-validations';
import { useStores } from 'store/root-store/root-store-context';
import { Strings } from 'constants/strings';
import Button from 'components/common/buttons/button';
import {
  capitalLetter,
  checks,
  hasNumber,
  moreEightChar,
  lowercaseLetter,
  pipe,
  specialChar,
} from 'utils/checklistPassUtils';
import { TooltipPassword } from '../tooltipPassword/TooltipPassword';
import { ChekcListPass } from '../tooltipPassword/ChekcListPass';
import { CheckItem } from 'utils/types';

enum FieldNames {
  fieldName = 'nombre',
  fieldSurname = 'apellido',
  fieldEmail = 'email',
  fieldPassword = 'password',
  fieldPasswordRepeat = 'passwordRepeat',
}

interface IRegisterForm {
  email: string;
  token: string | undefined;
}

const RegisterForm = (props: IRegisterForm) => {
  const { email, token } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const { sessionStore } = useStores();
  const navigate = useNavigate();
  const [checkListArr, setCheckListArr] = useState<CheckItem[]>(checks);
  const [progress, setProgress] = useState(39);
  const [valuePass, setValuePass] = useState<string>('');

  const emailHidden = email.replace(/(\w{3})[\w.\-+]+@([\w.]+\w)/, '$1...@$2');

  const initialValues = {
    [FieldNames.fieldName]: '',
    [FieldNames.fieldSurname]: '',
    [FieldNames.fieldEmail]: emailHidden,
    [FieldNames.fieldPassword]: '',
    [FieldNames.fieldPasswordRepeat]: '',
  };

  const activateInvite = async (values: Record<string, string>) => {
    const body = {
      nombre: values[FieldNames.fieldName],
      apellido: values[FieldNames.fieldSurname],
      password: values[FieldNames.fieldPassword],
    };
    if (token) {
      const response = await api.invite.activateInvitation(token, body);
      if (response.token) {
        sessionStore.setToken(response.token);
        api.api.setToken(response.token);
        navigate('/');
        enqueueSnackbar(Strings.forms.login.success, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    }
  };

  const handleFocus = () => {
    setOpenPopUp(true);
  };
  const handleBlur = () => {
    setOpenPopUp(false);
  };
  const handleMatch = () => {
    const iterable = pipe(
      specialChar,
      hasNumber,
      lowercaseLetter,
      capitalLetter,
      moreEightChar,
    )(valuePass);

    const checksListMatched = checks.map((e) => {
      const matchingItem = iterable.find((v) => v.key === e.key);
      if (matchingItem) {
        e.checked = matchingItem.checked;
      }
      return { ...e };
    });
    return checksListMatched;
  };

  const valueProgress = () =>
    checkListArr.filter((e: CheckItem) => e.checked === true);

  const isMobile = useMediaQuery('(max-width:1024px)');

  useEffect(() => {
    setCheckListArr(handleMatch());
    setProgress(() => valueProgress().length * 20);
  }, [valuePass, valueProgress().length]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={activateInvite}
      validationSchema={RegisterSchema}
      validateOnBlur={false}
      validateOnChange={isSubmit}
    >
      {({ handleSubmit, values, isSubmitting, errors }) => {
        if (isSubmitting && !isSubmit) {
          setIsSubmit(true);
        }
        setValuePass(values?.password);

        return (
          <>
            <ToSModal open={openModal} onClose={() => setOpenModal(false)} />
            <Box my="10px" width="100%">
              <TextInput
                id="name-field"
                label="Nombres"
                maxLength={150}
                disabled={false}
                name={FieldNames.fieldName}
                width="421px"
                height="48px"
                type="text"
                handleSubmit={handleSubmit}
                placeholder="Ingresá tu nombre completo"
              />
            </Box>
            <Box my="10px" width="100%">
              <TextInput
                id="lastname-field"
                label="Apellidos"
                maxLength={150}
                disabled={false}
                name={FieldNames.fieldSurname}
                width="421px"
                height="48px"
                type="text"
                handleSubmit={handleSubmit}
                placeholder="Ingresá tus apellidos"
              />
            </Box>
            <Box my="10px" width="100%">
              <TextInput
                id="email-field"
                label="E-mail de invitación"
                maxLength={254}
                disabled
                name={FieldNames.fieldEmail}
                width="421px"
                height="48px"
                type="email"
                handleSubmit={handleSubmit}
                placeholder=""
              />
            </Box>

            <TooltipPassword
              open={openPopUp}
              onClose={() => setOpenPopUp(false)}
              onOpen={() => setOpenPopUp(true)}
              title={
                <ChekcListPass checksList={checkListArr} progress={progress} />
              }
              placement={isMobile ? 'top' : 'left-start'}
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Box my="10px" width="100%">
                <TextInput
                  id="password-field"
                  label="Contraseña"
                  disabled={false}
                  maxLength={16}
                  name={FieldNames.fieldPassword}
                  width="421px"
                  height="48px"
                  type="password"
                  placeholder="Ingresá tu contraseña"
                  handleSubmit={handleSubmit}
                  handleFocus={handleFocus}
                  handleBlur={handleBlur}
                />
              </Box>
            </TooltipPassword>

            <Box
              mt={{
                xs: '24px',
                mobileM: '8px',
              }}
              width="100%"
            >
              <TextInput
                id="password-repeat-field"
                label=""
                disabled={false}
                name={FieldNames.fieldPasswordRepeat}
                width="421px"
                height="48px"
                maxLength={16}
                type="password"
                placeholder="Repetí tu contraseña"
                handleSubmit={handleSubmit}
              />
            </Box>

            <Box
              textAlign={{
                xs: 'center',
                laptop: 'left',
              }}
              width="100%"
            >
              <P3
                style={{
                  color: '#333333',
                  marginTop: '14px',
                }}
              >
                Al crear una cuenta, confirmo que leí y acepto las&nbsp;
                <P3
                  style={{
                    color: '#333333',
                    textDecoration: 'underline',
                    textUnderlineOffset: '1px',
                    display: 'inline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  Condiciones, Políticas de datos y privacidad.
                </P3>
              </P3>
            </Box>
            <Box marginTop="24px" id="activar_cuenta_ingresar">
              <Button
                variant="contained"
                preset="normal"
                size="large"
                onClick={handleSubmit}
                text="Activar cuenta"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
function enqueueSnackbar(
  success: string,
  arg1: {
    variant: string;
    anchorOrigin: { vertical: string; horizontal: string };
  },
) {
  throw new Error('Function not implemented.');
}
