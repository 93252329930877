import { types, Instance, SnapshotOut } from 'mobx-state-tree';

export const CuentaType = types.model('Cuenta').props({
  id: types.number,
  nombre: types.maybeNull(types.string),
  rol: types.maybeNull(types.string),
  estado: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  dominio: types.maybeNull(types.string),
  origen: types.string,
});
type CuentaModelType = Instance<typeof CuentaType>;
export type Cuenta = CuentaModelType;

export const UserDataType = types.model('UserData').props({
  id: types.number,
  cuentaActual: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  telefono: types.maybeNull(types.string),
  misCuentas: types.maybeNull(types.array(CuentaType)),
  hasPassword: types.maybe(types.boolean),
});
type UserDataModelType = Instance<typeof UserDataType>;
export type UserData = UserDataModelType;

export const UserType = types.model('UserType').props({
  modifiedAt: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
});

export const UserModel = types.model('User').props({
  id: types.maybeNull(types.string),
});

type UserModelType = Instance<typeof UserModel>;
export type User = UserModelType;
type UserSnapshotType = SnapshotOut<typeof UserModel>;
export type UserSnapshot = UserSnapshotType;
export const createUserDefaultModel = () => types.optional(UserModel, {});