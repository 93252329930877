/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useReducer } from 'react';
import { initialStateHuellaDeCarbono } from './initialValues';
import huellaDeCarbonoReducer from './index.reducer';
import {
  InitialStateHuellaDeCarbono,
  HuellaDeCarbonoDispatch,
} from './index.types';

export const HuellaDeCarbonoStateContext =
  createContext<InitialStateHuellaDeCarbono>(initialStateHuellaDeCarbono);

export const HuellaDeCarbonoDispatchContext =
  createContext<HuellaDeCarbonoDispatch>({} as HuellaDeCarbonoDispatch);

interface IPumaContext {
  children: React.ReactNode;
}

export const HuellaDeCarbonoContext: React.FC<IPumaContext> = ({
  children,
}) => {
  const [pumaValues, dispatch] = useReducer(
    huellaDeCarbonoReducer,
    initialStateHuellaDeCarbono,
  );
  return (
    <HuellaDeCarbonoDispatchContext.Provider value={dispatch}>
      <HuellaDeCarbonoStateContext.Provider value={pumaValues}>
        {children}
      </HuellaDeCarbonoStateContext.Provider>
    </HuellaDeCarbonoDispatchContext.Provider>
  );
};