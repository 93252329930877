import { ApisauceInstance, create } from 'apisauce';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { rootStore } from 'store/root-store/root-store';
import { Strings } from 'constants/strings';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import { AuthApi } from './authApi';
import { CertificationsApi } from './certificationsApi';
import { CommonApi } from './commonApi';
import { CommunityApi } from './communityApi';
import { HomeApi } from './homeApi';
import { InviteApi } from './inviteApi';
import { MeApi } from './meApi';
import { PasswordApi } from './passwordApi';
import { TracesApi } from './tracesApi';
import { ProcedureApi } from './procedureApi';
import toast from '../../components/common/toast/index.jsx';
import { WeatherApi } from './weatherApi';
import { DarumaApi } from './darumaApi';
import { ChatApi } from './chatApi';

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  token: string | null;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.token = null;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
      },
    });
    this.apisauce.addResponseTransform((response) => {
      response.data = camelizeKeys(response.data);
    });
    this.apisauce.addRequestTransform((request) => {
      request.data = decamelizeKeys(request.data);
    });
  }

  removeToken() {
    this.token = null;
    this.apisauce.setHeaders({
      Accept: 'application/json',
    });
    this.apisauce.deleteHeader('Authorization');
  }

  setToken(token: string) {
    if (token === '') return;
    this.token = token;
    this.apisauce.setHeaders({
      Authorization: `Token ${token}`,
      Accept: 'application/json',
    });
  }
}

const baseApi = new Api();
baseApi.setup();
const api = {
  api: baseApi,
  community: new CommunityApi(baseApi),
  traces: new TracesApi(baseApi),
  me: new MeApi(baseApi),
  common: new CommonApi(baseApi),
  auth: new AuthApi(baseApi),
  invite: new InviteApi(baseApi),
  passwords: new PasswordApi(baseApi),
  home: new HomeApi(baseApi),
  certifications: new CertificationsApi(baseApi),
  procedure: new ProcedureApi(baseApi),
  weather: new WeatherApi(baseApi),
  daruma: new DarumaApi(baseApi),
  chat: new ChatApi(baseApi),
};

const responseMonitor = (response: any) => {
  if (response.status === 401) {
    if (
      response.data.detail &&
      response.data.detail === 'Invalid or Inactive Token.'
    ) {
      rootStore.resetStore();
      rootStore.sessionStore.setReasonTokenExpired(
        true,
        'Nueva sesión',
        Strings.notifications.invalidToken,
      );
    }
    if (response.data.detail && response.data.detail === 'Token has expired.') {
      rootStore.resetStore();
      rootStore.sessionStore.setReasonTokenExpired(
        true,
        'Sesión expirada',
        Strings.notifications.expiredToken,
      );
    }
    if (response.data.detail && response.data.detail === 'Invalid User.') {
      rootStore.resetStore();
      rootStore.sessionStore.setReasonTokenExpired(
        true,
        'Problema',
        Strings.notifications.invalidUser,
      );
    }
    if (response.data.detail && response.data.detail === 'Invalid reCaptcha.') {
      rootStore.resetStore();
      toast.error('Error en reCaptcha');
    }
    api.api.removeToken();
    return;
  }
  if (response.status === 403) {
    toast.error('No tenés permiso para realizar la operación solicitada');
    return;
  }
  if (response.status === 404) {
    toast.error(
      'Estamos teniendo inconvenientes para realizar esta operación.',
    );
    return;
  }
  if (response.status >= 500 && response.status !== 503) {
    toast.error(
      'Estamos teniendo inconvenientes para realizar esta operación.',
    );
  }
  if (
    response.data.message === 'Limite de requests alcanzado' &&
    response.status === 429
  ) {
    const minRequests = response.data.waitSeconds / 60;
    const minutes = minRequests.toFixed(0);
    toast.error(
      `Demasiados intentos fallidos, esperá ${minutes} minutos para volver a intentar`,
    );
  }
};
api.api.apisauce.addMonitor(responseMonitor);

export default api;
