import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/x-date-pickers/locales';
import { es } from 'date-fns/locale';
import colors from './colors';
import Typography from './typography';
import shadows from '@mui/material/styles/shadows';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: Palette['primary'];
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    tertiary: true;
  }
}

export interface Theme {
  colors: Record<string, string>;
  fontFamilies: Record<string, string>;
  fontWeights: Record<string, string>;
}

// Elevations
const customShadows = shadows;
customShadows[1] = '0px 1px 4px 0px rgba(0, 0, 0, 0.15) !important';
customShadows[2] = '0px 3px 4px 0px rgba(0, 0, 0, 0.12) !important';
customShadows[3] = '0px 5px 8px 0px rgba(0, 0, 0, 0.12) !important';
customShadows[4] = '0px 8px 8px 0px rgba(75, 72, 70, 0.16) !important';
customShadows[6] = '0px 5px 8px 0px rgba(0, 0, 0, 0.16) !important';
customShadows[8] = '0px 8px 12px 0px rgba(0, 0, 0, 0.20) !important';
customShadows[16] = '0px 8px 16px 0px rgba(0, 0, 0, 0.26) !important';
customShadows[24] = '0px 10px 32px 0px rgba(0, 0, 0, 0.32) !important';

export const agroTheme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tabletS: 500,
        tablet: 768,
        laptop: 1024,
        laptopL: 1440,
      },
    },
    shadows: customShadows,
    palette: {
      tertiary: {
        main: colors.tertiaryMedium,
        light: colors.tertiaryHigh,
        dark: colors.tertiaryLow,
        contrastText: colors.tertiaryBase,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // Normal
            '&.MuiButton-contained': {
              background: `linear-gradient(90deg, ${colors.primaryBase} 0%, ${colors.complementaryGreen} 100%) !important`,
            },
            '&:has': {
              background:
                'linear-gradient(90deg, #f34202 0%, #ff9b25 100%) !important',
            },

            '&.MuiButton-outlined': {
              border: `1px solid ${colors.complementaryGreen} !important`,
              '&:has(.btn-text-normal-outlined)': {
                '.MuiButton-endIcon': {
                  color: `${colors.complementaryGreen} !important`,
                },
                '.MuiButton-startIcon': {
                  color: `${colors.primaryBase} !important`,
                },
              },
              '&:has(.btn-text-daruma-outlined)': {
                '.MuiButton-endIcon, .MuiButton-startIcon': {
                  color: '#f34202 !important',
                },
                border: `1px solid #f34202 !important`,
              },
            },

            // Danger
            '&:has(.btn-text-danger-contained)': {
              '&.MuiButton-contained': {
                background: `linear-gradient(90.06deg, #d00b0b 0.06%, ${colors.complementaryError} 99.95%) !important`,
              },
            },

            '&:has(.btn-text-danger-outlined)': {
              '&.MuiButton-outlined': {
                color: `${colors.complementaryError} !important`,
                border: `1px solid ${colors.complementaryError} !important`,
              },

              '.btn-text-danger-outlined': {
                color: `${colors.complementaryError} !important`,
              },
            },

            // Disabled
            '&.Mui-disabled': {
              '&.MuiButton-contained': {
                background: `${colors.tertiaryIntermediate} !important`,
                color: `${colors.tertiaryMedium} !important`,
              },
              '  &.MuiButton-outlined': {
                background: `none !important`,
                color: `${colors.tertiaryMedium} !important`,
                border: `1px solid ${colors.tertiaryIntermediate} !important`,

                '.MuiButton-endIcon, .MuiButton-startIcon': {
                  color: `${colors.tertiaryMedium} !important`,
                },

                '&:has(.btn-loading)': {
                  '.MuiButton-endIcon, .MuiButton-startIcon': {
                    color: 'transparent !important',
                  },
                },
              },

              '.btn-text-normal-contained, .btn-text-danger-contained,.btn-text-daruma-contained, .btn-text-normal-outlined, .btn-text-danger-outlined, .btn-text-daruma-outlined':
                {
                  color: `${colors.tertiaryMedium} !important`,
                  background: `-webkit-linear-gradient(
                  left,
                  ${colors.tertiaryMedium},
                  ${colors.tertiaryMedium}
                ) !important`,
                  'background-clip': 'text !important',
                  '-webkit-background-clip': `text !important`,
                  '-webkit-text-fill-color': 'transparent !important',
                },
            },

            // Texts (normal)
            '.btn-text-loading': {
              color: 'transparent !important',
            },
            '.btn-text-normal-contained, .btn-text-danger-contained, .btn-text-daruma-contained':
              {
                color: 'white !important',
              },
            '.btn-text-normal-outlined': {
              background: `-webkit-linear-gradient(
      left,
      ${colors.primaryBase},
      ${colors.complementaryGreen}
    ) !important`,
              'background-clip': 'text !important',
              '-webkit-background-clip': 'text !important',
              '-webkit-text-fill-color': 'transparent !important',
            },
            '.btn-text-danger-outlined': {
              color: 'white !important',
            },
            '.btn-text-daruma-outlined': {
              color: '#f34202 !important',
            },

            // Sizes
            '&.MuiButton-sizeSmall': {
              height: '32px',
            },

            '&.MuiButton-sizeLarge': {
              height: '48px',
            },
          },
        },
      },
    },
  },
  es,
  esES,
);

export default {
  colors,
  fontFamilies: Typography.fontFamilies,
  fontWeights: Typography.fontWeights,
} as Theme;
