import React from 'react';
import { P4 } from 'components/common/typography/styles';
import styled from 'styled-components';
import LogoCabure from 'assets/icons/icons-weather/logo-cabure.svg';

export const FooterCabure: React.FC = () => {
  return (
    <Footer>
      <img
        loading="lazy"
        src={LogoCabure}
        alt=""
        width="80.59px"
        height="24px"
      />
      <P4 color="#928176">Pronóstico proporcionado por YR.NO</P4>
    </Footer>
  );
};

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0px 0px 20px;
  width: 60%;
  align-items: end;
  justify-content: space-between;

  @media (max-width: 660px) {
    width: 100%;
    margin: 4px 0px 12px 20px;
    flex-direction: column;
    margin: 4px 0px 0px 9px;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
  }
`;
