import React from 'react';
import { P4 } from 'components/common/typography/styles';
import { CapitalizeLetter, getIconWeather } from 'utils/utilsWeather';
import { INubosity } from './typesWeather';
import { ContainerNubosity, ContainerDayAndDate } from './weather.styled';

export const NubosityComponent: React.FC<INubosity> = ({
  dayName,
  shortDate,
  nubosity,
}) => {
  return (
    <ContainerNubosity>
      <ContainerDayAndDate>
        <P4>{`${CapitalizeLetter(dayName)}`}</P4>
        <P4>{shortDate}</P4>
      </ContainerDayAndDate>
      <img
        style={{
          marginLeft: '6px',
        }}
        src={`${getIconWeather(nubosity)}`}
        alt=""
        width="29.52px"
        height="29.52px"
        loading="lazy"
      />
    </ContainerNubosity>
  );
};
