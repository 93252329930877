import React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  color?: string;
}

const ArrowLeftIcon: React.FC<IconProps> = ({
  width = '24',
  height = '25',
  color = '#4B4846',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00049 12.3008L11.0005 6.30078M5.00049 12.3008L11.0005 18.3008M5.00049 12.3008H19.0005"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
