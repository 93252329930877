/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Box } from '@mui/material';
import { P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { addFormikFieldProps } from 'hooks/useAddFormikFieldProps';
import { StyledLabel, StyledError, StyledInput } from '../index.styled';

interface InputNumberProps {
  id: string;
  label: string;
  disabled: boolean;
  name: string;
  width: string;
  height: string;
  maxLength?: number;
  warning?: string;
  explanation?: string;
  placeholder: string;
  handleSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

const InputNumber: React.FC<InputNumberProps> = (props) => {
  const {
    id,
    label,
    disabled,
    name,
    width,
    height,
    placeholder,
    warning,
    explanation,
    maxLength,
    handleSubmit,
  } = props;
  const { error, value, onBlur, onChange } = addFormikFieldProps(name);

  return (
    <Box
      width="100%"
      marginBottom={!!error || !!explanation || !!warning ? '16px' : '0px'}
    >
      <StyledLabel htmlFor={id}>
        <P4 color={colors.tertiaryBase}>{label}</P4>
      </StyledLabel>
      <Box position="relative" width="100%">
        <StyledInput
          disabled={disabled}
          id={id}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onKeyPress={(event) => {
            if (!/[0-9.,]/.test(event.key)) {
              event.preventDefault();
            }
            if (handleSubmit && event.key === 'Enter') {
              handleSubmit();
            }
          }}
          name={name}
          maxLength={maxLength}
          width={width}
          height={height}
          error={error || warning || undefined}
          placeholder={placeholder}
          type="text"
          // pattern="(?:[0-9]){0,}((?:[\,\.]{1})(?:[0-9]){1,})?"
        />
        {error && (
          <WarningIcon
            sx={{
              color: colors.complementaryError,
              position: 'absolute',
              right: '18px',
              top: '12px',
            }}
          />
        )}
      </Box>
      {(error || warning) && (
        <StyledError>
          <P4 color={colors.complementaryError}>{error || warning}</P4>
        </StyledError>
      )}
      {explanation && (
        <StyledError>
          <P4 color={colors.tertiaryMedium}>{explanation}</P4>
        </StyledError>
      )}
    </Box>
  );
};

export default InputNumber;
