import { Card, CardContent } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren;

const RoutesWrapper = ({ children }: Props) => {
  return (
    <Card
      sx={{
        maxWidth: '1440px',
        bgcolor: 'transparent',
        margin: '0 auto',
        overflow: 'visible',
      }}
      elevation={0}
    >
      <CardContent sx={{ p: { xs: '0 16px', tablet: '0 24px' } }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default RoutesWrapper;
