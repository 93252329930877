import apisauce, { ApiResponse } from 'apisauce';
import api from 'services/api/api';
import { DEFAULT_API_CONFIG } from 'services/api/api-config';

type ContentType = 'application/pdf' | 'image/jpeg';

const fileApi = apisauce.create({
  baseURL: DEFAULT_API_CONFIG.url,
  responseType: 'arraybuffer',
  timeout: 45000,
});

export const uploadFile = async (fileURL: string, contentType: ContentType) => {
  const response: ApiResponse<ArrayBuffer> = await fileApi.get(
    fileURL,
    {},
    {
      responseType: 'blob',
      headers: {
        'Content-Type': contentType,
        Authorization: `Token ${api.api.token}`,
      },
    },
  );

  if (response.ok) {
    const { data } = response;
    const file = new Blob([data as unknown as Blob], {
      type: contentType,
    });
    return URL.createObjectURL(file);
  }

  return null;
};
