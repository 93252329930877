import React from 'react';
import { Box } from '@mui/material';
import { P3 } from 'components/common/typography/styles';
import colors from 'theme/colors';

export const ProceduresEmpty: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="10px"
      textAlign="center"
      height="5rem"
      border={`${colors.tertiaryMedium} 1px solid`}
      width="calc(100% - 18px)" // 18px = 2 * 8px (padding) + 2 * 1px (border)
      padding="8px"
      marginTop="8px"
    >
      <P3 color={colors.tertiaryMedium}>{message}</P3>
    </Box>
  );
};
