import React from 'react';
import { Divider } from '@mui/material';
import { FooterCabure } from 'components/common/footerCabure';
import { ArrWeather } from './typesWeather';
import {
  ContainerCertWeather,
  CertWeather,
  BoxWeather,
} from './weather.styled';
import { ContainerWeatherComponent } from './ContainerWeatherComponent';

const Weather: React.FC<ArrWeather> = ({ arrWeather }) => {
  return (
    <ContainerCertWeather>
      <Divider style={{ margin: '16px 0px 16px 0px' }} />
      <BoxWeather>
        <CertWeather>
          <ContainerWeatherComponent arrWeather={arrWeather} />
        </CertWeather>
      </BoxWeather>
      <FooterCabure />
    </ContainerCertWeather>
  );
};
export default Weather;
