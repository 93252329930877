import React, { createContext, useReducer } from 'react';
import { initialStateFinSimple } from './initialValues';
import finSimpleReducer from './index.reducer';
import { FinSimpleDispatch, InitialStateFinSimple } from './index.types';

export const FinSimpleStateContext = createContext<InitialStateFinSimple>(
  initialStateFinSimple,
);
export const FinSimpleDispatchContext = createContext<FinSimpleDispatch>(
  {} as FinSimpleDispatch,
);

export const FinSimpleContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [finSimpleValues, dispatch] = useReducer(
    finSimpleReducer,
    initialStateFinSimple,
  );
  return (
    <FinSimpleDispatchContext.Provider value={dispatch}>
      <FinSimpleStateContext.Provider value={finSimpleValues}>
        {children}
      </FinSimpleStateContext.Provider>
    </FinSimpleDispatchContext.Provider>
  );
};
