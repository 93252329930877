import React, { createContext, useReducer } from 'react';
import sancorReducer from './index.reducer';
import { initialStateSancor } from './initialValues';
import { InitialStateSancor, SancorDispatch } from './index.types';

export const SancorStateContext =
  createContext<InitialStateSancor>(initialStateSancor);
export const SancorDispatchContext = createContext<SancorDispatch>(
  {} as SancorDispatch,
);

export const SancorContext = ({ children }: { children: React.ReactNode }) => {
  const [sancorValues, dispatch] = useReducer(
    sancorReducer,
    initialStateSancor,
  );
  return (
    <SancorDispatchContext.Provider value={dispatch}>
      <SancorStateContext.Provider value={sancorValues}>
        {children}
      </SancorStateContext.Provider>
    </SancorDispatchContext.Provider>
  );
};
