/* eslint-disable no-return-await */
import { useEffect, useState } from 'react';
import { ProcedureAvailable } from 'models/procedure';
import api from 'services/api/api';
import { useData } from 'hooks/useData';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'store/root-store/root-store-context';
import { changeDataByAlias } from 'utils/common';
import { CuitsRoot } from 'store/cuit-store/cuitModel';

export const useExternalScreenHook = () => {
  const {
    sessionStore,
    cuitsStore: { getCuits, cuitsByEstados },
  } = useStores();
  const [procedureSelected, setProcedure] = useState<ProcedureAvailable | null>(
    null,
  );
  const [open, setOpen] = useState(false);
  const companiesByState: CuitsRoot[] = cuitsByEstados();
  const fieldsFetcher = async () => await api.home.getMyFields();
  const companyFetcher = async () => await api.home.getMyBusiness();
  const highlightedProcedures = async () =>
    await api.home.getHighlightedProcedures();

  const { data: fields, isLoading: loadingFields } = useData(
    fieldsFetcher,
    `${sessionStore.currentUser?.cuentaActual}-mis-campos`,
  );
  const { data: companies, isLoading: loadingCompanies } = useData(
    companyFetcher,
    `${sessionStore.currentUser?.cuentaActual}-mis-empresas`,
  );
  const { data: procedures, isLoading: loadingProdcedures } = useData(
    highlightedProcedures,
    `${sessionStore.currentUser?.cuentaActual}-tramites-destacados`,
  );

  const completeFields = fields?.totalPreparados;
  const incompleteFields = fields?.total - fields?.totalPreparados;
  const completeCompanies = companies?.totalTerminadas;
  const incompleteCompanies = companies?.total - companies?.totalTerminadas;

  const areThereFields = fields?.total !== 0;
  const areThereCompanies = companies?.total !== 0;

  const redirectWithAliasProcedure = changeDataByAlias(
    procedureSelected?.subTipoTramite.tipoTramite.alias,
    'certificaciones',
  );

  const navigate = useNavigate();
  const handleOpen = (certification: ProcedureAvailable) => {
    setProcedure(certification);
    setOpen(true);
  };
  const handleClose = () => {
    setProcedure(null);
    setOpen(false);
  };

  useEffect(() => {
    getCuits();
  }, []);

  return {
    state: {
      completeFields,
      incompleteFields,
      completeCompanies,
      companiesByState,
      incompleteCompanies,
      areThereFields,
      areThereCompanies,
      procedures,
      procedureSelected,
      open,
      loadingFields,
      loadingCompanies,
      loadingProdcedures,
      redirectWithAliasProcedure,
    },
    action: {
      navigate,
      handleOpen,
      handleClose,
    },
  };
};