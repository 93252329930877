import { H3, H5, P3 } from '../../typography/styles';
import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import colors from 'theme/colors';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { dialogTitle } from './styles';
import { DialogContainer } from '../index.styled';

type IModalForm = {
  open: boolean;
  handleClose: () => void;
  title: string;
  description: string;
  iconChildren?: React.ReactNode;
  formChildren: React.ReactNode;
  image: string;
  comeFrom?: string;
};

export const ModalForm = ({
  open,
  handleClose,
  title,
  iconChildren,
  formChildren,
  image,
  description = '',
  comeFrom,
}: IModalForm) => {
  const widthImage = '44%';

  return (
    <DialogContainer open={open} onClose={handleClose}>
      <Grid container>
        <Grid
          item
          minHeight={{ xs: '100%', tablet: '400px' }}
          width={{
            xs: '100%',
            tablet: `calc(100% - ${widthImage})`,
          }}
        >
          <Stack
            p={{
              xs: '16px',
              tablet: '45.5px 62px 32px 56px',
            }}
          >
            <Box
              sx={{
                ...dialogTitle,
                display: {
                  xs: 'none',
                  tablet: 'flex',
                },
              }}
            >
              {iconChildren}
              <H3 style={{ color: 'inherit' }}>{title}</H3>
            </Box>
            <Box
              display={{
                xs: 'flex',
                tablet: 'none',
              }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box sx={dialogTitle}>
                {iconChildren}
                <H5 style={{ color: 'inherit' }}>{title}</H5>
              </Box>
              <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
                <CloseRoundedIcon sx={{ color: colors.tertiaryBase }} />
              </Box>
            </Box>

            <Box mt={{ xs: '8px', tablet: '16px' }}>
              <P3 style={{ color: colors.tertiaryMedium }}>{description}</P3>
            </Box>

            <Box mt="16px">{formChildren}</Box>
          </Stack>
        </Grid>
        <Grid
          item
          width={widthImage}
          bgcolor={colors.tertiaryHigh}
          display={{
            xs: 'none',
            tablet: 'initial',
          }}
        >
          <Box position="relative" height="calc(100% - 16px)" mt="16px">
            {comeFrom && (
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    tablet: 'initial',
                  },
                  position: 'absolute',
                  cursor: 'pointer',
                  top: -8,
                  right: 8,
                }}
                onClick={handleClose}
              >
                <CloseRoundedIcon sx={{ color: colors.tertiaryBase }} />
              </Box>
            )}
            <Box
              id="image"
              height="100%"
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </DialogContainer>
  );
};
