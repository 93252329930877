import styled from 'styled-components';
import InputBase from '@mui/material/InputBase';
import { MenuItem } from '@mui/material';
import colors from 'theme/colors';

export const StyledAccountSelector = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: 'transparent',
    border: '0px',
    fontSize: 16,
    padding: '5px 16px',
    display: 'flex',
    alignItems: 'center',
    color: colors.tertiaryBase,
  },

  '.MuiSelect-select .MuiBox-root': {
    width: '70vw',

    '@media (min-width: 768px)': {
      width: '240px',
    },
  },
}));

export const ItemSelectContainer = styled(MenuItem)`
  &.MuiMenuItem-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 16px;
    color: ${colors.tertiaryBase};
    &:hover {
      background-color: ${colors.white};
    }
    @media (max-width: 767px) {
      min-height: 28px;
    }
  }

  &:hover {
    color: ${colors.primaryBase};
  }
`;
