import React, { createContext, useReducer } from 'react';
import misEmpresasReducer from './index.reducer';
import { initialStateMisEmpresas } from './initialValues';
import { InitialStateMisEmpresas, MisEmpresasDispatch } from './index.types';

export const MisEmpresasStateContext = createContext<InitialStateMisEmpresas>(
  initialStateMisEmpresas,
);
export const MisEmpresasDispatchContext = createContext<MisEmpresasDispatch>(
  {} as MisEmpresasDispatch,
);

export const MisEmpresasContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [misEmpresasValues, dispatch] = useReducer(
    misEmpresasReducer,
    initialStateMisEmpresas,
  );
  return (
    <MisEmpresasDispatchContext.Provider value={dispatch}>
      <MisEmpresasStateContext.Provider value={misEmpresasValues}>
        {children}
      </MisEmpresasStateContext.Provider>
    </MisEmpresasDispatchContext.Provider>
  );
};