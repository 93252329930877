import React from 'react';
import { observer } from 'mobx-react';
import { Box, List, ListItemButton, Popover } from '@mui/material';
import { P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { useStores } from 'store/root-store/root-store-context';
import { LotPolygon, Perimeter } from 'models/map';
import MapDraw from 'components/common/icon/icons/mapDraw';
import {
  ItemP4,
  ListItemStyled,
  ListItemWithoutPolygon,
  ListHoverItemLot,
} from './index.styled';

interface ILotesDropdown {
  open: boolean;
  handleClosePopover: () => void;
  anchorEl: HTMLDivElement | null;
  perimeters: Perimeter[];
  lots: LotPolygon[];
  handleSelectItem: (lot: LotPolygon | null) => void;
  selectedLot: number;
}

const LotesDropdown: React.FC<ILotesDropdown> = (props) => {
  const { mapStore } = useStores();
  const {
    open,
    handleClosePopover,
    anchorEl,
    perimeters,
    lots,
    handleSelectItem,
    selectedLot,
  } = props;

  const selectedHasPolygon = perimeters.filter(
    (perimeter) =>
      !lots.find(
        (item) =>
          item.nombrePoligono === perimeter.nombrePoligono && item.assigned,
      ),
  );

  const noSelectedPolygon = lots.find(
    (item) => item.id === selectedLot && !item.assigned,
  );
  return (
    <Popover
      open={open}
      onClose={handleClosePopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      key={selectedLot}
    >
      <List
        className="item-lotes"
        sx={{
          width: '200px',
          maxHeight: '315px',
          overflowY: 'auto',
        }}
      >
        <ListItemWithoutPolygon disablePadding>
          {selectedHasPolygon.length > 0 && perimeters.length > 0 ? (
            <>
              {noSelectedPolygon === undefined ? (
                <ListHoverItemLot
                  dense
                  onClick={() => {
                    handleSelectItem(null);
                    handleClosePopover();
                  }}
                >
                  <P4 color={colors.tertiaryMedium}>No elegir este poligono</P4>
                </ListHoverItemLot>
              ) : (
                <P4
                  color={colors.tertiaryMedium}
                  style={{ padding: '4px 16px' }}
                >
                  Sin poligono elegido
                </P4>
              )}
            </>
          ) : (
            <Box>
              {noSelectedPolygon !== undefined ? (
                <P4
                  color={colors.tertiaryMedium}
                  style={{ padding: '4px 16px' }}
                >
                  Sin poligono elegido
                </P4>
              ) : (
                <ListHoverItemLot
                  dense
                  onClick={() => {
                    handleSelectItem(null);
                  }}
                >
                  <P4 color={colors.tertiaryMedium}>No elegir este poligono</P4>
                </ListHoverItemLot>
              )}
              <ListHoverItemLot
                dense
                onClick={() => {
                  mapStore.setDrawPolygon(true);
                  handleClosePopover();
                }}
              >
                <MapDraw className="svg" />
                <P4 color={colors.tertiaryMedium} style={{ marginLeft: '5px' }}>
                  Dibujá un polígono en el mapa
                </P4>
              </ListHoverItemLot>
            </Box>
          )}
        </ListItemWithoutPolygon>

        {perimeters.length > 0 &&
          selectedHasPolygon.map((perimeter: Perimeter) => {
            return (
              <ListItemStyled disablePadding key={perimeter.id}>
                <ListItemButton
                  dense
                  onClick={() => {
                    const perimeterParsed = perimeter.polygon
                      .flat()
                      .map((point) => {
                        return [point.lat, point.lng];
                      });
                    handleSelectItem({
                      id: perimeter.id,
                      name: perimeter.name,
                      nombrePoligono: perimeter.nombrePoligono,
                      poligono: [...perimeterParsed, perimeterParsed[0]],
                      color: perimeter.color,
                      load: perimeter.load,
                    });
                    handleClosePopover();
                  }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <ItemP4>
                    {perimeter.load ? perimeter.name : perimeter.nombrePoligono}
                  </ItemP4>
                  <Box
                    bgcolor={perimeter.color}
                    minHeight="12px"
                    minWidth="12px"
                    width="12px"
                    height="12px"
                    borderRadius="50%"
                  />
                </ListItemButton>
              </ListItemStyled>
            );
          })}
      </List>
    </Popover>
  );
};

export default observer(LotesDropdown);
