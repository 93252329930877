import { lazy } from 'react';

export const LazySolicitudEnviada = lazy(
  () => import('./EnvioSolicitud/index'),
);

export const LazySolicitudFinalizada = lazy(
  () => import('./SolicitudFinalizada/index'),
);

export const LazySelection = lazy(() => import('./Selection'));
