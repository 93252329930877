import React from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Caps } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { StyledChip } from './index.styled';

type DataVerificationChipProps = {
  text: string;
  isValid: boolean;
};

const DataVerificationChip: React.FC<DataVerificationChipProps> = (props) => {
  const { text, isValid } = props;

  if (isValid) {
    return (
      <StyledChip bgcolor={colors.primaryHigh}>
        <CheckCircleRoundedIcon
          sx={{ color: colors.complementaryGreen, fontSize: '16px' }}
        />
        <Caps color={colors.complementaryGreen}>{text}</Caps>
      </StyledChip>
    );
  }

  return (
    <StyledChip bgcolor="#FDF1E8">
      <WarningRoundedIcon
        sx={{ color: colors.secondaryBase, fontSize: '16px' }}
      />
      <Caps color={colors.secondaryBase}>{text}</Caps>
    </StyledChip>
  );
};

export default DataVerificationChip;
