import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CrearCuenta } from './crear-cuenta';
import AuthLayout from 'components/layouts/authLayout';
import Login from 'screens/portal/iniciar-sesion';
import Register from 'screens/portal/invitacion';
import RecoverAccount from 'screens/portal/recuperar-cuenta';
import ValidateErpContainer from 'screens/portal/activar-cuenta/ContainerValidate';
import RecoverAccountDone from './solicitud-recibida';
import RecoverVerify from './recuperar-verificar';
import RequestAccount from './solicitar-cuenta';
import RequestAccountDone from './solicitud-cuenta-recibida';
import ValidateTokenFromErp from './activar-cuenta';
import { ExternalLoginValidate } from './activar-cuenta/ExternalLoginValidate';

const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID || '';

const Auth: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthLayout>
        <Routes>
          <Route path="/" element={<ValidateTokenFromErp />} />
          <Route
            path="/external_login/:uuid"
            element={<ExternalLoginValidate />}
          />
          <Route path="/iniciar-sesion" element={<Login />} />
          <Route path="/invitacion" element={<Register />} />
          <Route path="/crear-cuenta" element={<CrearCuenta />} />
          <Route path="/recuperar-cuenta" element={<RecoverAccount />} />
          <Route path="/solicitud-recibida" element={<RecoverAccountDone />} />
          <Route path="/recuperar-verificar" element={<RecoverVerify />} />
          <Route path="/solicitar-cuenta" element={<RequestAccount />} />
          <Route
            path="/solicitud-cuenta-recibida"
            element={<RequestAccountDone />}
          />
          <Route path="/activar-cuenta" element={<ValidateErpContainer />} />
        </Routes>
      </AuthLayout>
    </GoogleOAuthProvider>
  );
};

export default Auth;
