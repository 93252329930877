export const modalContact = {
  contacto: {
    email: 'contacto@agrology.com',
    firstText:
      'Si tenés alguna duda o sugerencia envianos un mensaje y te responderemos a la brevedad.',
    secondText: 'También podés escribinos directamente a ',
  },
  tramites: {
    email: 'tramites@agrology.com',
    firstText:
      'Si tenés alguna duda o sugerencia envianos un mensaje y te responderemos a la brevedad.',
    secondText: 'También podés escribinos directamente a ',
  },
};
