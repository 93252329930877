import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
`;

export const ContainerNubosity = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContainerDayAndDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerTemperatureAnSdvg = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0px 8px 0px;
`;

export const ContainerTemperature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerWind = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const ContainerRain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContainerCertWeather = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const CertWeather = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 755px) {
    justify-content: flex-start;
  }
  @media (min-width: 756px) {
    justify-content: space-around;
  }
`;

export const BoxWeather = styled.div`
  @media (max-width: 755px) {
    justify-content: flex-start;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;
