/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Box, Divider, useMediaQuery } from '@mui/material';
import { H3, P2 } from 'components/common/typography/styles';
import Content from 'components/layouts/content';
import colors from 'theme/colors';
import useWindowDimensions from 'hooks/useWindowsSize';
import { Certifications } from 'components/sections/procedures/certifications/Certifications';
import { ProceduresOnProcess } from 'components/sections/procedures/certifications/ProceduresOnProcess';
import { ProceduresFinished } from 'components/sections/procedures/certifications/ProceduresFinished';
import ProcedureModal from 'components/common/modals/procedureModal';
import { ProcedureAvailable } from 'models/procedure';
import { useStores } from 'store/root-store/root-store-context';
import DesktopAlert from 'components/common/alerts/desktopAlert';
import UploadCompanyModal from 'components/common/modals/uploadCompanyModal';
import CommomModalProcedure from 'components/common/modals/commonModalProcedures';
import { CardListCommonProcedureModal } from './huellaDeCarbono/components/CardListCommonProcedureModal';
import { isProcedureEqualToHuellaDeCarbono } from 'utils/common';
import { Helmet } from 'react-helmet';

const MyCertificationsScreen: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [openUploadCompanies, setOpenUploadCompanies] = useState(false);
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const { width } = useWindowDimensions();
  const { sessionStore, cuitsStore } = useStores();
  const [openAlert, setOpenAlert] = useState(false);
  const {
    sessionStore: { isExternal },
    cuitsStore: { cuitsByEstados },
  } = useStores();
  const [procedure, setProcedure] = useState<ProcedureAvailable | null>(null);
  const isMobile = useMediaQuery('(max-width:425px)');
  const approvedCompanies = cuitsByEstados();

  const handleClose = () => {
    setProcedure(null);
    setOpen(false);
  };

  const handleOpen = (certification: ProcedureAvailable, comeFrom?: string) => {
    setProcedure(certification);
    if (approvedCompanies?.length <= 0) {
      setOpenUploadCompanies(true);
    } else if (
      approvedCompanies?.length > 0 &&
      isProcedureEqualToHuellaDeCarbono(certification) &&
      comeFrom === 'iniciar_solicitud'
    ) {
      setOpenCommonModal(true);
    } else {
      setOpen(true);
    }
  };

  const titlePage =
    sessionStore.getMenuItemByCode('certificaciones')?.seccion ||
    'Certificaciones';

  useEffect(() => {
    cuitsStore.getCuits();
  }, [cuitsStore]);

  return (
    <Content justifyContent="flex-start">
      <Helmet>
        <title>Agrology | Sustentabilidad</title>
      </Helmet>
      <>
        {(isExternal || approvedCompanies?.length <= 0) && (
          <UploadCompanyModal
            imgHeader={procedure?.imgHeader || ''}
            redirectTo="/micuenta"
            logos={[procedure?.entidad.logo || '']}
            title={procedure?.nombre || ''}
            subtitle={procedure?.entidad.nombre || ''}
            open={openUploadCompanies}
            onClose={() => setOpenUploadCompanies(false)}
            onlyRead={false}
            width={1150}
          />
        )}
        {isExternal &&
          procedure?.alias &&
          approvedCompanies?.length > 0 &&
          isProcedureEqualToHuellaDeCarbono(procedure) && (
            <CommomModalProcedure
              imgHeader={procedure?.imgHeader || ''}
              redirectTo={`/certificaciones/${procedure.alias}`}
              logos={[procedure?.entidad.logo || '']}
              title={procedure?.nombre || ''}
              subtitle={procedure?.entidad.nombre || ''}
              open={openCommonModal}
              onClose={() => setOpenCommonModal(false)}
              onlyRead={false}
              children={<CardListCommonProcedureModal isMobile={isMobile} />}
              width={1150}
              textButton="Empecemos"
            />
          )}
        {procedure && (
          <ProcedureModal
            imgHeader={procedure.imgHeader}
            redirectTo={`/certificaciones/${procedure.alias}`}
            markdownTemplate={procedure.markdownTemplate}
            logos={[procedure.entidad.logo || '']}
            title={procedure.nombre}
            subtitle={procedure.entidad.nombre}
            open={open}
            onClose={handleClose}
            onlyRead={false}
            alias={procedure.alias}
          />
        )}

        <Box paddingBottom="24px">
          <H3 color={colors.tertiaryLow} style={{ marginBottom: '16px' }}>
            {titlePage}
          </H3>

          <P2 style={{ width: '100%', marginBottom: '24px' }}>
            Te ofrecemos los mejores proveedores para certificar tus campos
            obteniendo una mayor ganancia, sin intermediarios y simplificando
            todo el proceso.
          </P2>

          <Certifications width={width} handleOpen={handleOpen} />

          <Divider />

          <Box mt="24px">
            <ProceduresOnProcess setOpenAlert={setOpenAlert} />
          </Box>

          <ProceduresFinished setOpenAlert={setOpenAlert} />
        </Box>
        <DesktopAlert
          showModal={openAlert}
          handleClose={() => setOpenAlert(false)}
        />
      </>
    </Content>
  );
};

export default MyCertificationsScreen;
