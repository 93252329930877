import React from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import colors from 'theme/colors';
import { WrapperButtons, Title, Message } from './index.styled';
import Button from '../buttons/button';

interface AlertProps {
  btnPrimText?: string;
  btnSecText?: string;
  callbackPrim?: () => void;
  callbackSec?: () => void;
  title: string;
  message: string | React.ReactNode;
  showModal: boolean;
  handleClose: () => void;
}

const Alert: React.FC<AlertProps> = (props) => {
  const {
    btnPrimText,
    btnSecText,
    callbackPrim,
    callbackSec,
    title,
    message,
    showModal,
    handleClose,
  } = props;

  return (
    <Dialog
      open={!!showModal}
      onClose={handleClose}
      scroll="paper"
      PaperProps={{
        elevation: 24,
      }}
    >
      <DialogTitle>
        <Box position="relative">
          <IconButton
            sx={{
              position: 'absolute',
              top: -12,
              right: -12,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Title sx={{ color: colors.complementaryGreen }}>{title}</Title>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          '@media(min-width: 780px)': {
            width: '25rem',
          },
        }}
      >
        <Message>{message}</Message>
      </DialogContent>

      {(btnPrimText || btnSecText) && (
        <DialogActions>
          <WrapperButtons>
            {btnSecText && callbackSec && (
              <Button
                variant="outlined"
                preset="normal"
                size="small"
                onClick={callbackSec}
                text={`${btnSecText}`}
              />
            )}
            {btnPrimText && callbackPrim && (
              <Button
                variant="contained"
                preset="normal"
                size="small"
                onClick={callbackPrim}
                text={btnPrimText}
              />
            )}
          </WrapperButtons>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Alert;
