import styled from 'styled-components';
import colors from 'theme/colors';

export const ErrorMsgScreenDesktopStyled = styled.div<{ hOffset: string }>`
  width: 100%;
  margin: 24px 0;
  height: max-content;
  /* max-height: calc(100vh - 48px - ${(props) => props.hOffset}); */
  max-width: 738px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ErrorMsgScreenStyled = styled.div`
  background-color: ${colors.tertiaryHigh};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .mobile-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .mobile-header {
    margin-top: 2rem;
  }

  .mobile-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 301px;
  }

  .mobile-footer {
    display: flex;
    align-items: center;
    color: ${colors.tertiaryMedium};
    margin-bottom: 2rem;
  }
`;
