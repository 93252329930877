import React, { useState } from 'react';
import { Box } from '@mui/material';
import { H3, P2 } from 'components/common/typography/styles';
import WrapperDrawer from 'components/common/drawer/drawer';
import ModifyDataForm from 'components/common/forms/modifyData';
import ModifyPasswordForm from 'components/common/forms/modifyPassword';
import Content from 'components/layouts/content';
import ProfileAccounts from 'components/sections/tables/profileAccounts';
import ProfileInfo from 'components/sections/tables/profileInfo';
import { useStores } from 'store/root-store/root-store-context';
import colors from 'theme/colors';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';
import { Strings } from 'constants/strings';
import { UserData } from 'utils/types';
import { Helmet } from 'react-helmet';

const MyProfileScreen: React.FC = observer(() => {
  const {
    sessionStore,
    sessionStore: { currentUser },
  } = useStores();
  const [openDrawerData, setOpenDrawerData] = useState(false);
  const [openDrawerPassword, setOpenDrawerPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitForm = async (data: any) => {
    const response = await sessionStore.updateMyInformation(data);

    if (!response.kind) {
      enqueueSnackbar(Strings.notifications.success, {
        variant: 'success',
      });
      setOpenDrawerData(false);
    }
  };
  const hasUserPass = currentUser?.hasPassword;

  return (
    <Content justifyContent="flex-start">
      <Helmet>
        <title>Agrology | Mi perfil</title>
      </Helmet>
      <WrapperDrawer
        open={openDrawerData}
        onClose={() => setOpenDrawerData(false)}
        title="Modificar mis datos"
        description=""
        marginTitle="24px 0px"
      >
        <ModifyDataForm
          handleSubmitForm={handleSubmitForm}
          userData={currentUser as UserData}
        />
      </WrapperDrawer>

      <WrapperDrawer
        open={openDrawerPassword}
        onClose={() => setOpenDrawerPassword(false)}
        title={hasUserPass ? 'Modificar mi contraseña' : 'Generar contraseña'}
        description=""
      >
        <ModifyPasswordForm onClose={setOpenDrawerPassword} />
      </WrapperDrawer>

      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        paddingBottom="24px"
        gap="8px"
      >
        <H3 color={colors.tertiaryLow} style={{ marginBottom: '16px' }}>
          Mi perfil
        </H3>

        <P2
          style={{
            marginBottom: '20px',
            width: '100%',
          }}
        >
          En esta sección encontrarás toda la información relacionada con tu
          cuenta de usuario.
        </P2>

        <ProfileInfo
          setOpenDrawerData={(value) => setOpenDrawerData(value)}
          setOpenDrawerPassword={(value) => setOpenDrawerPassword(value)}
        />

        <ProfileAccounts />
      </Box>
    </Content>
  );
});

export default MyProfileScreen;
