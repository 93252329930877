import React from 'react';
import MuiMarkdown from 'mui-markdown';
import { Box, Stack } from '@mui/material';
import Icon from 'components/common/icon';
import {
  Header1,
  Header2,
  Body1,
  Body2,
  Bullet,
  SmallBullet,
} from './index.styled';

type MdToMuiProps = {
  md: string;
  children?: React.ReactNode;
};

type ListItemProps = {
  children: React.ReactNode;
};

const UnorderedList: React.FC<ListItemProps> = ({ children }) => {
  return (
    <Stack gap="12px" mt="10px" mb="20px">
      {React.Children.map(children, (child, key) => (
        <Box display="flex" gap="12px" key={`ul-${key + 1 - 1}`}>
          <Icon icon="bulletModal" />
          <Bullet>{child}</Bullet>
        </Box>
      ))}
    </Stack>
  );
};

const OrderedList: React.FC<ListItemProps> = ({ children }) => {
  return (
    <Stack gap="8px" mt="10px">
      {React.Children.map(children, (child, key) => (
        <Box
          display="flex"
          gap="8px"
          alignItems="center"
          key={`ol-${key + 1 - 1}`}
        >
          <Icon icon="smallBulletModal" />
          <SmallBullet>{child}</SmallBullet>
        </Box>
      ))}
    </Stack>
  );
};

const MdToMui: React.FC<MdToMuiProps> = ({ md, children }) => {
  const overrides = {
    h1: {
      component: Header1,
    },
    h2: {
      component: Header2,
    },
    h3: {
      component: Body1,
    },
    p: {
      component: Body2,
    },
    ul: {
      component: UnorderedList,
    },
    ol: {
      component: OrderedList,
    },
  };

  return (
    <Stack>
      <MuiMarkdown overrides={overrides}>{md}</MuiMarkdown>
      {children}
    </Stack>
  );
};

export default MdToMui;
