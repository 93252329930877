import React from 'react';
import { List, ListItem, ListItemText, Popover } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Logout from '@mui/icons-material/Logout';
import { P3 } from 'components/common/typography/styles';
import { useNavigate } from 'react-router-dom';
import { ListItemButtonStyled, ListItemIconStyled } from './index.styled';

interface IProfileDropdown {
  anchorEl: HTMLElement | null;
  open: boolean;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  handleLogout: () => void;
}

const ProfileDropdown: React.FC<IProfileDropdown> = (props) => {
  const { anchorEl, open, setAnchorEl, handleLogout } = props;
  const navigate = useNavigate();

  return (
    <Popover
      id="dropdown-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List sx={{ padding: '4px 0' }}>
        <ListItem disablePadding divider>
          <ListItemButtonStyled
            onClick={() => {
              setAnchorEl(null);
              navigate('/perfil');
            }}
          >
            <ListItemIconStyled>
              <PersonIcon
                sx={{
                  color: 'inherit',
                }}
                fontSize="small"
              />
            </ListItemIconStyled>
            <ListItemText>
              <P3 style={{ color: 'inherit' }}>Ver perfil</P3>
            </ListItemText>
          </ListItemButtonStyled>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButtonStyled
            onClick={() => {
              setAnchorEl(null);
              handleLogout();
            }}
          >
            <ListItemIconStyled>
              <Logout
                sx={{
                  color: 'inherit',
                }}
                fontSize="small"
              />
            </ListItemIconStyled>
            <ListItemText>
              <P3 style={{ color: 'inherit' }}>Cerrar sesión</P3>
            </ListItemText>
          </ListItemButtonStyled>
        </ListItem>
      </List>
    </Popover>
  );
};

export default ProfileDropdown;
