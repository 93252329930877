import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { H4, P3 } from 'components/common/typography/styles';
import AgrologyLogo from 'assets/Logos/agrology-mobile.png';
import UrlDesktop from 'assets/icons/url-desktop.svg?react';
import { Link } from '@mui/material';
import colors from 'theme/colors';
import { ErrorMsgScreenStyled } from './index.styled';

const MobileErrorScreen: React.FC = () => {
  return (
    <ErrorMsgScreenStyled>
      <div className="mobile-wrapper">
        <div className="mobile-header">
          <img loading="lazy" src={AgrologyLogo} alt="agrology-logo" />
        </div>
        <div className="mobile-middle">
          <UrlDesktop />
          <H4 style={{ margin: '24px 0 8px 0' }}>
            Visitanos desde una tablet o computadora
          </H4>
          <P3>
            Nuestra plataforma se aprecia mejor desde una pantalla más grande.
          </P3>
        </div>
        <div className="mobile-footer">
          <P3>Si tenés algúna duda&nbsp;</P3>
          <Link
            href="mailto:contacto@agrology.com"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            sx={{
              textUnderlineOffset: '2px',
              display: 'flex',
              color: colors.tertiaryMedium,
              marginTop: '2px',
            }}
          >
            <MailOutlineIcon />
            <P3>Contactános</P3>
          </Link>
        </div>
      </div>
    </ErrorMsgScreenStyled>
  );
};

export default MobileErrorScreen;
