import styled from 'styled-components';

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;

  .wrapper-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px;
    max-width: 480px;
  }

  .auth-title {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
  }

  .activate-title {
    margin-bottom: 40px;
    width: 100%;
  }

  .url-erp {
    display: flex;
    flex-direction: column;
  }

  .container-url-erp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  @media (max-width: 1023px) {
    width: 100%;
    align-items: center;

    .auth-title,
    .activate-title {
      text-align: left;
      margin-bottom: 24px;
    }

    .auth-parag {
      padding: 0px;
    }
  }
`;
