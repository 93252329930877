import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import colors from 'theme/colors';

interface SwiperHomeProps {
  buttonprev: string;
  buttonnext: string;
}

export const SwiperHome = styled(Swiper)<SwiperHomeProps>`
  border-radius: 8px;

  .swiper {
    padding: 0;
  }

  .swiper-button-prev {
    background-image: ${(props) => `url("${props.buttonprev}")`};
    left: 16;
  }

  .swiper-button-next {
    background-image: ${(props) => `url("${props.buttonnext}")`};
    right: 16;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 35px;
    opacity: 0;
  }

  &:hover {
    .swiper-button-next {
      opacity: 1;
    }
  }
  &:hover {
    .swiper-button-prev {
      opacity: 1;
    }
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    display: none;
  }

  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background: ${colors.primaryHigh};
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: #6cc72b;
  }

  @media (max-width: 767px) {
    height: 260px;
    border-radius: 0px 0px 16px 16px;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
`;

export const SwiperSlideHome = styled(SwiperSlide)`
  .swiper-slide {
    width: 100%;
  }
`;

interface SwiperDarumaProps {
  buttonPrev: string;
  buttonNext: string;
}

interface ISwiperLoginHome {
  buttonprev: string;
  buttonnext: string;
  lengtharr: number;
}

export const SwiperDaruma = styled(Swiper)<SwiperDarumaProps>`
  width: 100%;
  height: 100%;
  padding-bottom: 30px;

  .swiper-button-prev {
    background-image: ${(props) => `url("${props.buttonPrev}")`};
    left: 0;
  }

  .swiper-button-next {
    background-image: ${(props) => `url("${props.buttonNext}")`};
    right: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 35px;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    display: none;
  }

  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
  }
  .swiper-pagination-bullet-active {
    background: #6cc72b;
  }
  @media (max-width: 768px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  @media (min-width: 320px) {
    .swiper {
      padding: 10px 5px 30px 5px;
    }
  }
  @media (min-width: 768px) {
    .swiper {
      padding: 10px 20px 30px 20px;
    }
    .swiper-slide {
      max-width: 300px;
    }
  }
  @media (min-width: 867px) {
    .swiper {
      padding: 10px 25px 30px 20px;
    }
  }
`;

export const SwiperSlideDaruma = styled(SwiperSlide)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SwiperLoginHome = styled(Swiper)<ISwiperLoginHome>`
  border-radius: 0px 25px 25px 0px;

  .swiper-button-prev {
    background-image: ${(props) => `url("${props.buttonprev}")`};
    left: 5px;
    width: 36px;
    height: 43px;
    display: flex;
  }

  .swiper-button-next {
    background-image: ${(props) => `url("${props.buttonnext}")`};
    right: 10px;
    width: 36px;
    height: 43px;
    display: flex;
  }

  height: auto;
  .swiper {
    padding: 0;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    display: none;
  }

  &:hover {
    .swiper-button-next {
      display: ${(props) => (props.lengtharr >= 2 ? 'flex' : 'none')};
    }
  }
  &:hover {
    .swiper-button-prev {
      display: ${(props) => (props.lengtharr >= 2 ? 'flex' : 'none')};
    }
  }

  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background: ${colors.primaryHigh};
  }
  .swiper-pagination-bullet-active {
    background: #6cc72b;
  }

  @media (min-width: 1024px) {
    width: 50%;
    object-fit: cover;
  }

  @media (max-width: 1023px) {
    width: 100%;
    object-fit: cover;
    border-radius: 0px 0px 16px 16px;
  }

  @media (min-width: 767px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
  @media (max-width: 760px) {
    height: 229px;
    width: 100%;
    object-fit: contain;
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
`;

export const SwiperSlideLoginHome = styled(SwiperSlide)`
  .swiper-slide {
    width: 100%;
  }
`;
