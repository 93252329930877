import React from 'react';
import { StyledContent, StyledWrapperContent } from './index.styled';

// TODO: Export this interface from utils/types
interface ContentProps {
  flexDirection?: 'row' | 'column';
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  alignItems?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  hOffset?: string;
  children: React.ReactNode;
  scroll?: string;
}

const Content: React.FC<ContentProps> = ({
  flexDirection = 'column',
  justifyContent = 'center',
  alignItems = 'center',
  hOffset = '0px',
  children,
  scroll,
}) => {
  return (
    <StyledWrapperContent
      hOffset={hOffset || '0px'}
      id="content-scroll"
      scroll={scroll}
    >
      <StyledContent
        flexDirection={flexDirection}
        justifyContent={justifyContent}
        alignItems={alignItems}
      >
        {children}
      </StyledContent>
    </StyledWrapperContent>
  );
};

export default Content;
