import React from 'react';
import { Box } from '@mui/material';
import Alert from 'components/common/alerts';
import { P3 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import Button from '../buttons/button';

interface ContactProps {
  showModal: boolean;
  handleClose: () => void;
}

const DesktopAlert: React.FC<ContactProps> = ({ showModal, handleClose }) => {
  return (
    <Alert
      showModal={showModal}
      handleClose={handleClose}
      title="Visitanos en la computadora"
      message={
        <Box width="100%">
          <P3 color={colors.tertiaryMedium}>
            Visitá <b>app.agrology.com</b> desde tu computadora para iniciar un
            nuevo trámite o ver su detalle.
          </P3>
          <Box width="100%" marginTop="16px">
            <Button
              variant="contained"
              preset="normal"
              size="small"
              onClick={handleClose}
              text="OK"
            />
          </Box>
        </Box>
      }
    />
  );
};

export default DesktopAlert;
