/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'store/root-store/root-store-context';
import api from 'services/api/api';
import { SancorContext } from 'contexts/Sancor';
import { SancorContainer } from './Sancor-Risk';
import { Helmet } from 'react-helmet';

const SeguroSubtipoRoutes: React.FC = () => {
  const [subtipo, setSubtipo] = React.useState<string | null>(null);
  const { sessionStore } = useStores();
  const { alias } = useParams();
  const navigate = useNavigate();

  const getCurrentProcedure = async () => {
    if (alias) {
      const response = await api.procedure.getSelectedProcedure(alias);
      if (!(response && response.alias === alias)) {
        navigate('/', { replace: true });
      }
      setSubtipo(response.subTipoTramite.alias);
    }
  };

  useEffect(() => {
    getCurrentProcedure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);

  return (
    <>
      <Routes>
        {subtipo === 'seguro_multiriesgo' && (
          <Route
            path="/*"
            element={
              sessionStore.isUserAdmin ? (
                <SancorContext>
                  <SancorContainer />
                </SancorContext>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        )}
      </Routes>
    </>
  );
};

export default observer(SeguroSubtipoRoutes);
