import { Box, styled } from '@mui/material';

interface IIcon {
  status: 'ongoing' | 'finished' | 'rejected';
}

interface IProcedureInfo {
  status: 'ongoing' | 'finished' | 'rejected';
}

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 48px);
  align-items: center;
`;

const bgGradientByStatus = (status: string) => {
  if (status === 'ongoing') {
    return 'linear-gradient(90deg, #FFF -0.19%, #FFF 100%)';
  }
  if (status === 'finished') {
    return 'linear-gradient(90deg, #6CC72B -0.19%, #48BB88 100%)';
  }
  return 'linear-gradient(90.06deg, #D00B0B 0.06%, #FF3C00 99.95%)';
};

export const StyledProcedureInfo = styled(Box)(
  ({ status }: IProcedureInfo) => ({
    display: 'flex',
    zIndex: 5,
    justifyContent: 'space-between',
    width: 'calc(100% - 48px)',
    background: bgGradientByStatus(status),
    padding: '16px 24px',
    '.logo': {
      height: '48px',
      width: '48px',
    },
  }),
);

export const StyledChildrenContainer = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - 48px - 81px);
  overflow-y: auto;
`;

export const StyledChildrenWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 48px);
  margin: 24px 0 0 0;
  height: 100%;
  padding: 0 24px;

  @media (max-width: 767px) {
    width: calc(100% - 32px);
    padding: 0 16px;
    margin: 12px 0 0 0;
  }
`;

export const StyledIcon = styled('img')<IIcon>`
  width: 'auto';
  height: 100%;
  filter: ${({ status }) =>
    status !== 'ongoing'
      ? 'brightness(0%) invert(1)'
      : 'brightness(100%) invert(0)'};
`;
