import React from 'react';
import { H3, P2 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { ErrorMsgScreenDesktopStyled } from './index.styled';

interface ErrorMsgScreenProps {
  icon: React.ReactNode;
  titleError: string;
  subtitleError: string;
  hOffset?: string;
}

const ErrorMsgScreen: React.FC<ErrorMsgScreenProps> = ({
  icon,
  titleError,
  subtitleError,
  hOffset = '0px',
}) => {
  return (
    <ErrorMsgScreenDesktopStyled hOffset={hOffset || '0px'}>
      <div>{icon}</div>
      <H3
        color={colors.tertiaryMedium}
        style={{
          width: '100%',
          maxWidth: '900px',
          textAlign: 'center',
          marginBottom: '8px',
        }}
      >
        {titleError}
      </H3>
      {subtitleError && <P2 color={colors.tertiaryMedium}>{subtitleError}</P2>}
    </ErrorMsgScreenDesktopStyled>
  );
};

export default ErrorMsgScreen;
