import { Stack } from '@mui/material';
import EventBtn from 'components/common/buttons/eventBtn';
import DataVerificationChip from 'components/common/chips/dataVerification';
import { P3, P2 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import LayersIcon from '@mui/icons-material/Layers';
import parseHas from 'utils/parseHas';

type CamposModuleProps = {
  hectareas: number | null;
  openMap: () => void;
};

const CamposModule = ({ hectareas, openMap }: CamposModuleProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px="16.5px"
      pb="16px"
      pt="14px"
    >
      <P3 sx={{ fontWeight: '600', color: colors.tertiaryLow }}>Campos</P3>
      {hectareas ? (
        <P2 color={colors.complementaryGreen}>
          {parseHas(hectareas.toString())} ha
        </P2>
      ) : (
        <Stack textAlign="center">
          <DataVerificationChip text="Falta de polígono" isValid={false} />
        </Stack>
      )}
      <Stack width="48px">
        <EventBtn
          onPress={openMap}
          text="Ver en mapa"
          primary={false}
          icon={
            <LayersIcon
              sx={{
                color: colors.primaryBase,
                fontSize: 16,
                marginRight: '4px',
              }}
            />
          }
        />
      </Stack>
    </Stack>
  );
};

export default CamposModule;
