import {
  HuellaDeCarbonoAction,
  InitialStateHuellaDeCarbono,
} from './index.types';

export default function huellaDeCarbonoReducer(
  initialValues: InitialStateHuellaDeCarbono,
  action: HuellaDeCarbonoAction,
) {
  switch (action.type) {
    case 'set-cultivosIds': {
      return { ...initialValues, cultivosIds: action.value };
    }

    case 'set-stateProcedure': {
      return { ...initialValues, stateProcedure: action.value };
    }

    case 'set-refused': {
      return { ...initialValues, refused: action.value };
    }

    case 'set-moveForward': {
      return { ...initialValues, moveForward: action.value };
    }

    case 'set-fields': {
      return { ...initialValues, fieldsByCuitAndAlias: action.value };
    }

    case 'set-subtipo': {
      return { ...initialValues, subtipo: action.value };
    }

    default:
      return initialValues;
  }
}