import React from 'react';
import { Box, IconButton } from '@mui/material';
import { H4, P2, P3 } from 'components/common/typography/styles';
import CloseIcon from '@mui/icons-material/Close';
import colors from 'theme/colors';
import { useSnackbar } from 'notistack';
import api from 'services/api/api';
import { useStores } from 'store/root-store/root-store-context';
import { Strings } from 'constants/strings';
import { Formik } from 'formik';
import InputTextarea from 'components/common/inputs/textarea';
import { ContactSchema } from 'utils/schema-validations';
import { modalContact } from 'utils/modals';
import { DialogBody, DialogContainer, DialogHeader } from './index.styled';
import Button from '../buttons/button';

export interface ContactModalProps {
  open: boolean;
  openConfirmMessage: () => void;
  onClose: () => void;
  type: 'contacto' | 'tramites';
}

enum FieldNames {
  fieldTextarea = 'textarea',
}

const ContactModal: React.FC<ContactModalProps> = ({
  open,
  onClose,
  openConfirmMessage,
  type,
}) => {
  const initialValues = {
    [FieldNames.fieldTextarea]: '',
  };

  const { enqueueSnackbar } = useSnackbar();
  const { sessionStore } = useStores();

  const requestContact = async (values: { [key: string]: string }) => {
    const responseAccount = await api.me.getMyAccount();
    const message = `
    <ul>
        <li><b>Nombres</b>: ${sessionStore.currentUser?.firstName}</li>
        <li><b>Apellidos</b>: ${sessionStore.currentUser?.lastName}</li>
        <li><b>Email</b>: ${[sessionStore.currentUser?.email]}</li>
        ${
          sessionStore.currentUser?.telefono
            ? `<li><b>Teléfono</b>: ${sessionStore.currentUser?.telefono}</li>`
            : `<li><b>Teléfono</b>: -</li>`
        }
        <li><b>Empresa o dominio</b>: ${responseAccount.data.dominio}</li>
        <li><b>Comentario</b>: ${values.textarea}</li>
    </ul>
      `;
    const body = {
      recipient:
        import.meta.env.VITE_ENVIRONMENT === 'production'
          ? modalContact[type].email
          : 'nahuel.ponce@agrology.com',
      subject: 'Formulario de contacto Agrology',
      message,
    };
    const response = await api.common.sendMessage(body);
    if (response.ok) {
      openConfirmMessage();
      onClose();
    } else {
      enqueueSnackbar(Strings.notifications.commonError, {
        variant: 'error',
      });
    }
  };

  const email =
    type === 'contacto' ? 'contacto@agrology.com' : 'tramites@agrology.com';

  return (
    <DialogContainer open={open} onClose={onClose} scroll="body" daruma>
      <Box>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            zIndex: 10,
          }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogHeader style={{ padding: '25px 18px' }}>
          <H4 color={colors.complementaryGreen}>Contactanos</H4>
        </DialogHeader>
      </Box>
      <DialogBody style={{ display: 'flex', flexDirection: 'column' }}>
        <P2 color={colors.tertiaryMedium}>{modalContact[type].firstText}</P2>
        <P3 color={colors.tertiaryMedium} style={{ marginTop: '25px' }}>
          {modalContact[type].secondText}{' '}
          <a href={`mailto:${email}`}>{email}</a>
        </P3>
        <Formik
          initialValues={initialValues}
          onSubmit={requestContact}
          validationSchema={ContactSchema}
        >
          {({ handleSubmit, isSubmitting }) => (
            <>
              <Box mt="12px" mb="24px" width="calc(100% - 30px)">
                <InputTextarea
                  id={FieldNames.fieldTextarea}
                  label=""
                  disabled={false}
                  name={FieldNames.fieldTextarea}
                  width="100%"
                  height="116px"
                  placeholder="Dejanos tu mensaje"
                />
              </Box>
              <Box style={{ alignSelf: 'center' }}>
                <Button
                  variant="contained"
                  preset="normal"
                  size="large"
                  onClick={handleSubmit}
                  text="Enviar consulta"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                />
              </Box>
            </>
          )}
        </Formik>
      </DialogBody>
    </DialogContainer>
  );
};

export default ContactModal;
