/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { AnySchema, object } from 'yup';
import {
  Box,
  FormControlLabel,
  Grid,
  RadioGroup,
  useMediaQuery,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import Button from 'components/common/buttons/button';
import WrapperDrawer from 'components/common/drawer/drawer';
import InputNumber from 'components/common/inputs/number';
import SelectInput from 'components/common/inputs/select';
import TextInput from 'components/common/inputs/text';
import UploadImage from 'components/common/inputs/uploadImage';
import LoadFile from 'components/common/inputs/loadFile';
import {
  IUserData,
  IValues,
  InputImage,
  Inputs,
  IHectareasData,
  PasswordsForm,
  IRequestAccount,
} from 'models/dynamicForms';
import {
  capitalLetter,
  checks,
  hasNumber,
  moreEightChar,
  lowercaseLetter,
  pipe,
  specialChar,
} from 'utils/checklistPassUtils';
import { P3, P4 } from 'components/common/typography/styles';
import InputRadio from 'components/common/inputs/radio';
import colors from 'theme/colors';
import { TooltipPassword } from 'components/common/tooltipPassword/TooltipPassword';
import { ChekcListPass } from 'components/common/tooltipPassword/ChekcListPass';
import { CheckItem } from 'utils/types';
import { InputCuit } from 'components/common/inputs/cuit';

type InputForms = {
  inputs: Inputs;
  handleSubmitFormData: (
    data:
      | unknown
      | IValues
      | IUserData
      | IHectareasData
      | PasswordsForm
      | IRequestAccount,
  ) => void;
  validationSchema?: AnySchema | null;
  initialValues?: { [key: string]: string | number | boolean } | null;
  children?: React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  getValuesFromForm?: (v: any, iv: any) => void;
};

const makeValidationSchema = (inputs: InputForms['inputs']) => {
  const schema = inputs.map((columnsInputs) =>
    columnsInputs.map((input) => {
      if (input.type === 'button') return null;
      return { [input.name]: input.validation };
    }),
  );
  return object().shape(Object.assign({}, ...schema.flat()));
};

const makeInitialValues = (inputs: InputForms['inputs']) => {
  const schema = inputs.map((columnsInputs) =>
    columnsInputs.map((input) => {
      if (input.type === 'button' || input.type === 'loadImage') return null;
      return { [input.name]: '' };
    }),
  );
  return Object.assign({}, ...schema.flat());
};

const camelCaseToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

const BaseToFile = async (url: string, name: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], name, { type: 'image/jpeg' });
};

const DynamicForm: React.FC<InputForms> = (props) => {
  const {
    inputs,
    handleSubmitFormData,
    validationSchema,
    initialValues,
    children,
    getValuesFromForm,
  } = props;
  const [isSubmit, setIsSubmit] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const [selectedImg, setSelectedImg] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [titleDrawer, setTitleDrawer] = useState('');
  const [openPopUp, setOpenPopUp] = useState(false);
  const [progress, setProgress] = useState(39);
  const [checkListArr, setCheckListArr] = useState<CheckItem[]>(checks);
  const [valuePass, setValuePass] = useState<string>('');

  const isMobile = useMediaQuery('(max-width:1024px)');

  if (!inputs || !inputs.length) {
    throw new Error('Inputs are required');
  }

  const makeFormData = (values: Record<string, any>) => {
    const form = new FormData();
    Object.keys(values).forEach(async (inputName) => {
      if (values[inputName] instanceof Blob) {
        const inputImage = inputs
          .flat()
          .find(
            (input) => input.type === 'loadImage' && input.name === inputName,
          ) as InputImage;
        if (
          inputImage.fileState &&
          !inputImage.fileState.includes('api.agrology.com')
        ) {
          const file = await BaseToFile(
            inputImage.fileState,
            `${inputImage.name}.jpg`,
          );
          form.append(camelCaseToSnakeCase(inputName), file);
        }
      } else {
        form.append(camelCaseToSnakeCase(inputName), values[inputName]);
      }
    });
    return form;
  };

  const handleSubmitForm = (
    data: Record<string, any> | FormData,
    { setSubmitting }: FormikHelpers<any>,
  ) => {
    if (inputs.flat().some((input) => input.type === 'loadImage')) {
      const form = makeFormData(data);
      handleSubmitFormData(form);
      return;
    }
    handleSubmitFormData(data);
    setSubmitting(false);
  };

  const handleEditor = (imageURL: string) => {
    setSelectedImg(imageURL);
    setOpenEditor(true);
  };

  const handleCroppedImg = (imageURL: string) => {
    setOpenEditor(false);
    const inputImage = inputs
      .flat()
      .find(
        (input) => input.type === 'loadImage' && input.name === selectedType,
      ) as InputImage;
    if (inputImage.setFileState) {
      inputImage.setFileState(imageURL);
    }
  };

  const handleFocus = () => {
    setOpenPopUp(true);
  };
  const handleBlur = () => {
    setOpenPopUp(false);
  };

  const handleMatch = () => {
    const iterable = pipe(
      specialChar,
      hasNumber,
      lowercaseLetter,
      capitalLetter,
      moreEightChar,
    )(valuePass);

    const checksListMatched = checks.map((e) => {
      const matchingItem = iterable.find((v) => v.key === e.key);
      if (matchingItem) {
        e.checked = matchingItem.checked;
      }
      return { ...e };
    });
    return checksListMatched;
  };

  const valueProgress = () =>
    checkListArr.filter((e: CheckItem) => e.checked === true);

  useEffect(() => {
    setCheckListArr(handleMatch());
    setProgress(() => valueProgress().length * 20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuePass, valueProgress().length]);

  return (
    <Box width="100%">
      {children}
      <WrapperDrawer
        open={openEditor}
        onClose={() => setOpenEditor(false)}
        title={titleDrawer}
        description=""
      >
        <UploadImage selectedImg={selectedImg} croppedImg={handleCroppedImg} />
      </WrapperDrawer>
      <Formik
        initialValues={initialValues ?? makeInitialValues(inputs)}
        validationSchema={validationSchema ?? makeValidationSchema(inputs)}
        onSubmit={(data, formikHelpers) =>
          handleSubmitForm(data, formikHelpers)
        }
        validateOnBlur={false}
        validateOnChange={isSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          isValid,
          errors,
          values,
          touched,
        }) => {
          if (isSubmitting && !isSubmit) {
            setIsSubmit(true);
          }
          if (getValuesFromForm) {
            getValuesFromForm(values, initialValues);
          }
          if (values?.password) {
            setValuePass(values?.password);
          }

          return (
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              maxWidth="900px"
            >
              <Box
                display="flex"
                flexDirection={{
                  xs: 'column',
                  tablet: 'row',
                }}
                gap={{
                  xs: 0,
                  tablet: 4,
                }}
              >
                {inputs.map((inputsColumn, keyA) => {
                  if (inputsColumn.length === 0) return null;
                  return (
                    <Grid
                      container
                      direction="column"
                      key={`grid-${keyA - 1 + 1}`}
                    >
                      {inputsColumn.map((input, keyB) => {
                        if (
                          input.type === 'password' &&
                          input.name === 'password'
                        ) {
                          return (
                            <TooltipPassword
                              open={openPopUp}
                              onClose={() => setOpenPopUp(false)}
                              onOpen={() => setOpenPopUp(true)}
                              title={
                                <ChekcListPass
                                  checksList={checkListArr}
                                  progress={progress}
                                />
                              }
                              key={`grid-item-${keyB - 1 + 1}`}
                              placement={isMobile ? 'top' : 'left-start'}
                              arrow
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                            >
                              <Grid item pb="16px">
                                <TextInput
                                  key={input.id}
                                  id={input.id || input.name}
                                  name={input.name}
                                  disabled={Boolean(input.disabled)}
                                  label={input.label || ''}
                                  placeholder={input.placeholder}
                                  type={input.type}
                                  explanation={input.explanation}
                                  width="100%"
                                  height="48px"
                                  handleFocus={handleFocus}
                                  handleBlur={handleBlur}
                                />
                              </Grid>
                            </TooltipPassword>
                          );
                        }
                        if (
                          input.type === 'text' ||
                          input.type === 'email' ||
                          (input.type === 'password' &&
                            input.name !== 'password')
                        ) {
                          return (
                            <Grid
                              item
                              pb="16px"
                              key={`grid-item-${keyB - 1 + 1}`}
                            >
                              <TextInput
                                key={input.id}
                                id={input.id || input.name}
                                name={input.name}
                                disabled={Boolean(input.disabled)}
                                label={input.label || ''}
                                placeholder={input.placeholder}
                                type={input.type}
                                explanation={input.explanation}
                                width="100%"
                                height="48px"
                              />
                            </Grid>
                          );
                        }
                        if (input.type === 'number') {
                          return (
                            <Grid
                              item
                              pb="16px"
                              key={`grid-item-${keyB - 1 + 1}`}
                            >
                              <InputNumber
                                key={input.id}
                                id={input.id || input.name}
                                name={input.name}
                                disabled={Boolean(input.disabled)}
                                label={input.label || input.name}
                                placeholder={input.placeholder}
                                explanation={input.explanation}
                                width="100%"
                                height="48px"
                              />
                            </Grid>
                          );
                        }
                        if (input.type === 'select') {
                          return (
                            <Grid
                              item
                              pb="16px"
                              key={`grid-item-${keyB - 1 + 1}`}
                            >
                              {input.customComponentTop &&
                                input.customComponentTop.map(
                                  (component) => component,
                                )}
                              <SelectInput
                                id={input.id || input.name}
                                label={input.label || input.name}
                                synced={input.synced}
                                disabled={Boolean(input.disabled)}
                                options={input.options}
                                name={input.name}
                              />
                              <Box mt={errors[input.name] ? '0px' : '4px'}>
                                {input.customComponentBottom &&
                                  input.customComponentBottom.map(
                                    (component) => component,
                                  )}
                              </Box>
                            </Grid>
                          );
                        }
                        if (input.type === 'loadImage') {
                          return (
                            <Grid
                              item
                              pb="16px"
                              key={`grid-item-${keyB - 1 + 1}`}
                            >
                              <LoadFile
                                id={input.id || input.name}
                                name={input.name}
                                placeholder={input.placeholder}
                                image={input.fileState || ''}
                                cbUpload={() => {
                                  handleEditor(input.fileState || '');
                                  setSelectedType(input.name);
                                  setTitleDrawer(input.titleDrawer || '');
                                }}
                              />
                            </Grid>
                          );
                        }
                        if (input.type === 'cuit') {
                          return (
                            <Grid
                              item
                              pb="16px"
                              key={`grid-item-${keyB - 1 + 1}`}
                            >
                              <InputCuit
                                key={input.id}
                                id={input.id || input.name}
                                name={input.name}
                                disabled={Boolean(input.disabled)}
                                label={input.label || input.name}
                                placeholder={input.placeholder}
                                width="100%"
                                height="48px"
                              />
                            </Grid>
                          );
                        }
                        if (input.type === 'radio') {
                          return (
                            <Grid
                              item
                              pb="16px"
                              key={`grid-item-${keyB - 1 + 1}`}
                            >
                              {input.customComponentTop &&
                                input.customComponentTop.map(
                                  (component) => component,
                                )}
                              <RadioGroup
                                name={input.name}
                                onChange={handleChange}
                              >
                                <Box display="flex" margin="16px 0 44px 0">
                                  {input.options.map((option) => (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      marginRight="50px"
                                      marginLeft="12px"
                                      key={option.id}
                                    >
                                      <FormControlLabel
                                        label={
                                          <P3
                                            style={{
                                              color: colors.tertiaryBase,
                                            }}
                                          >
                                            {option.label}
                                          </P3>
                                        }
                                        id="finc_client_agrocredit"
                                        control={
                                          <InputRadio
                                            checked={
                                              values[input.name] ===
                                              option.value
                                            }
                                            defaultChecked={
                                              option.value ===
                                              input.defaultCheckedValueToCompare
                                            }
                                          />
                                        }
                                        value={option.value}
                                      />
                                    </Box>
                                  ))}
                                </Box>
                              </RadioGroup>
                              {errors[input.name] && touched[input.name] && (
                                <P4
                                  style={{
                                    color: colors.red,
                                  }}
                                >
                                  {errors[input.name] as string}
                                </P4>
                              )}
                              {input.customComponentBottom &&
                                input.customComponentBottom.map(
                                  (component) => component,
                                )}
                            </Grid>
                          );
                        }
                        return null;
                      })}
                    </Grid>
                  );
                })}
              </Box>
              <Box
                display="flex"
                flexDirection={{
                  xs: 'column',
                  tablet: 'row',
                }}
                gap="8px"
              >
                {inputs.flat().map((input, key) => {
                  if (input.type === 'button') {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { type, ...rest } = input;
                    return (
                      <Button
                        {...rest}
                        loading={input.loading || isSubmitting}
                        disabled={input.disabled || !isValid || isSubmitting}
                        onClick={input.onClick ?? handleSubmit}
                        key={`button-${key - 1 + 1}`}
                      />
                    );
                  }
                  return null;
                })}
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default DynamicForm;

DynamicForm.defaultProps = {
  validationSchema: null,
  initialValues: null,
  children: <></>,
};
