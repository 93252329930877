import React from 'react';
import { Container } from './weather.styled';
import { ArrWeather } from './typesWeather';
import { NubosityComponent } from './NubosityComponent';
import { TemperatureComponent } from './TemperatureComponent';
import { WindComponent } from './WindComponent';
import { RainComponent } from './RainComponent';

export const ContainerWeatherComponent: React.FC<ArrWeather> = ({
  arrWeather,
}) => {
  return (
    <>
      {arrWeather.map(
        (
          {
            dayName,
            maxTemp,
            minTemp,
            nubosity,
            rain,
            windDirection,
            windSpeed,
            shortDate,
          },
          key,
        ) => {
          return (
            <Container key={`${windDirection}-${key + 1 - 1}`}>
              <NubosityComponent
                dayName={dayName}
                nubosity={nubosity}
                shortDate={shortDate}
              />
              <TemperatureComponent maxTemp={maxTemp} minTemp={minTemp} />
              <WindComponent
                windDirection={windDirection}
                windSpeed={windSpeed}
              />
              <RainComponent rain={rain} />
            </Container>
          );
        },
      )}
    </>
  );
};
