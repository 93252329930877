/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Box } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { H5 } from 'components/common/typography/styles';
import { useStores } from 'store/root-store/root-store-context';
import React from 'react';
import api from 'services/api/api';
import colors from 'theme/colors';
import ProcedureAvailableCard from 'components/common/cards/procedures/Available';
import { ProcedureAvailable } from 'models/procedure';
import { useData } from 'hooks/useData';
import { ARRAY_EMPTY } from '../proceduresEmpty/messages.procedures';

export const Certifications: React.FC<{
  width: number;
  handleOpen: (
    procedure: ProcedureAvailable,
    comeFrom?: string,
  ) => void;
}> = ({ width, handleOpen }) => {
  const { sessionStore } = useStores();
  // eslint-disable-next-line no-return-await
  const fetcher = async () => await api.traces.getCertifications();
  const { data, isLoading } = useData(
    fetcher,
    `certification/${sessionStore?.currentUser?.cuentaActual}`,
  );

  return (
    <>
      {(data !== undefined && data.length === ARRAY_EMPTY) ||
      (data === undefined && !isLoading) ? null : (
        <>
          <H5
            id="cert-finisheds-title"
            style={{ color: colors.tertiaryMedium, marginTop: '24px' }}
          >
            Certificaciones disponibles
          </H5>
          <Grid
            height={isLoading ? '251px' : ''}
            container
            alignItems="stretch"
            spacing="12px"
            mt="16px"
            mb="16px"
            sx={{
              '@media (max-width: 768px)': {
                my: 2,
              },
            }}
          >
            {isLoading && data === undefined ? (
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  height: '40px',
                  margin: 'auto',
                }}
              >
                <SpinnerXL />
              </Box>
            ) : (
              data &&
              data.map((certification: ProcedureAvailable) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={`${certification.alias}_available`}
                  >
                    <ProcedureAvailableCard
                      procedure={certification}
                      startProcedureURL={`/certificaciones/${certification.alias}`}
                      textBtn="Iniciar solicitud"
                      callback={(comeFrom?: string) =>
                        handleOpen(certification, comeFrom)
                      }
                      width={width}
                    />
                  </Grid>
                );
              })
            )}
          </Grid>
        </>
      )}
    </>
  );
};
