import { ListItemButton, ListItemIcon } from '@mui/material';
import styled from 'styled-components';
import colors from 'theme/colors';

export const ListItemButtonStyled = styled(ListItemButton)`
  &.MuiButtonBase-root {
    padding: 4px 16px !important;

    &:hover {
      background-color: ${colors.white} !important;
    }
  }

  & > * {
    color: ${colors.tertiaryBase} !important;
  }

  &:hover > * {
    color: ${colors.primaryBase} !important;
  }
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  min-width: 32px !important;
`;
