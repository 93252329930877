import React from 'react';

export const GoogleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <rect width="24" height="24" transform="translate(0.500488)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5405 12.2605C23.5405 11.445 23.4673 10.6609 23.3314 9.9082H12.5005V14.3566H18.6896C18.423 15.7941 17.6128 17.0121 16.3948 17.8275V20.713H20.1114C22.2859 18.7109 23.5405 15.7627 23.5405 12.2605Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5005 23.5005C15.6055 23.5005 18.2086 22.4708 20.1114 20.7144L16.3948 17.829C15.365 18.519 14.0477 18.9267 12.5005 18.9267C9.50522 18.9267 6.97 16.9037 6.06568 14.1855H2.22363V17.1651C4.11591 20.9235 8.005 23.5005 12.5005 23.5005Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.06572 14.1855C5.83572 13.4955 5.70503 12.7584 5.70503 12.0005C5.70503 11.2425 5.83572 10.5055 6.06572 9.81548V6.83594H2.22367C1.44481 8.38844 1.00049 10.1448 1.00049 12.0005C1.00049 13.8562 1.44481 15.6125 2.22367 17.165L6.06572 14.1855Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5005 5.07386C14.1889 5.07386 15.7048 5.65409 16.8966 6.79364L20.195 3.49523C18.2034 1.63955 15.6002 0.5 12.5005 0.5C8.005 0.5 4.11591 3.07705 2.22363 6.83545L6.06568 9.815C6.97 7.09682 9.50522 5.07386 12.5005 5.07386Z"
        fill="#EA4335"
      />
    </svg>
  );
};
