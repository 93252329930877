import { AnySchema } from 'yup';
import { FieldNames } from '../components/common/forms/inviteUser/index.inputs';
import { FieldNames as PasswordFieldNames } from '../components/common/forms/newPassword/index.inputs';

export type InputBase = {
  id: string;
  name: string;
  disabled?: boolean;
  label: string;
  placeholder: string;
  type: 'text' | 'email' | 'password' | 'number';
  explanation?: string;
  synced?: boolean;
  validation?: AnySchema;
  customComponentTop?: React.ReactNode[]; // Located above the input
  customComponentBottom?: React.ReactNode[]; // Located below the input
};

export type BodyApplicant = {
  cuitApplicant?: number;
  nombre?: string;
  apellido?: string;
  email?: string;
  telefono?: string;
  cargo?: string;
  isInGroup?: false;
  cuit?: string;
  cuits?: string[] | undefined;
  client?: string;
  razonSocialApplicant?: string;
};

export type OptionsSimple = {
  value: number | string;
  label: string;
};

export type OptionsDouble = OptionsSimple & {
  subValue: number | string;
};

export type InputSelect = Omit<InputBase, 'type'> & {
  type: 'select';
  options: Array<OptionsSimple | OptionsDouble>;
};

export type InputImage = Omit<InputBase, 'type'> & {
  type: 'loadImage';
  titleDrawer: string;
  fileState: string;
  setFileState: (file: string) => void;
};

export type InputCuit = Omit<InputBase, 'type'> & {
  type: 'cuit';
};

export type InputRadio = {
  id: string;
  type: 'radio';
  name: string;
  validation?: AnySchema;
  options: {
    id: string;
    value: string;
    name: string;
    label: string;
  }[];
  defaultCheckedValueToCompare: string;
  customComponentTop?: React.ReactNode[]; // Located above the input
  customComponentBottom?: React.ReactNode[]; // Located below the input
};

export type ButtonBase = {
  id?: string;
  type: 'button';
  text: string;
  preset: 'normal' | 'danger' | 'daruma';
  onClick?: (args: unknown) => void;
  variant: 'contained' | 'outlined';
  size: 'small' | 'large';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
};

export type Inputs = (
  | InputBase
  | InputSelect
  | InputImage
  | InputRadio
  | ButtonBase
  | InputCuit
)[][];

enum FieldNamesUser {
  fieldName = 'firstName',
  fieldSurname = 'lastName',
  fieldEmail = 'email',
  fieldPhone = 'telefono',
}
export interface IUserData {
  [FieldNamesUser.fieldName]: string;
  [FieldNamesUser.fieldSurname]: string;
  [FieldNamesUser.fieldEmail]: string;
  [FieldNamesUser.fieldPhone]: string;
}

enum FieldNamesHectareas {
  fieldHaTotales = 'hectareasTotales',
  fieldHaAprobadas = 'hectareasAprobadas',
  fieldHaDeclaradas = 'hectareasDeclaradas',
}

export interface IHectareasData {
  [FieldNamesHectareas.fieldHaTotales]: string;
  [FieldNamesHectareas.fieldHaAprobadas]: string;
  [FieldNamesHectareas.fieldHaDeclaradas]: string;
}

export interface PasswordsForm {
  [PasswordFieldNames.fieldPassword]: string;
  [PasswordFieldNames.fieldRepeatPassword]: string;
}

export interface IValues {
  [FieldNames.fieldEmail]: string;
  [FieldNames.fieldRol]: string;
}

export enum FieldNamesRequestAccount {
  fieldNames = 'names',
  fieldLastNames = 'lastNames',
  fieldEmail = 'email',
  fieldPhone = 'phone',
  fieldDomain = 'domain',
}

export interface IRequestAccount {
  [FieldNamesRequestAccount.fieldNames]: string;
  [FieldNamesRequestAccount.fieldLastNames]: string;
  [FieldNamesRequestAccount.fieldEmail]: string;
  [FieldNamesRequestAccount.fieldPhone]: string;
  [FieldNamesRequestAccount.fieldDomain]: string;
}
