import { ViterraAction, InitialStateViterra } from './index.types';

export default function viterraReducer(
  initialValues: InitialStateViterra,
  action: ViterraAction,
) {
  switch (action.type) {
    case 'set-stateProcedure': {
      return { ...initialValues, stateProcedure: action.value };
    }

    case 'set-refused': {
      return { ...initialValues, refused: action.value };
    }

    case 'set-moveForward': {
      return { ...initialValues, moveForward: action.value };
    }

    case 'set-productName': {
      return { ...initialValues, productName: action.value };
    }

    case 'set-entityName': {
      return { ...initialValues, entityName: action.value };
    }

    default:
      return initialValues;
  }
}
