/* eslint-disable no-param-reassign */
import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import api from 'services/api/api';
import { lotesSorter } from 'utils/mapUtils';
import { LoteResponse } from 'models/map';
import { errors } from 'constants/fields';
import { LoteModel, StateType } from './fieldsModel';

export type ScreenField =
  | 'MIS_CAMPOS'
  | 'VITERRA'
  | 'HC_PUMA'
  | 'SANCOR_MULTIRIESGO';
type TabMap = 'CAMPO' | 'LOTES';
export type ValidationResult = {
  error: string;
  isWarning: boolean;
};
const CustomValidation = types.model('CustomValidation').props({
  error: types.string,
  isWarning: types.boolean,
  fieldId: types.number,
});
type CustomValidationType = Instance<typeof CustomValidation>;

export const FieldsStoreModel = types
  .model('FieldsStore')
  .props({
    fields: types.array(StateType),
    lots: types.optional(types.array(LoteModel), []),
    tabMode: types.optional(types.string, 'Campo'),
    changesSaved: types.optional(types.boolean, false),
    splitioTabMode: types.optional(types.boolean, false),
    loadingContentTab: types.optional(types.boolean, false),
    loadingFields: types.optional(types.boolean, false),
    customValidations: types.optional(types.array(CustomValidation), []),
  })
  .views((self) => ({
    isFieldValid: (fieldId: number, screen: ScreenField): ValidationResult => {
      // Para evitar problemas con ciertas validaciones en donde pueden haber warnings,
      // colocar las validaciones que no son warnings primero!
      const field = self.fields.find((f) => f.id === fieldId);
      if (!field) {
        return {
          error: errors.DEFAULT,
          isWarning: false,
        };
      }

      if (screen === 'MIS_CAMPOS') {
        if (!field.localidad && !field.poligono) {
          return {
            error: errors.misCampos.LOCATION_AND_POLYGON_NOT_COMPLETED,
            isWarning: false,
          };
        }
        if (!field.localidad) {
          return {
            error: errors.misCampos.LOCATION_NOT_COMPLETED,
            isWarning: false,
          };
        }
        if (!field.poligono) {
          return {
            error: errors.misCampos.POLYGON_NOT_COMPLETED,
            isWarning: false,
          };
        }
      }

      if (screen === 'VITERRA') {
        if (!field.localidad && !field.poligono) {
          return {
            error: errors.viterra.LOCATION_AND_POLYGON_NOT_COMPLETED,
            isWarning: false,
          };
        }
        if (!field.localidad) {
          return {
            error: errors.viterra.LOCATION_NOT_COMPLETED,
            isWarning: false,
          };
        }
        if (!field.poligono) {
          return {
            error: errors.viterra.POLYGON_NOT_COMPLETED,
            isWarning: false,
          };
        }

        const hasCustomFieldValidation = self.customValidations.find(
          (f) => f.fieldId === fieldId,
        );
        if (hasCustomFieldValidation) {
          return {
            error: hasCustomFieldValidation.error,
            isWarning: hasCustomFieldValidation.isWarning,
          };
        }
      }

      if (screen === 'HC_PUMA') {
        if (!field.localidad) {
          return {
            error: errors.hcPuma.LOCATION_NOT_COMPLETED,
            isWarning: false,
          };
        }
        if (field.lotes?.some((lote) => !lote.hasPoligono)) {
          return {
            error: errors.hcPuma.ACTIVE_LOTS_NOT_COMPLETED,
            isWarning: false,
          };
        }
      }

      if (screen === 'SANCOR_MULTIRIESGO') {
        if (!field.localidad) {
          return {
            error: errors.sancorMultiriesgo.LOCATION_NOT_COMPLETED,
            isWarning: false,
          };
        }
        const hasNotPlanedLots = field.lotes?.some(
          (lote) => !lote.isPlanificado,
        );
        const hasNotPolygonLots = field.lotes?.some(
          (lote) => !lote.hasPoligono,
        );
        if (hasNotPlanedLots) {
          return {
            error: errors.sancorMultiriesgo.ACTIVE_LOTS_NOT_FOUND,
            isWarning: false,
          };
        }
        if (!hasNotPlanedLots && hasNotPolygonLots) {
          return {
            error: errors.sancorMultiriesgo.ACTIVE_LOTS_NOT_COMPLETED,
            isWarning: false,
          };
        }
      }

      return {
        error: '', // No hay errores
        isWarning: false,
      };
    },
    isTabValid: (
      fieldId: number,
      tab: TabMap,
      screen: ScreenField,
    ): ValidationResult => {
      const field = self.fields.find((f) => f.id === fieldId);
      if (!field) {
        return {
          error: errors.DEFAULT,
          isWarning: false,
        };
      }

      if (screen === 'HC_PUMA' && tab === 'CAMPO') {
        if (!field.poligono) {
          return {
            error: errors.hcPuma.POLYGON_NOT_COMPLETED,
            isWarning: false,
          };
        }
      }

      if (screen === 'HC_PUMA' && tab === 'LOTES') {
        if (
          field.lotes?.some((lote) => !lote.hasPoligono && lote.isPlanificado)
        ) {
          return {
            error: errors.hcPuma.ACTIVE_LOTS_NOT_COMPLETED,
            isWarning: false,
          };
        }
      }

      return {
        error: '', // No hay errores
        isWarning: false,
      };
    },
  }))
  .actions((self) => ({
    setFields: (fields: any) => {
      self.fields = fields;
    },
    setLots: (lots: any) => {
      self.lots = lots;
    },
    addLot: (lot: any) => {
      self.lots.push(lot);
    },
    setTabMode: (tabMode: 'Campo' | 'Lotes') => {
      if (self.splitioTabMode) {
        self.tabMode = tabMode;
      }
    },
    setSplitioTabMode: (splitioTabMode: boolean) => {
      self.splitioTabMode = splitioTabMode;
    },
    setLoadingContentTab: () => {
      self.loadingContentTab = !self.loadingContentTab;
    },
    setLoadingFields: (loadingFields: boolean) => {
      self.loadingFields = loadingFields;
    },
    setAddValidation: (validation: CustomValidationType) => {
      self.customValidations.push(validation);
    },
    setClearValidation: () => {
      self.customValidations.length = 0;
    },
    setChangesSaved: (changesSaved: boolean) => {
      self.changesSaved = changesSaved;
    },
  }))
  .actions((self) => ({
    getMyFields: async () => {
      self.setLoadingFields(true);
      const response = await api.me.getMyFields();
      if (!response.kind) {
        self.setFields(response.data);
      }
      self.setLoadingFields(false);
    },
  }))
  .actions((self) => ({
    getMyFieldsByCUIT: async (cuit: string) => {
      self.setLoadingFields(true);
      const response = await api.me.getMyFieldsByCUIT(cuit);
      if (!response.kind) {
        self.setFields(response.data);
        self.setLoadingFields(false);
      }
    },
  }))
  .actions((self) => ({
    getMyFieldsNotCompletedByCUIT: async (cuit: string) => {
      const response = await api.me.getMyFieldsNotCompletedByCUIT(cuit);
      if (!response.kind) {
        self.setFields(response.data);
      }
    },
  }))
  .actions((self) => ({
    getAvailableFieldsForCertification: async (alias: string) => {
      const response =
        await api.traces.getFieldsAvailableForCertification(alias);
      self.setFields(response.data);
      self.setClearValidation();
    },
  }))
  .actions((self) => ({
    updateField: async (fieldId: number, values: Record<string, any>) => {
      // TODO: Investigar si hay una forma mejor de actualizar un nodo del arbol de MobX.
      // Si bien esto funciona, no es lo ideal porque se pierde el tipado de los campos y
      // no es performante.
      const response = await api.me.updateField(fieldId, values);
      if (!response.kind) {
        const newFields = self.fields.map((field) => {
          if (field.id === fieldId) {
            const {
              lotes,
              localidad,
              poligono,
              hectareas,
              nombre,
              razonSocial,
            } = response.data;
            const updatedField = {
              ...field,
              lotes: lotes ?? [],
              localidad,
              poligono,
              hectareas,
              nombre,
              razonSocial,
            };
            return updatedField;
          }
          return field;
        });
        const fieldsStringify = JSON.stringify(newFields);
        const fieldsParsed = JSON.parse(fieldsStringify);
        self.setFields(fieldsParsed);
      }
      return response;
    },
  }))
  .actions((self) => ({
    deleteField: async (fieldId: number) => {
      const response = await api.me.deleteField(fieldId);
      if (response.ok) {
        const newFields = self.fields.filter((field) => field.id !== fieldId);
        self.setFields(newFields);
      }
      return response;
    },
  }))
  .actions((self) => ({
    getMyLots: async (fieldId: number) => {
      const response = (await api.me.getLotsByFieldId(fieldId)) as unknown as {
        data: LoteResponse[];
      };
      const responseParsed = lotesSorter(response.data);
      self.setLots(responseParsed);
      self.setLoadingContentTab();
    },
    getMyLotsByAlias: async (
      alias: string,
      fieldId: number,
      especiesIds: string,
    ) => {
      const response = (await api.me.getLotsHCByFieldId(
        alias,
        fieldId,
        especiesIds,
      )) as unknown as {
        data: LoteResponse[];
      };
      const responseParsed = lotesSorter(response.data);
      self.setLots(responseParsed);
      self.setLoadingContentTab();
    },
    getFieldHC: async (alias: string, fieldId: number, especiesIds: string) => {
      const response = await api.common.getFieldHC(alias, fieldId, especiesIds);
      if (!response.kind) {
        const newFields = self.fields.map((field) => {
          if (field.id === fieldId) {
            const { lotes, localidad, poligono } = response;
            const updatedField = {
              ...field,
              lotes: lotes ?? [],
              localidad,
              poligono,
            };
            return updatedField;
          }
          return field;
        });
        const fieldsStringify = JSON.stringify(newFields);
        const fieldsParsed = JSON.parse(fieldsStringify);
        self.setFields(fieldsParsed);
      }
      return response;
    },
    getFieldById: async (fieldId: number) => {
      const response = await api.common.getField(fieldId);
      if (!response.kind) {
        const newFields = self.fields.map((field) => {
          if (field.id === fieldId) {
            const { lotes, localidad, poligono, nombre, razonSocial } =
              response;
            const updatedField = {
              ...field,
              lotes: lotes ?? [],
              localidad,
              poligono,
              nombre,
              razonSocial,
            };
            return updatedField;
          }
          return field;
        });
        const fieldsStringify = JSON.stringify(newFields);
        const fieldsParsed = JSON.parse(fieldsStringify);
        self.setFields(fieldsParsed);
      }
      return response;
    },
  }))
  .actions((self) => ({
    getFieldValidationViterra: async (fieldId: number, alias: string) => {
      const response = await api.traces.validateField(fieldId, alias);
      let validation = { fieldId, error: '', isWarning: false };
      if (!response.kind) {
        if (!response[fieldId].notZonaProtegida) {
          validation = {
            fieldId,
            error: errors.viterra.FIELD_IN_PROTECTED_AREA,
            isWarning: false,
          };
        }

        if (!response[fieldId].cultivada) {
          validation = {
            fieldId,
            error: errors.viterra.GROW_SOJA_NOT_FOUND,
            isWarning: true,
          };
        }

        if (validation.error) {
          self.setAddValidation(validation);
        }
      }
      return validation;
    },
  }));

type FieldsStoreModelType = Instance<typeof FieldsStoreModel>;
export type FieldsStore = FieldsStoreModelType;
type FieldsStoreSnapshotType = SnapshotOut<typeof FieldsStoreModel>;
export type FieldsStoreSnapshot = FieldsStoreSnapshotType;
export const createFieldsStoreDefaultModel = () =>
  types.optional(FieldsStoreModel, {});
