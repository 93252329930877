/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-await */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import L, { LatLng } from 'leaflet';
import {
  LayerGroup,
  LayersControl,
  MapContainer,
  Polygon,
  TileLayer,
  FeatureGroup,
} from 'react-leaflet';
import { observer } from 'mobx-react';
import { Box, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import MapWrapper from 'components/common/map/mapWrapper';
import { useStores } from 'store/root-store/root-store-context';
import { Strings } from 'constants/strings';
import { MapDrawerProps, Perimeter } from 'utils/types';
import { generateRandomColor, sleep, tabModes } from 'utils/common';
import { uploadKML, normalizeLong } from 'utils/mapUtils';
import PerimetersNotSave from 'components/common/alerts/perimetersNotSave';
import api from 'services/api/api';
import { LotPolygon } from 'models/map';
import MapConsumer from './mapConsumer';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-geosearch/dist/geosearch.css';
import SearchField from './searchControl';
import './index.css';
import colors from 'theme/colors';

const endangeredZoneOptions = { color: 'red' };

const Map: React.FC<MapDrawerProps> = observer(
  ({ openDrawer, closeDrawer, field }) => {
    const { alias } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const {
      fieldsStore,
      mapStore: { refreshPolygons },
      sessionStore: { isExternal },
    } = useStores();
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const [loadPerimeter, setLoadPerimeter] = useState<
      'charge' | 'load' | 'loadLotes' | 'clear' | null
    >(null);
    const [isLoading, setIsLoading] = useState(false);
    const [zoom, setZoom] = useState(4.7);
    const [lots, setLots] = useState<LotPolygon[]>([]);
    const [perimeters, setPerimeters] = useState<Perimeter[]>([]);
    const [idPerimeter, setIdPerimeter] = useState<number | null>(null);
    const [isViterra, setIsViterra] = useState<boolean>();
    const [selectedPerimeter, setSelectedPerimeter] =
      useState<Perimeter | null>(null);
    const [endangeredZones, setEndangeredZones] = useState<
      [number, number][][]
    >([]);
    const [zoomPolygon, setZoomPolygon] = useState<any>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loadingKML, setLoadingKML] = useState(false);
    const isLtTablet = useMediaQuery('(max-width:767px)');
    const isTabletRef = useRef<boolean>(window.innerWidth < 768);

    if (isTabletRef.current !== window.innerWidth < 768 && openDrawer) {
      closeDrawer(false);
    }

    const getEndangeredZones = async () => {
      const response = await api.common.getEndangeredZones();
      const zones = response.data.map((item: any) => item.poligono);
      setEndangeredZones(zones);
    };

    const getInfo = async (aliasUrl: string | undefined) => {
      if (aliasUrl) {
        const res = await api.procedure.getSelectedProcedure(aliasUrl);
        if (res.entidad?.nombre === 'Viterra') {
          setIsViterra(true);
        } else {
          setIsViterra(false);
        }
      }
    };

    const handleUploadKML = async (e: any) => {
      setLoadingKML(true);
      const result = await uploadKML(e);

      if (result.some((item) => item.error)) {
        enqueueSnackbar(Strings.notifications.commonError, {
          variant: 'error',
        });
      } else if (result.length > 0) {
        const lotPerimeters: Perimeter[] = result.map(
          (lot: Perimeter, index: number) => {
            return {
              id: lot.id,
              name: lot.name,
              color: lot.color,
              original: false,
              load: true,
              polygon: lot.polygon,
              drawnOrKMLPolygons: true,
              idPoligono: index,
            };
          },
        );

        setPerimeters((prev: Perimeter[]) => [...prev, ...lotPerimeters]);
        setLoadPerimeter(
          Object.values(lotPerimeters).length > 0 ? 'load' : 'loadLotes',
        );
      }

      setLoadingKML(false);
    };

    const handleSubmit = async () => {
      setIsLoading(true);
      if (field) {
        const modifiedField = {
          id: selectedPerimeter?.id,
          poligono: selectedPerimeter?.polygon?.flat().map((item) => {
            return [item.lat, normalizeLong(item.lng)];
          }),
        };
        if (modifiedField.poligono) {
          modifiedField.poligono.push(modifiedField.poligono[0]);
        }
        const response = await fieldsStore.updateField(field.id, modifiedField);
        await sleep(2500);
        if (!response.kind) {
          enqueueSnackbar(Strings.forms.fieldModification.success, {
            variant: 'success',
          });
        }
      }
      fieldsStore.setChangesSaved(true);
      setIsLoading(false);
    };

    const handleDeletePerimeter = () => {
      setPerimeters(
        perimeters.filter((perimeter) => {
          return perimeter.id !== idPerimeter;
        }),
      );
      const filterPerimeter = lots.find((i) => {
        const perimeter = perimeters.find(
          (p) => p.nombrePoligono === i.nombrePoligono && p.id === idPerimeter,
        );
        return perimeter;
      });

      if (filterPerimeter) {
        filterPerimeter.poligono = [];
        filterPerimeter.color = colors.complementaryGreen;
        filterPerimeter.assigned = false;
        filterPerimeter.drawnOrKMLPolygons = false;
        const newLots = lots.map((l) =>
          l.id === filterPerimeter.id ? filterPerimeter : l,
        );
        setLots(newLots);
      }
    };

    const firstLetterUpperCase = (text: string) => {
      return text
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    const handleOnClose = () => {
      const filter = perimeters.filter(
        (a: any) => a.drawnOrKMLPolygons || a.load,
      );
      if (
        (fieldsStore.tabMode === tabModes.CAMPO ||
          fieldsStore.tabMode === tabModes.LOTES) &&
        filter.length >= 1 &&
        !fieldsStore.changesSaved
      ) {
        setOpenAlert(true);
      } else {
        closeDrawer(false);
        setLoadPerimeter(null);
        setPerimeters([]);
        setZoomPolygon([]);
      }
    };

    /**
     * Remove the selected perimeter from the map (external users in Lotes)
     */
    const handleRemovePolygonFromMap = () => {
      if (selectedPerimeter) {
        if (selectedPerimeter.idOrigin) {
          setPerimeters((prev) => {
            return prev.filter(
              (perimeter) => perimeter.idOrigin !== selectedPerimeter.idOrigin,
            );
          });
        } else {
          setPerimeters((prev) => {
            return prev.filter(
              (perimeter) => perimeter.id !== selectedPerimeter.id,
            );
          });
        }
        setSelectedPerimeter(null);
      }
    };

    useEffect(() => {
      getEndangeredZones();
      getInfo(alias);
    }, []);

    useEffect(() => {
      if (openDrawer) {
        if (field && field.poligono && tabModes.CAMPO === fieldsStore.tabMode) {
          const perimeter = {
            id: field.id,
            idOrigin: field.id,
            color: colors.complementaryGreen,
            original: true,
            name: 'Polígono guardado',
            polygon: field.poligono.map((point) => {
              return new LatLng(point[0], normalizeLong(point[1]));
            }),
            idPoligono: 0,
            hectareas: field.hectareas,
          };
          setPerimeters([perimeter]);
          setSelectedPerimeter(perimeter);
          setLoadPerimeter(perimeters.length > 1 ? 'load' : 'charge');
        } else if (tabModes.LOTES === fieldsStore.tabMode) {
          const lotPerimeters: Perimeter[] = [];
          fieldsStore.lots.forEach((lot, idx) => {
            if (lot.poligono.length > 0) {
              const polygon = lot.poligono.map((point) => {
                return new LatLng(point[0], normalizeLong(point[1]));
              });
              lotPerimeters.push({
                id: lot.id,
                idOrigin: lot.id,
                color: lot.color ?? generateRandomColor(),
                original: true,
                name: lot.nombre || lot.nombrePoligono || `Polígono ${idx + 1}`,
                polygon: [...polygon, polygon[0]],
                idPoligono: idx,
                hectareas: lot.hectareas,
              });
            }
          });
          setPerimeters(lotPerimeters);
          setSelectedPerimeter(null);
          setLoadPerimeter(lotPerimeters.length > 0 ? 'load' : 'loadLotes');
        } else {
          setPerimeters([]);
          setSelectedPerimeter(null);
          setLoadPerimeter('clear');
        }
      }
    }, [openDrawer, fieldsStore.tabMode, refreshPolygons]);

    useEffect(() => {
      const isExternalInLote =
        fieldsStore.tabMode === tabModes.LOTES && isExternal;
      if (idPerimeter !== null && !isExternalInLote) {
        handleDeletePerimeter();
      }
    }, [idPerimeter]);

    return (
      <MapWrapper
        fieldId={field?.id || 0}
        open={openDrawer}
        setZoomPolygon={setZoomPolygon}
        onClose={handleOnClose}
        loadingKML={loadingKML}
        fieldName={field?.nombre || ''}
        fieldNameLocation={
          field?.localidad
            ? firstLetterUpperCase(
                `${String(field?.localidad?.nombre)}, ${String(
                  field?.localidad?.partido.nombre,
                )}, ${String(field?.localidad?.partido.provincia.nombre)}`,
              )
            : ''
        }
        hiddenFileInput={hiddenFileInput}
        uploadKML={handleUploadKML}
        perimeters={perimeters}
        setPerimeters={setPerimeters}
        selectedPerimeter={selectedPerimeter}
        setSelectedPerimeter={setSelectedPerimeter}
        isLoading={isLoading}
        lots={lots}
        setLots={setLots}
        handleSubmit={handleSubmit}
        closeDrawer={closeDrawer}
        handleDeletePerimeter={handleRemovePolygonFromMap}
      >
        {/* warning modal to remove unsaved perimeters */}
        <PerimetersNotSave
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          onClose={() => {
            closeDrawer(false);
            setLoadPerimeter(null);
            setPerimeters([]);
            setZoomPolygon([]);
          }}
        />

        <Box display="flex" flexDirection="row">
          <MapContainer
            center={
              field?.poligono
                ? L.latLngBounds(
                    field?.poligono.map((item) => [item[0], item[1]]),
                  ).getCenter()
                : [-35.859818239408405, -65.8639906786889]
            }
            zoom={zoom}
            minZoom={4}
            style={{
              height: '100vh',
              flex: 1,
            }}
          >
            <SearchField />
            <Box
              sx={{
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 137.1%);',
                position: 'absolute',
                display: {
                  sm: 'block',
                  md: 'none',
                },
                top: 0,
                width: '100%',
                height: '60px',
                zIndex: 688,
              }}
            />
            <LayersControl
              autoZIndex={false}
              collapsed={isLtTablet}
              position="topright"
            >
              <LayersControl.BaseLayer checked={!isLtTablet} name="Cartografía">
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer
                name="Imagen Satelital"
                checked={isLtTablet}
              >
                <TileLayer
                  attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                />
              </LayersControl.BaseLayer>
              {!isViterra && (
                <LayersControl.Overlay checked name="Zonas protegidas">
                  <FeatureGroup pathOptions={endangeredZoneOptions}>
                    <LayerGroup>
                      {endangeredZones.map((endangeredZone, key) => {
                        return (
                          <Polygon
                            pmIgnore
                            pathOptions={endangeredZoneOptions}
                            interactive={false}
                            positions={endangeredZone}
                            key={`polygon-${key + 1 - 1}`}
                          />
                        );
                      })}
                    </LayerGroup>
                  </FeatureGroup>
                </LayersControl.Overlay>
              )}
            </LayersControl>

            {isViterra && (
              <LayerGroup pmIgnore>
                {endangeredZones.map((endangeredZone, key) => {
                  return (
                    <Polygon
                      pmIgnore
                      pathOptions={endangeredZoneOptions}
                      interactive={false}
                      positions={endangeredZone}
                      key={`polygon2-${key + 1 - 1}`}
                    />
                  );
                })}
              </LayerGroup>
            )}

            <MapConsumer
              zoomPolygon={zoomPolygon}
              setZoomPolygon={setZoomPolygon}
              perimeters={perimeters}
              setPerimeters={setPerimeters}
              loadPerimeter={loadPerimeter}
              setIdPerimeter={setIdPerimeter}
              setLoadPerimeter={setLoadPerimeter}
              setSelectedPerimeter={setSelectedPerimeter}
              setZoom={setZoom}
            />
          </MapContainer>
        </Box>
      </MapWrapper>
    );
  },
);

export default Map;
