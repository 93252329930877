import { Box, Paper, Stack } from '@mui/material';
import { H6, H4 } from '../../../common/typography/styles';
import Button from '../../../common/buttons/button';
import colors from '../../../../theme/colors';

type TCommonProcedureModalCard = {
  textButton: string;
  numberList: number;
  title: string;
  descriptionChildren: React.ReactNode;
  actionButton: () => void;
  startIcon?: string | null;
  isMobile: boolean;
};

export const CommonProcedureModalCard = ({
  textButton,
  descriptionChildren,
  actionButton,
  numberList,
  title,
  startIcon = null,
  isMobile,
}: TCommonProcedureModalCard) => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: '12px',
        backgroundColor: colors.tertiaryHigh,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: {
            sm: 'center',
            xs: 'flex-start',
          },
          flexDirection: {
            sm: 'row',
            xs: 'column',
          },
          gap: '17px',
          width: {
            xs: '100%',
          },
        }}
      >
        {!isMobile && <H4 color={colors.complementaryGreen}>{numberList}.</H4>}

        <Stack
          sx={{
            width: {
              md: '281px',
              sm: '436px',
            },
          }}
        >
          <Stack direction="row" marginBottom="3px">
            {isMobile && <H6 style={{ marginRight: '5px' }}>{numberList}.</H6>}
            <H6>{title}</H6>
          </Stack>
          <Box>{descriptionChildren}</Box>
        </Stack>
        <Box
          sx={{
            width: {
              sm: '160px',
              xs: '100%',
            },
          }}
        >
          <Button
            preset="normal"
            size="small"
            variant="outlined"
            text={textButton}
            onClick={actionButton}
            startIcon={startIcon}
          />
        </Box>
      </Box>
    </Paper>
  );
};