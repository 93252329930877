import {FC} from "react";

export const Maletin:FC<{ color: string, width: string, height: string }> = ({ color, width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="work" clipPath="url(#clip0_20533_5408)">
                <path id="Vector" d="M20 6.09375H16V4.09375C16 2.98375 15.11 2.09375 14 2.09375H10C8.89 2.09375 8 2.98375 8 4.09375V6.09375H4C2.89 6.09375 2.01 6.98375 2.01 8.09375L2 19.0938C2 20.2038 2.89 21.0938 4 21.0938H20C21.11 21.0938 22 20.2038 22 19.0938V8.09375C22 6.98375 21.11 6.09375 20 6.09375ZM14 6.09375H10V4.09375H14V6.09375Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_20533_5408">
                    <rect width={width} height={height} fill={color} transform="translate(0 0.09375)"/>
                </clipPath>
            </defs>
        </svg>
    )
}