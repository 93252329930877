import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import apisauce from 'apisauce';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RequestAccountSchema } from 'utils/schema-validations';
import { DEFAULT_API_CONFIG } from 'services/api/api-config';
import { Strings } from 'constants/strings';
import DynamicForm from '../dynamic';
import { inputsForm } from './index.inputs';
import { FieldNamesRequestAccount, IRequestAccount } from 'models/dynamicForms';

const initialValues = {
  [FieldNamesRequestAccount.fieldNames]: '',
  [FieldNamesRequestAccount.fieldLastNames]: '',
  [FieldNamesRequestAccount.fieldEmail]: '',
  [FieldNamesRequestAccount.fieldPhone]: '',
  [FieldNamesRequestAccount.fieldDomain]: '',
};

const RequestAccountForm: React.FC = observer(() => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const sendRequest = apisauce.create({
    baseURL: DEFAULT_API_CONFIG.url,
    timeout: 45000,
  });

  const requestAccountAgro = async (values: IRequestAccount) => {
    const token = (await recaptchaRef?.current?.executeAsync()) || '';
    recaptchaRef?.current?.reset();

    const message = `
      <ul>
      <li><b>Nombres</b>: ${values[FieldNamesRequestAccount.fieldNames]}</li>
      <li><b>Apellidos</b>: ${
        values[FieldNamesRequestAccount.fieldLastNames]
      }</li>
      <li><b>Email</b>: ${values[FieldNamesRequestAccount.fieldEmail]}</li>
      ${
        values[FieldNamesRequestAccount.fieldPhone]
          ? `<li><b>Teléfono</b>: ${
              values[FieldNamesRequestAccount.fieldPhone]
            }</li>`
          : ''
      }
      <li><b>Empresa o dominio</b>: ${
        values[FieldNamesRequestAccount.fieldDomain]
      }</li>
      </ul>
    `;

    const body = {
      recipient:
        import.meta.env.VITE_ENVIRONMENT === 'production'
          ? 'contacto@agrology.com'
          : 'nahuel.ponce@agrology.com',
      subject: 'Solicitud alta de usuario',
      message,
      'g-recaptcha-response': token,
    };

    const response = await sendRequest.post(
      '/agrology/externo/mensaje/enviar',
      body,
    );

    if (response.ok) {
      navigate('/portal/solicitud-cuenta-recibida');
    } else {
      enqueueSnackbar(Strings.notifications.commonError, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  return (
    <>
      <ReCAPTCHA
        sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
        ref={recaptchaRef}
        size="invisible"
        hl="es"
      />
      <DynamicForm
        initialValues={initialValues}
        inputs={inputsForm}
        handleSubmitFormData={(data) =>
          requestAccountAgro(data as IRequestAccount)
        }
        validationSchema={RequestAccountSchema}
      />
    </>
  );
});

export default RequestAccountForm;
