import React from 'react';
import { Inputs } from 'models/dynamicForms';
import { ChevronRight } from '@mui/icons-material';

export enum FieldNames {
  fieldName = 'firstName',
  fieldSurname = 'lastName',
  fieldEmail = 'email',
  fieldPhone = 'telefono',
}

export const inputsForm: Inputs = [
  [
    {
      id: FieldNames.fieldName as string,
      name: FieldNames.fieldName as string,
      disabled: false,
      label: 'Nombre',
      placeholder: 'Ingresá tus nombres',
      type: 'text',
    },
    {
      id: FieldNames.fieldSurname as string,
      name: FieldNames.fieldSurname as string,
      disabled: false,
      label: 'Apellido',
      placeholder: 'Ingresá tus apellidos',
      type: 'text',
    },
    {
      id: FieldNames.fieldEmail as string,
      name: FieldNames.fieldEmail as string,
      disabled: true,
      label: 'Email',
      placeholder: 'Ingresá tu email',
      type: 'email',
    },
    {
      id: FieldNames.fieldPhone as string,
      name: FieldNames.fieldPhone as string,
      disabled: false,
      label: 'Teléfono',
      placeholder: 'Ingresá tu teléfono',
      explanation: 'Agregá el código de tu area (ej: 11)',
      type: 'number',
    },
    {
      type: 'button',
      text: 'Guardar cambios',
      variant: 'contained',
      size: 'large',
      preset: 'normal',
      fullWidth: false,
      endIcon: <ChevronRight />,
    },
  ],
  [],
];
