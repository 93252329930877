/* eslint-disable camelcase */
import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export interface ApiListResponse<T> {
  data: T[];
}

export interface Passwords {
  oldPassword: string;
  newPassword: string;
}

export class PasswordApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async changePassword(body: Passwords): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/passwords/change`,
        body,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async recoverAccount(body: { email: string }): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/passwords/recovery`,
        body,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async newPassowrd(body: { password: string; token: string }): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/passwords/recovery/${body.token}/reset`,
        { password: body.password },
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async createPassword(newPass: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/passwords/create`,
        {
          new_password: newPass,
        },
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
