import React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  color?: string;
}

const EmptyStateIcon: React.FC<IconProps> = ({
  width = '115',
  height = '81',
  color = '#928176',
}) => {
  return (
    <svg
      width="115"
      height="81"
      viewBox="0 0 115 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.591 48.9152C8.28969 48.9152 11.5493 49.9585 14.9994 51.0628C18.5304 52.1914 22.1821 53.361 29.3748 53.361C36.5697 53.361 40.2214 52.1914 43.7523 51.0628C47.2036 49.9585 50.4621 48.9152 57.1608 48.9152C63.8606 48.9152 67.1202 49.9585 70.5726 51.0628C74.1047 52.1914 77.7565 53.361 84.9514 53.361C86.2926 53.361 87.4778 53.309 88.5943 53.2359C88.5943 53.2359 88.5965 53.237 88.5976 53.237C88.6242 53.2381 88.6519 53.2381 88.6796 53.2381C88.7017 53.2381 88.7206 53.227 88.7427 53.2259C93.5298 52.8969 96.4848 51.9732 99.3279 51.0628C102.78 49.9585 106.04 48.9152 112.74 48.9152C113.617 48.9152 114.328 48.2052 114.328 47.3269C114.328 46.4497 113.618 45.7386 112.74 45.7386C105.545 45.7386 101.892 46.9082 98.361 48.0369C95.9875 48.7956 93.6781 49.5177 90.3045 49.9009C90.2458 31.6745 75.4052 16.8625 57.164 16.8625C38.9229 16.8625 24.0831 31.6742 24.0236 49.9009C20.6489 49.5188 18.3383 48.7967 15.9636 48.0369C12.4338 46.9071 8.78205 45.7375 1.58829 45.7375C0.711066 45.7375 0 46.4475 0 47.3258C0 48.203 0.711066 48.9141 1.58829 48.9141L1.591 48.9152Z"
        fill="#928176"
      />
      <path
        d="M112.74 59.5537C112.166 59.5537 111.615 59.5615 111.085 59.5759C110.209 59.6003 109.518 60.329 109.541 61.2074C109.564 62.0691 110.271 62.7513 111.127 62.7513H111.172C111.674 62.7369 112.197 62.7292 112.74 62.7292C113.617 62.7292 114.328 62.0192 114.328 61.1409C114.328 60.2637 113.617 59.5537 112.74 59.5537L112.74 59.5537Z"
        fill="#928176"
      />
      <path
        d="M1.59101 62.7294C8.2897 62.7294 11.547 63.7728 14.9994 64.877C18.5304 66.0079 22.1821 67.1764 29.3748 67.1764C36.5697 67.1764 40.2214 66.0068 43.7524 64.877C47.2036 63.7728 50.4632 62.7294 57.1608 62.7294C63.8606 62.7294 67.1202 63.7728 70.5726 64.877C74.1036 66.0079 77.7565 67.1764 84.9514 67.1764C92.1451 67.1764 95.7969 66.0068 99.3267 64.877C100.64 64.4573 101.881 64.0596 103.313 63.7152C104.166 63.5092 104.69 62.6519 104.485 61.799C104.277 60.9462 103.419 60.4268 102.569 60.6272C101.023 61.0005 99.6689 61.4336 98.3575 61.8533C94.9074 62.9576 91.6478 64.0009 84.9514 64.0009C78.2515 64.0009 74.9919 62.9576 71.5395 61.8533C68.0086 60.7225 64.3557 59.554 57.1608 59.554C49.967 59.554 46.3153 60.7236 42.7855 61.8533C39.3342 62.9576 36.0746 64.0009 29.3759 64.0009C22.6772 64.0009 19.4199 62.9576 15.9675 61.8533C12.4365 60.7236 8.78476 59.554 1.59101 59.554C0.713782 59.554 0.00271606 60.2639 0.00271606 61.1422C0.00271606 62.0195 0.713782 62.7294 1.59101 62.7294Z"
        fill="#928176"
      />
      <path
        d="M112.74 73.3706C105.545 73.3706 101.892 74.5402 98.3609 75.6689C94.9096 76.7731 91.65 77.8165 84.9514 77.8165C78.2515 77.8165 74.9919 76.7731 71.5395 75.6689C68.0074 74.5402 64.3557 73.3706 57.1608 73.3706C49.967 73.3706 46.3153 74.538 42.7855 75.6689C39.3342 76.7731 36.0746 77.8165 29.3759 77.8165C22.6772 77.8165 19.4176 76.7731 15.9675 75.6689C12.4365 74.5402 8.78476 73.3706 1.59101 73.3706C0.713782 73.3706 0.00271606 74.0806 0.00271606 74.9589C0.00271606 75.8361 0.712676 76.5472 1.59101 76.5472C8.2897 76.5472 11.5493 77.5905 14.9994 78.6948C18.5304 79.8234 22.1821 80.993 29.3748 80.993C36.5697 80.993 40.2214 79.8234 43.7524 78.6948C47.2036 77.5905 50.4621 76.5472 57.1608 76.5472C63.8606 76.5472 67.1202 77.5905 70.5726 78.6948C74.1047 79.8234 77.7565 80.993 84.9514 80.993C92.1462 80.993 95.798 79.8256 99.3289 78.6948C102.78 77.5894 106.04 76.5461 112.74 76.5461C113.617 76.5461 114.328 75.8361 114.328 74.9578C114.328 74.0806 113.617 73.3706 112.74 73.3706Z"
        fill="#928176"
      />
      <path
        d="M57.1653 5.04842C58.0425 5.04842 58.7536 4.33846 58.7536 3.46013V1.58829C58.7536 0.711066 58.0436 0 57.1653 0C56.2881 0 55.577 0.70996 55.577 1.58829V3.46013C55.577 4.33846 56.2881 5.04842 57.1653 5.04842Z"
        fill="#928176"
      />
      <path
        d="M76.1485 9.25625C76.3656 9.35704 76.5937 9.40467 76.8164 9.40467C77.4145 9.40467 77.9882 9.06464 78.2573 8.48647L79.0482 6.78853C79.4192 5.99439 79.0747 5.05073 78.2795 4.67969C77.4809 4.30754 76.5384 4.65532 76.1706 5.44834L75.3798 7.14628C75.0088 7.94153 75.3532 8.88519 76.1485 9.25623V9.25625Z"
        fill="#928176"
      />
      <path
        d="M92.8009 21.6498C93.1609 21.6498 93.5231 21.5268 93.8211 21.2776L95.2543 20.0726C95.9255 19.5077 96.013 18.5064 95.4481 17.8352C94.8821 17.164 93.882 17.0765 93.2108 17.6414L91.7776 18.8465C91.1064 19.4113 91.0188 20.4126 91.5837 21.0838C91.8994 21.457 92.3491 21.6498 92.801 21.6498L92.8009 21.6498Z"
        fill="#928176"
      />
      <path
        d="M102.11 39.4959C102.246 39.4959 102.385 39.4792 102.522 39.4416L104.329 38.9565C105.178 38.7283 105.679 37.8588 105.452 37.0115C105.224 36.1631 104.345 35.6625 103.507 35.8884L101.7 36.3736C100.851 36.6017 100.35 37.4712 100.577 38.3185C100.768 39.0285 101.409 39.4959 102.11 39.4959H102.11Z"
        fill="#928176"
      />
      <path
        d="M36.0726 8.4848C36.3406 9.06297 36.9144 9.40299 37.5136 9.40299C37.7373 9.40299 37.9666 9.35537 38.1814 9.25458C38.9767 8.88353 39.3211 7.93988 38.9501 7.14573L38.1593 5.44779C37.7904 4.65364 36.849 4.30699 36.0504 4.67914C35.2552 5.05018 34.9107 5.99384 35.2818 6.78798L36.0726 8.4848Z"
        fill="#928176"
      />
      <path
        d="M20.509 21.2756C20.8069 21.5248 21.1691 21.6478 21.5291 21.6478C21.9821 21.6478 22.4317 21.455 22.7463 21.0818C23.3112 20.4106 23.2237 19.4082 22.5525 18.8445L21.1193 17.6394C20.4481 17.0767 19.4479 17.1631 18.8819 17.8332C18.3171 18.5044 18.4046 19.5068 19.0758 20.0705L20.509 21.2756Z"
        fill="#928176"
      />
      <path
        d="M10.0009 38.9544L11.8085 39.4395C11.9447 39.4771 12.0842 39.4938 12.2205 39.4938C12.9216 39.4938 13.5618 39.0275 13.7523 38.3164C13.9804 37.4702 13.4776 36.5996 12.6292 36.3715L10.8216 35.8864C9.98426 35.6615 9.10482 36.161 8.87666 37.0094C8.65071 37.8568 9.15245 38.7273 10.0008 38.9544H10.0009Z"
        fill="#928176"
      />
    </svg>
  );
};

export default EmptyStateIcon;
