import React from 'react';
import { Stack } from '@mui/material';
import type { LatLngExpression } from 'leaflet';
import Title from './Title';
import { PreviewMap } from './PreviewMap';

export type TitleHeaderProps = {
  id: number;
  poligonos: LatLngExpression[] | null;
  title: string;
  onClose: () => void;
};

export const TitleHeader = ({
  id,
  poligonos,
  title,
  onClose,
}: TitleHeaderProps) => {
  return (
    <Stack height="150px">
      <Title title={title} id={id} onClose={onClose} />
      <PreviewMap poligonos={poligonos} />
    </Stack>
  );
};
