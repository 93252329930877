import { lazy } from 'react';

export const LazyFinSimpleSeleccion = lazy(
  () => import('./SeleccionEmpresa/Seleccion'),
);
export const LazyFinSimpleRevision = lazy(
  () => import('./RevisionInforme/RevisionInforme'),
);
export const LazyFinSimpleSolicitudEnviada = lazy(
  () => import('./SolicitudEnviada/SolicitudEnviada'),
);
export const LazyFinSimpleGeneracionInforme = lazy(
  () => import('./GeneracionInforme/GeneracionInforme'),
);
