/**
 * **parseHas** Transforma una cadena de texto en número flotante de dos decimales con separador de miles
 *
 * **Ejemplo**: parseHas('1234567.8931') -> 1.234.567,89
 */
const parseHas = (has: string, thousands = false) => {
  const hasNumber = parseFloat(has).toFixed(2);
  if (thousands) {
    const hasNumberSplit = hasNumber.split('.');
    const hasNumberSplitLeft = hasNumberSplit[0];
    const hasNumberSplitRight = hasNumberSplit[1];
    const hasNumberSplitLeftFormatted = hasNumberSplitLeft.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      '.',
    );
    return `${hasNumberSplitLeftFormatted},${hasNumberSplitRight}`;
  }
  return hasNumber.replace('.', ',');
};

export default parseHas;
