import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Collapse, InputLabel } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import UploadIcon from '@mui/icons-material/Upload';
import { Caps, H6, P3 } from 'components/common/typography/styles';
import InputRadio from 'components/common/inputs/radio';
import colors from 'theme/colors';
import CampoGif from 'assets/Campo.gif';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MapDraw from 'assets/icons/extraIcons/map-draw.svg?react';
import { MapTabsProps, HiddenInput } from '../mapTabs';
import Button from '../../buttons/button';
import { useStores } from 'store/root-store/root-store-context';
import QuestionIcon from 'components/common/icon/icons/questionIcon';

export interface FieldPanelProps extends MapTabsProps {
  loadingKML: boolean;
}

export const FieldPanel: React.FC<FieldPanelProps> = observer((props) => {
  const {
    hiddenFileInput,
    uploadKML,
    perimeters,
    selectedPerimeter,
    setSelectedPerimeter,
    isLoading,
    handleSubmit,
    loadingKML,
  } = props;
  const {
    fieldsStore: { setChangesSaved },
  } = useStores();
  const [showTutorial, setShowTutorial] = useState(false);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          md: '308px',
        },
      }}
      height="calc(100vh - 266px)"
      position="relative"
    >
      <HiddenInput
        accept=".kml,.kmz"
        id="contained-button-file"
        multiple
        ref={hiddenFileInput}
        type="file"
        onChange={uploadKML}
      />
      <Box
        sx={{
          display: {
            xs: 'none',
            lg: 'block',
          },
        }}
      >
        <Collapse in={showTutorial || perimeters.length === 0}>
          <Box m="16px 12px 0px 16px">
            <img
              src={CampoGif}
              alt="tutorial-lotes-draw"
              style={{
                width: '100%',
              }}
              loading="lazy"
            />
            <P3 color={colors.tertiaryBase} style={{ marginBottom: '16px' }}>
              Dibujá un polígono en el mapa para asignárselo al campo
            </P3>
            {perimeters.length > 0 && (
              <Button
                text="OK, entendido"
                variant="outlined"
                preset="normal"
                size="small"
                onClick={() => setShowTutorial(false)}
              />
            )}
          </Box>
        </Collapse>
        {!showTutorial && perimeters.length !== 0 && (
          <>
            <P3
              color={colors.tertiaryBase}
              style={{ margin: '16px 12px 0px 16px' }}
            >
              Delimitá en el mapa
              <MapDraw
                style={{
                  verticalAlign: 'middle',
                  margin: '0 5px 2px 5px',
                }}
              />
              o importá el polígono de cada lote de tu campo
            </P3>
            <Box m="9px 12px 0 16px" width="calc(100% - 12px - 16px)">
              <Box
                display="flex"
                justifyContent="flex-end"
                gap="3px"
                onClick={() => setShowTutorial(true)}
                sx={{ cursor: 'pointer' }}
              >
                <Caps
                  color={colors.primaryBase}
                  style={{ textDecoration: 'underline' }}
                >
                  MÁS INFO
                </Caps>
                <InfoOutlinedIcon
                  sx={{
                    color: colors.primaryBase,
                    width: 16,
                    height: 16,
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>

      {perimeters.length > 0 && (
        <Collapse in={!showTutorial}>
          <H6
            color={colors.tertiaryBase}
            sx={{
              marginBottom: {
                xs: 1,
                md: 2,
              },
              padding: {
                xs: '0px 16px 0px 16px',
                md: '16px 16px 0 16px',
              },
            }}
          >
            Polígonos marcados
          </H6>
          {perimeters.length === 0 && (
            <Box marginX="20px">
              <P3
                style={{
                  margin: '16px 0px 20px 0px',
                  color: colors.tertiaryBase,
                }}
              >
                Delimitá en el mapa o importá el polígono de tu campo
              </P3>
              <Button
                variant="contained"
                preset="normal"
                fullWidth
                size="small"
                onClick={() => {
                  if (hiddenFileInput && hiddenFileInput.current)
                    hiddenFileInput.current.click();
                }}
                text="Importar KML/KMZ"
                startIcon={<UploadIcon />}
              />
            </Box>
          )}

          <Box
            display="flex"
            flexDirection="column"
            sx={{
              height: {
                xs: 'calc(100vh - 400px)',
                md: 'calc(100vh - 350px)',
              },
              overflowY: 'auto',
            }}
            maxHeight="calc(100vh - 124px)"
            className="item-lotes"
          >
            {perimeters.map((perimeter: any, index: number) => {
              return (
                <Box
                  key={perimeter.name}
                  display="inline-flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginX="13px"
                  marginY="6px"
                >
                  <InputLabel>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                    >
                      <InputRadio
                        checked={selectedPerimeter?.idPoligono === index}
                        defaultChecked={false}
                        onChange={() => {
                          setSelectedPerimeter({
                            ...perimeter,
                            idPoligono: index,
                          });
                        }}
                      />
                      <P3>{perimeter.name}</P3>
                    </Box>
                  </InputLabel>
                  <Box
                    borderRadius="100%"
                    bgcolor={perimeter.color}
                    width="10px"
                    height="10px"
                  />
                </Box>
              );
            })}
          </Box>
        </Collapse>
      )}

      {perimeters.length === 0 && (
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <P3 sx={{ paddingX: '20px' }}>
            Dibujá un polígono en el mapa para asignárselo al campo
            <span style={{ position: 'relative', top: '3px', left: '3px' }}>
              <QuestionIcon />
            </span>
          </P3>
        </Box>
      )}

      <Collapse in={!showTutorial || perimeters.length === 0}>
        <Box
          display="flex"
          flexDirection="column"
          alignSelf="center"
          alignItems="center"
          marginY="16px"
          justifyContent={perimeters.length === 0 ? 'end' : 'center'}
          padding="0 16px"
          gap="10px"
          sx={{
            height: {
              xs: perimeters.length === 0 ? 'calc(100vh - 340px)' : 'auto',
              md: perimeters.length === 0 ? 'calc(100vh - 410px)' : 'auto',
            },
          }}
        >
          <Button
            variant="outlined"
            preset="normal"
            size="small"
            fullWidth
            loading={loadingKML}
            onClick={() => {
              if (hiddenFileInput && hiddenFileInput.current && !loadingKML) {
                hiddenFileInput.current.click();
                setChangesSaved(true);
              }
            }}
            text="Importar polígonos"
          />
          <Button
            variant="contained"
            preset="normal"
            size="small"
            fullWidth
            onClick={handleSubmit}
            text="Guardar cambios"
            endIcon={<ChevronRight />}
            disabled={selectedPerimeter === null || isLoading}
            loading={isLoading}
          />
        </Box>
      </Collapse>
    </Box>
  );
});
