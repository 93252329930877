import React, { useState } from 'react';
import { Box } from '@mui/material';
import { MapContainer, Polygon, TileLayer } from 'react-leaflet';
import { latLngBounds } from 'leaflet';
import type { LatLngExpression, LatLngBoundsExpression } from 'leaflet';

export const PreviewMap = ({
  poligonos,
}: {
  poligonos: LatLngExpression[] | null;
}) => {
  const [zoom, setZoom] = useState<number>(5);

  const generateCenter = (): LatLngExpression => {
    if (poligonos) {
      const center = latLngBounds(poligonos).getCenter();

      return [center.lat, center.lng];
    }

    return [-35.859818239408405, -65.8639906786889];
  };

  return (
    <>
      <Box
        width="100%"
        height="100%"
        maxHeight="150px"
        position="absolute"
        zIndex="-1"
      >
        <MapContainer
          ref={(map) => {
            if (map && poligonos) {
              setZoom(
                map?.fitBounds(poligonos as LatLngBoundsExpression).getZoom(),
              );
            }
          }}
          center={generateCenter()}
          zoom={zoom}
          dragging={false}
          touchZoom={false}
          doubleClickZoom={false}
          zoomControl={false}
          keyboard={false}
          attributionControl={false}
          style={{ width: '100%', height: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          {poligonos && <Polygon positions={poligonos} />}
        </MapContainer>
      </Box>
    </>
  );
};
