import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Box, Stack } from '@mui/material';
import SelectInput from 'components/common/inputs/select';
import { Province, Locality, Department } from 'store/fields-store/fieldsModel';
import { useStores } from 'store/root-store/root-store-context';
import api from 'services/api/api';
import { FieldSchema } from 'utils/schema-validations';
import { useSnackbar } from 'notistack';
import { Strings } from 'constants/strings';
import { Field } from 'models/fields';
import colors from 'theme/colors';
import { lastAlborSync } from 'utils/procedure';
import Button from '../buttons/button';
import AlborSyncIcon from '../icon/icons/alborSyncIcon';
import { P4 } from '../typography/styles';

enum FieldNames {
  fieldLocality = 'localidad',
  fieldDepartament = 'partido',
  fieldProvince = 'provincia',
}

type FieldDataFormProps = {
  onSubmit: () => void;
  field: Field;
};

const FieldDataForm = (props: FieldDataFormProps) => {
  const { field, onSubmit } = props;
  const { fieldsStore } = useStores();
  const onSubmitAndCLose = onSubmit;
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [localities, setLocalities] = useState<Locality[]>([]);
  const [departaments, setDepartaments] = useState<Department[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const getData = async () => {
    const provincesResponse = await api.common.getProvinces();
    setProvinces(provincesResponse.data);
    if (field && field.localidad) {
      const departamentsResponse = await api.common.getProvinceDepartaments(
        field.localidad.partido.provincia.id,
      );
      setDepartaments(departamentsResponse.data);
      const localitiesResponse = await api.common.getDepartmentLocalities(
        field.localidad.partido.id,
      );
      setLocalities(localitiesResponse.data);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UNIDENTIFIED = 'NO IDENTIFICADO';

  const isIdentified = (value: string | undefined | null): boolean => {
    return (value ?? '').toUpperCase() !== UNIDENTIFIED;
  };

  const ProvinciaIsIdentified = isIdentified(
    field.localidad?.partido.provincia.nombre,
  );
  const PartidoIsIdentified = isIdentified(field.localidad?.partido.nombre);
  const LocalidadIsIdentified = isIdentified(field.localidad?.nombre);

  const initialValues = {
    [FieldNames.fieldLocality]: field ? field.localidad?.id : '',
    [FieldNames.fieldDepartament]: field ? field?.localidad?.partido?.id : '',
    [FieldNames.fieldProvince]: field
      ? field.localidad?.partido?.provincia.id
      : '',
  };

  const processValues = (
    values: Record<string, string | number | undefined | object>,
  ) => {
    const processedValues = { ...values };
    if (processedValues[FieldNames.fieldLocality]) {
      processedValues[FieldNames.fieldLocality] = localities.find(
        // eslint-disable-next-line eqeqeq
        (locality) => locality.id == processedValues[FieldNames.fieldLocality],
      );
    }
    return processedValues;
  };

  const updateField = async (
    values: Record<string, string | number | undefined>,
  ) => {
    setIsLoading(true);
    const processedValues = processValues(values);

    const response = await fieldsStore.updateField(field.id, processedValues);
    if (!response.kind) {
      enqueueSnackbar(Strings.forms.fieldModification.success, {
        variant: 'success',
      });
    }
    setIsLoading(false);
    onSubmitAndCLose();
  };

  // Comento esto porque vi que no se usaba!
  // const preUpdateField = async (
  //   values: Record<string, string | number | undefined | object>,
  // ) => {
  //   const processedValues = processValues(values);
  //   await fieldsStore.updateField(state.id, processedValues);

  //   onSubmitAndCLose();
  // };

  const changedProvince = async (provinceId: number) => {
    const departamentsResponse = await api.common.getProvinceDepartaments(
      provinceId,
    );
    setDepartaments(departamentsResponse.data);
    setLocalities([]);
    setIsDisabled(true);
  };

  const changedDepartment = async (departmentId: number) => {
    const localitiesResponse = await api.common.getDepartmentLocalities(
      departmentId,
    );
    setLocalities(localitiesResponse.data);
  };

  const isValueChange = (
    values: Record<string, string | number | undefined>,
  ) => {
    if (localities.length === 0 || values.localidad === undefined) {
      return true;
    }
    return false;
  };

  const firstLetterUpperCase = (text: string) => {
    return text
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateField}
      validationSchema={FieldSchema}
      validateOnBlur={false}
      validateOnChange={isSubmit}
    >
      {({ values, isValid, isSubmitting }) => {
        if (isSubmitting && !isSubmit) {
          setIsSubmit(true);
        }

        return (
          <>
            <Box marginBottom="22px">
              <SelectInput
                id="province-field"
                label="Provincia"
                disabled={!!field.tsLocalidad && ProvinciaIsIdentified}
                defaultValue="dsa"
                synced={!!field.tsLocalidad}
                options={provinces.map((item) => ({
                  value: item.id,
                  label: firstLetterUpperCase(item.nombre),
                }))}
                width="100%"
                handleChange={
                  (id) => changedProvince(parseInt(id as string, 10)) // TODO: FIX TYPE!!!
                }
                name={FieldNames.fieldProvince}
                placeholder="Seleccioná una provincia"
              />
            </Box>
            <Box marginBottom="22px">
              <SelectInput
                id="departament-field"
                label="Partido/Departamento"
                disabled={
                  (values.provincia === undefined || values.provincia === 25) &&
                  !PartidoIsIdentified
                }
                synced={!!field.tsLocalidad}
                options={departaments.map((item) => ({
                  value: item.id,
                  label: firstLetterUpperCase(item.nombre),
                }))}
                width="100%"
                handleChange={
                  (id) => changedDepartment(parseInt(id as string, 10)) // TODO: FIX TYPE!!!
                }
                name={FieldNames.fieldDepartament}
                placeholder="Seleccioná un partido/departamento"
              />
            </Box>
            <Box marginBottom="22px">
              <SelectInput
                id="locality-field"
                label="Localidad"
                disabled={
                  (values.partido === undefined ||
                    localities.length === 0 ||
                    values.partido === 529) &&
                  !LocalidadIsIdentified
                }
                synced={!!field.tsLocalidad}
                options={localities.map((item) => ({
                  value: item.id,
                  label: firstLetterUpperCase(item.nombre),
                }))}
                width="100%"
                handleChange={() => {
                  setIsDisabled(false);
                }}
                name={FieldNames.fieldLocality}
                placeholder="Seleccioná una localidad"
              />
            </Box>
            <Button
              variant="contained"
              preset="normal"
              size="large"
              onClick={() => updateField(values)}
              text="Confirmar cambio"
              disabled={
                !isValid ||
                isSubmitting ||
                isLoading ||
                isDisabled ||
                isValueChange(values)
              }
              loading={isLoading}
            />
            {field.tsLocalidad && (
              <Stack
                direction="row"
                gap="4px"
                alignItems="flex-start"
                mt="18px"
              >
                <Box>
                  <AlborSyncIcon width="33" height="33" />
                </Box>
                <P4 color={colors.tertiaryMedium}>
                  Los datos que presenten este icono están sincronizados con la
                  plataforma de origen Albor. Última actualización:&nbsp;
                  {lastAlborSync(field.tsLocalidad)}
                </P4>
              </Stack>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default FieldDataForm;
