import React from 'react';
import { styled } from '@mui/material';
import { Tab, tabClasses } from '@mui/base/Tab';
import colors from 'theme/colors';
import typography from 'theme/typography';

interface CustomTabProps {
  border: string;
  children: React.ReactNode;
  disabled?: boolean;
  value?: string | number;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | number,
  ) => void;
}

interface CustomMapTabProps extends CustomTabProps {
  icon: React.ReactNode;
}

const baseStyles = (customProps: CustomTabProps) => `
  cursor: pointer;
  padding: 10px 14.5px;
  border-radius: 8px 8px 0 0;
  background-color: ${colors.tertiaryHigh};
  border: 2px solid ${colors.tertiaryIntermediate};
  border-bottom: 0px;
  width: 143px;
  @media (max-width: 767px) {
    border-radius: ${customProps.border};
    border: none;
    background-color: ${colors.tertiaryIntermediate};
  }
  .text-tab {
    font-family: ${typography.fontFamilies.gothamRounded};
    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -2%;
    color: ${colors.tertiaryMedium};
  }
`;

const selectedTabStyles = `
  box-shadow: 0px -8px 12px rgba(146, 129, 118, 0.2);
  background-color: ${colors.white};
  border-color: ${colors.white};

  .text-tab {
    background: ${`-webkit-linear-gradient(left, ${colors.primaryBase}, ${colors.complementaryGreen})`};
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
`;

export const CustomTab = styled((props: CustomTabProps) => (
  <Tab {...props}>
    <h6 className="text-tab">{props.children}</h6>
  </Tab>
))`
  ${(props: CustomTabProps) => baseStyles(props)}

  &.${tabClasses.selected} {
    ${selectedTabStyles}
  }
`;

export const CustomMapTab = styled((props: CustomMapTabProps) => (
  <Tab {...props}>
    {props.icon}
    <h6 className="text-tab">{props.children}</h6>
  </Tab>
))`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${(props: CustomMapTabProps) => baseStyles(props)}

  &.${tabClasses.selected} {
    ${selectedTabStyles}
  }
`;

const baseStylesOutline = (customProps: CustomTabProps) => `
  cursor: pointer;
  padding: 10px 0px;
  background-color: white;
  border-bottom: 0px;
  border-bottom: 2px solid trasnparent;
  width: 100%;
  @media (max-width: 767px) {
    border: none;
    background-color: white;
  }
  .text-tab {
    font-family: ${typography.fontFamilies.gothamRounded};
    font-weight: 500;
    font-size: 18px;
    line-height: 16.8px;
    letter-spacing: -2%;
    color: ${colors.tertiaryMedium};
  }
`;

const selectedTabStylesOutline = `
  background-color: ${colors.white};
  border-bottom: 2.2px solid;
  border-color: ${colors.primaryBase};

  .text-tab {
    background: ${`-webkit-linear-gradient(left, ${colors.primaryBase}, ${colors.complementaryGreen})`};
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
`;

export const CustomTabOutline = styled((props: CustomTabProps) => (
  <Tab {...props}>
    <h6 className="text-tab">{props.children}</h6>
  </Tab>
))`
  ${(props: CustomTabProps) => baseStylesOutline(props)}

  &.${tabClasses.selected} {
    ${selectedTabStylesOutline}
  }
`;

export const CustomMapTabOutline = styled((props: CustomMapTabProps) => (
  <Tab {...props}>
    {props.icon}
    <h6 className="text-tab">{props.children}</h6>
  </Tab>
))`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${(props: CustomMapTabProps) => baseStylesOutline(props)}

  &.${tabClasses.selected} {
    ${selectedTabStylesOutline}
  }
`;
