import React from 'react';
import { Box } from '@mui/material';
import Alert from 'components/common/alerts';

interface ArgenpymesAlertProps {
  showModal: boolean;
  handleClose: () => void;
}

const ArgenpymesAlert: React.FC<ArgenpymesAlertProps> = ({
  showModal,
  handleClose,
}) => {
  return (
    <Alert
      showModal={showModal}
      handleClose={handleClose}
      title="Beneficio Combo Bienvenida Argenpymes"
      message={
        <Box>
          En breve te contactaremos por correo para darte los detalles de como
          activar tu beneficio.
        </Box>
      }
    />
  );
};

export default ArgenpymesAlert;
