import React from 'react';
import { Box } from '@mui/material';
import { P3, P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';

interface IDarumaTable {
  empresa: string | null;
  representante: string | null;
}
const DarumaTable: React.FC<IDarumaTable> = (props) => {
  const { empresa, representante } = props;
  if (empresa) {
    return (
      <Box
        display={{
          xs: 'none',
          md: 'flex',
        }}
        width="100%"
        mb="17px"
        padding="0 24px"
        boxSizing="border-box"
      >
        <Box
          mr={{
            xs: '20px',
            md: '56px',
          }}
        >
          <P4 color={colors.tertiaryMedium}>Empresa solicitante</P4>
          <P3 color={colors.tertiaryLow}>{empresa}</P3>
        </Box>
        {representante && (
          <Box
            mr={{
              xs: '20px',
              md: '56px',
            }}
          >
            <P4 color={colors.tertiaryMedium}>Representante</P4>
            <P3 color={colors.tertiaryLow}> {representante}</P3>
          </Box>
        )}
      </Box>
    );
  }
  return null;
};

export default DarumaTable;
