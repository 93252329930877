import { styled } from '@mui/material/styles';
import theme from 'theme';

export type WeightVariants = 'bold' | 'medium' | 'regular' | 'light';

export const weightValues: Record<WeightVariants, string> = {
  bold: '400',
  medium: '350',
  regular: '325',
  light: '300',
} as const;

export const H1 = styled('h1')`
  font-family: ${theme.fontFamilies.gothamRounded};
  font-style: normal;
  font-weight: ${weightValues.medium};
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -2%;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

export const H2 = styled('h2')`
  font-family: ${theme.fontFamilies.gothamRounded};
  font-style: normal;
  font-weight: ${weightValues.medium};
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -2%;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

export const H3 = styled('h3')`
  font-family: ${theme.fontFamilies.gothamRounded};
  font-style: normal;
  font-weight: ${weightValues.medium};
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -2%;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
`;

export const H4 = styled('h4')<{ weight?: WeightVariants }>`
  font-family: ${theme.fontFamilies.gothamRounded};
  font-style: normal;
  font-weight: ${({ weight }) =>
    weight ? weightValues[weight] : weightValues.medium};
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -2%;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 20px;
    letter-spacing: -0.4px;
  }
`;

export const H4Ellipsis = styled(H4)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const H5 = styled('h5')<{ weight?: WeightVariants }>`
  font-family: ${theme.fontFamilies.gothamRounded};
  font-style: normal;
  font-weight: ${({ weight }) =>
    weight ? weightValues[weight] : weightValues.medium};
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin: 0px;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.36px;
  }
`;

export const H5WithouElipsis = styled('h5')`
  font-family: ${theme.fontFamilies.gothamRounded};
  font-style: normal;
  font-weight: ${weightValues.medium};
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin: 0px;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
`;

export const H6 = styled('h6')<{ weight?: WeightVariants; fontSize?: string }>`
  font-family: ${theme.fontFamilies.gothamRounded};
  font-style: normal;
  font-weight: ${({ weight }) =>
    weight ? weightValues[weight] : weightValues.medium};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: 14px;
  letter-spacing: -2%;
  margin: 0;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
`;
export const H6Ellipsis = styled('h6')`
  font-family: ${theme.fontFamilies.gothamRounded};
  font-style: normal;
  font-weight: ${weightValues.medium};
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -2%;
  margin: 0;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const P1 = styled('p')`
  font-family: ${theme.fontFamilies.workSans};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31.2px;
  margin: 0;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
`;

export const P1Intermediate = styled('p')`
  font-family: ${theme.fontFamilies.workSans};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31.2px;
  margin: 0;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
`;

export const P2 = styled('p')`
  font-family: ${theme.fontFamilies.workSans};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  line-height: 26px;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
`;
export const P3 = styled('p')`
  font-family: ${theme.fontFamilies.workSans};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
  line-height: 20px;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
`;

export const P2Ellipsis = styled(P2)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const P3Ellipsis = styled(P3)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const P4 = styled('p')<{ fontSize?: string }>`
  font-family: ${theme.fontFamilies.workSans};
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.fontSize || '13px'};
  margin: 0;
  line-height: 16.25px;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
`;

export const P4Ellipsis = styled(P4)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Caps = styled('p')`
  font-family: ${theme.fontFamilies.gothamRounded};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  line-height: 125%;
  letter-spacing: 0;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
  text-transform: uppercase;
`;

export const CapsMedium = styled('p')`
  font-family: ${theme.fontFamilies.gothamRounded};
  font-style: normal;
  font-weight: ${weightValues.medium};
  font-size: 12px;
  margin: 0;
  line-height: 125%;
  letter-spacing: 0;
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
  text-transform: uppercase;
`;
