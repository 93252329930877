import { AgreeAction, InitialStateAgree } from './index.types';

export default function agreeReducer(
  initialValues: InitialStateAgree,
  action: any, // TODO FIX THIS with AgreeAction
) {
  switch (action.type) {
    case 'set-cuits': {
      return { ...initialValues, cuits: action.value };
    }

    case 'set-stateProcedure': {
      return { ...initialValues, stateProcedure: action.value };
    }

    case 'set-refused': {
      return { ...initialValues, refused: action.value };
    }

    case 'set-moveForward': {
      return { ...initialValues, moveForward: action.value };
    }

    case 'set-products': {
      return { ...initialValues, products: action.value };
    }

    case 'set-productName': {
      return { ...initialValues, productName: action.value };
    }

    case 'set-cuitSelected': {
      return { ...initialValues, cuitSelected: action.value };
    }

    case 'set-routeState': {
      return { ...initialValues, routeState: action.value };
    }

    case 'set-estadoSolicitud': {
      return { ...initialValues, estadoSolicitud: action.value };
    }

    default:
      return initialValues;
  }
}
