import { forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { H5 } from '../typography/styles';
import Button from '../buttons/button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import colors from '../../../theme/colors';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useMediaQuery } from '@mui/material';

interface ReportCompleteProps extends CustomContentProps {
  url: string;
}

const OnesOilSnackbar = forwardRef<HTMLDivElement, ReportCompleteProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const isTbalet = useMediaQuery('(max-width: 768px)');

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref}>
        <Card
          variant="outlined"
          sx={{ width: '100%' }}
          style={{ backgroundColor: '#fff' }}
        >
          <CardActions
            sx={{
              padding: '8px 8px 8px 16px',
              justifyContent: 'space-between',
            }}
          >
            <CheckCircleIcon sx={{ color: colors.complementaryGreen }} />
            <H5 style={{ marginRight: '8px' }}>{props.message}</H5>
            <div
              style={{
                marginLeft: 'auto',
                display: 'flex',
              }}
            >
              {isTbalet ? (
                <IconButton
                  aria-label="fingerprint"
                  onClick={() => {
                    window.open(props.url);
                    closeSnackbar();
                  }}
                >
                  <OpenInNewIcon sx={{ color: colors.complementaryGreen }} />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  text="Conocélo"
                  preset="normal"
                  size="small"
                  onClick={() => {
                    window.open(props.url);
                    closeSnackbar();
                  }}
                  startIcon={<OpenInNewIcon sx={{ color: '#fff' }} />}
                />
              )}
              <IconButton
                size="small"
                sx={{
                  padding: '8px 8px',
                  transform: 'rotate(0deg)',
                  color: '#000',
                  transition: 'all .2s',
                }}
                onClick={handleDismiss}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  },
);

OnesOilSnackbar.displayName = 'OnesOilSnackbar';

export default OnesOilSnackbar;
