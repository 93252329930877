import React from 'react';
import { Card, CardContent } from '@mui/material';

export interface IStepProcedureContainer {
  children: React.ReactNode;
}

const StepProcedureContainer: React.FC<IStepProcedureContainer> = ({
  children,
}) => {
  return (
    <Card
      sx={{
        width: '100%',
        border: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
      }}
      elevation={0}
    >
      <CardContent sx={{ padding: '24px' }}>{children}</CardContent>
    </Card>
  );
};

export default StepProcedureContainer;
