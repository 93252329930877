import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { H3, H5, P2, P3 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import { Link } from 'react-router-dom';
import { DialogContainer, DialogHeader, DialogBody } from './index.styled';

interface ToSModalProps {
  open: boolean;
  onClose: () => void;
}

const TitleH5: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '0 0 16px 0',
      }}
    >
      <FactCheckIcon sx={{ color: colors.tertiaryBase }} />
      <H5
        style={{
          color: colors.tertiaryBase,
          marginLeft: '5px',
        }}
      >
        {title}
      </H5>
    </div>
  );
};

const ParagraphP3: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <P3
      style={{
        color: colors.tertiaryMedium,
        margin: '0 0 26px 0',
      }}
    >
      {children}
    </P3>
  );
};

export const ToSModal: React.FC<ToSModalProps> = ({ open, onClose }) => {
  return (
    <DialogContainer open={open} onClose={onClose} scroll="body">
      <DialogHeader>
        <IconButton
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>

        <H3
          style={{
            color: colors.complementaryGreen,
            margin: '24px 0',
          }}
        >
          Términos y condiciones
        </H3>
      </DialogHeader>
      <DialogBody>
        <P2
          style={{
            color: colors.tertiaryMedium,
            margin: '23px 0 16px 0',
          }}
        >
          Al crear una cuenta, confirmo que he leído y aceptado los siguientes
          términos y condiciones:
        </P2>
        <ParagraphP3>
          La presente es una propuesta de prestación de servicios de AGROLOGY
          DATA S.R.L. (en adelante “<u>PROVEEDOR</u>”) exclusiva e
          intransferible a cada cliente (en adelante, el “CLIENTE”). En caso de
          ser aceptada la presente PROPUESTA por parte del CLIENTE, regirán
          entre el <u>CLIENTE</u> y el PROVEEDOR los términos y condiciones
          generales y particulares que se reproducen a continuación (el “
          <u>CONTRATO</u>”).
        </ParagraphP3>
        <TitleH5 title="Objeto" />
        <ParagraphP3>
          El PROVEEDOR pone a disposición del CLIENTE, y a los clientes
          pertenecientes a la cadena de valor del agro, un conjunto de sistemas
          y funcionalidades accesibles vía Internet (la “<u>PLATAFORMA</u>”)
          mediante los cuales accederán a ciertos productos y servicios
          orientados inicialmente y sin carácter taxativo a: (i) trazabilidad de
          granos, (ii) seguros y reaseguros en el país y en el exterior con
          compañías de seguros autorizadas por la Superintendencia de Seguros de
          la Nación, (iii) financiamiento (fintech), (iv) beneficios
          comerciales, (v) huella de carbono y eficiencias ambientales, (vi)
          simplificación burocrática, reducción de costos y eliminación de
          intermediarios en la cadena de comercialización a fin de generar
          ahorros para los productores y demás integrantes de la cadena, y los
          que razonablemente se deriven a medida que se desarrolle la PLATAFORMA
          (los “<u>PRODUCTOS Y SERVICIOS</u>”).
        </ParagraphP3>
        <ParagraphP3>
          En esta primera etapa, los PRODUCTOS Y SERVICIOS se encontrarán
          limitados a los clientes registrados en AlborCampo, AlborGo! y
          FinnegansAgro que acepten la presente PROPUESTA.
        </ParagraphP3>
        <ParagraphP3>
          La aceptación de la presente PROPUESTA por parte del CLIENTE implicará
          el otorgamiento expreso por parte de éste último, de la autorización
          necesaria para que el el PROVEEDOR pueda acceder a sus respectivas
          bases de datos e información personal procesadas a través de aquellas
          plataformas (los “<u>SISTEMAS DE GESTIÓN</u>”). Los CLIENTES no podrán
          acceder a los PRODUCTOS Y SERVICIOS ofrecidos bajo la PLATAFORMA a
          menos que hayan cedido al PROVEEDOR la información personal procesada
          en los SISTEMAS DE GESTIÓN, lo que se considerará como perfeccionado
          con la aceptación del CLIENTE a la siguiente PROPUESTA. El PROVEEDOR
          se obliga a comunicar de inmediato a AlborCampo, AlborGo! y a
          FinnegansAgro los nombres de sus respectivos clientes que hubieran
          aceptado la presente PROPUESTA y en consecuencia hubieren otorgado su
          expreso consentimiento en los términos mencionados.
        </ParagraphP3>
        <ParagraphP3>
          La autorización de los clientes registrados en AlborCampo, AlborGo! y
          FinnegansAgro estará limitada al acceso a sus datos únicamente por
          parte del PROVEEDOR, en cumplimiento de la Política de Privacidad del
          PROVEEDOR. El PROVEEDOR estará obligado a resguardar la
          confidencialidad de estos datos respecto de cualquier tercero
          (incluyendo AlborCampo, AlborGo!, en el caso de clientes de
          FinnegansAgro, y FinnegansAgro, en el caso de clientes de AlborCampo
          y/o AlborGo!.
        </ParagraphP3>
        <ParagraphP3>
          El presente CONTRATO se limita al ofrecimiento y prestación de los
          PRODUCTOS Y SERVICIOS al CLIENTE vía Internet, y no comprende ningún
          tipo de instalación, implementación, capacitación, consultoría o
          cualquier otro tipo de soporte a los equipos del CLIENTE, con la
          salvedad de aquellos que acuerden expresamente en algún contrato
          complementario al presente.
        </ParagraphP3>
        <ParagraphP3>
          El PROVEEDOR podrá realizar mejoras, innovaciones, modificaciones y/o
          actualizaciones a los PRODUCTOS Y SERVICIOS, lo cual se notificará al
          CLIENTE a través de la PLATAFORMA, de propiedad del PROVEEDOR.
        </ParagraphP3>
        <TitleH5 title="Cuentas del cliente" />
        <ParagraphP3>
          Para utilizar cualquiera de los PRODUCTOS Y SERVICIOS ofrecidos a
          través de la PLATAFORMA, el CLIENTE deberá registrarse para obtener
          una cuenta en la PLATAFORMA. Durante el registro, así como para el
          ofrecimiento de PRODUCTOS Y SERVICIOS, el PROVEEDOR tendrá acceso,
          recopilará, tratará y procesará ciertos datos personales del CLIENTE
          (incluyendo los datos personales a los que el PROVEEDOR accederá
          mediante la autorización otorgada por el CLIENTE al aceptar la
          siguiente PROPUESTA), que serán tratados en los términos y condiciones
          previstos en la&nbsp;
          <Link
            replace
            target="_blank"
            to="/assets/agrology_política_de_privacidad_v1.0_02.03.2022.pdf"
            style={{
              color: colors.primaryBase,
              textDecoration: 'none',
            }}
          >
            Política de Privacidad
          </Link>
          . El CLIENTE podrá acceder a la Política de Privacidad del PROVEEDOR,
          que rige el tratamiento de los datos personales, a través de la
          PLATAFORMA. El CLIENTE es totalmente responsable de la
          confidencialidad de su contraseña y del uso de su cuenta, incluido el
          uso no autorizado de su cuenta por terceros.
        </ParagraphP3>
        <TitleH5 title="Almacenamiento de información" />
        <ParagraphP3>
          La información que el CLIENTE almacenará en la PLATAFORMA será de su
          exclusiva propiedad, y será almacenada en una nube (servicios cloud)
          contratada por el PROVEEDOR a la firma Amazon (AWS, líder mundial en
          el rubro).
        </ParagraphP3>
        <ParagraphP3>
          Tratándose de un prestador de reconocido prestigio internacional y con
          amplia y acreditada capacidad de cumplir con las obligaciones que
          asume bajo su contrato de almacenamiento, el CLIENTE exime de
          responsabilidad al PROVEEDOR por cualquier daño o perjuicio que
          pudiera sufrir como consecuencia del incumplimiento de Amazon y/o AWS
          a las obligaciones asumidas y/o por cualquier interrupción de los
          servicios y/o pérdida de información que no sea atribuible a una
          acción u omisión del PROVEEDOR.
        </ParagraphP3>
        <ParagraphP3>
          El CLIENTE asume la directa operación de su propia información (ya sea
          en la PLATAFORMA y/o en los SISTEMAS DE GESTIÓN) y por lo tanto, exime
          de responsabilidad al PROVEEDOR por acciones u omisiones atribuibles
          al CLIENTE, o por cualquier hecho de terceros, caso fortuito o fuerza
          mayor.
        </ParagraphP3>
        <TitleH5 title="Servicios de terceros" />
        <ParagraphP3>
          Los PRODUCTOS Y SERVICIOS puestos a disposición por parte del
          PROVEEDOR incluyen ciertos productos y servicios que podrán ser
          provistos por terceros, incluyendopero no limitando a exportadores,
          compañías de seguro, entidades financieras, etcétera (los “
          <u>PRODUCTOS Y SERVICIOS DE TERCEROS</u>”). El uso y acceso por parte
          del CLIENTE a estos PRODUCTOS Y SERVICIOS DE TERCEROS podrá requerir
          la expresa autorización para que el referido tercero pueda acceder a
          los datos del CLIENTE registrados y/o procesados en la PLATAFORMA y/o
          en los SISTEMAS DE GESTIÓN. El PROVEEDOR no compartirá información
          perteneciente al CLIENTE con terceros, excepto que ello fuera
          autorizado por el CLIENTE.
        </ParagraphP3>
        <ParagraphP3>
          En caso de que el CLIENTE tenga acceso a PRODUCTOS Y SERVICIOS DE
          TERCEROS, el PROVEEDOR se obliga a exigir a esos terceros el
          cumplimiento y mantenimiento de políticas, obligaciones y prácticas de
          privacidad que cumplan con estándares legalmente exigibles para la
          protección y resguardo de la información del CLIENTE. Sin embargo, el
          PROVEEDOR no será responsable por los posibles incumplimientos de esos
          terceros a las obligaciones mencionadas.
        </ParagraphP3>
        <TitleH5 title="Garantías y Responsabilidad" />
        <ParagraphP3>
          Ni el PROVEEDOR ni la PLATAFORMA realizan ni contienen ninguna
          garantía, explícita ni implícita, en cuanto a los PRODUCTOS Y
          SERVICIOS ofrecidos, ni su aptitud ni idoneidad para un propósito, fin
          o uso determinado. El CLIENTE irrevocablemente libera y exime de
          responsabilidad al PROVEEDOR y a sus funcionarios, directores,
          gerentes empleados y demás representantes de cualquier responsabilidad
          por cualquier contenido, información o uso de la PLATAFORMA y/o de los
          PRODUCTOS Y SERVICIOS.
        </ParagraphP3>
        <TitleH5 title="Rescisión" />
        <ParagraphP3>
          En caso de que el CLIENTE ingrese a la PLATAFORMA, de propiedad del
          PROVEEDOR, en esta primera etapa, limitada a los clientes de
          AlborCampo, AlborGo! y FinnegansAgro, y deje de ser cliente de éstas,
          automáticamente dejará de tener acceso a los PRODUCTOS Y SERVICIOS
          disponibles a través de la PLATAFORMA.
        </ParagraphP3>
        <ParagraphP3>
          En caso de que el CLIENTE decida rescindir su vínculo comercial con el
          PROVEEDOR y dejar de ser CLIENTE, deberá notificarlo a
          contacto@agrology.com. En ese caso, (i) el CLIENTE dejará de tener
          acceso a los PRODUCTOS Y SERVICIOS disponibles en la PLATAFORMA y (ii)
          el PROVEEDOR dejará de tener acceso a los datos del CLIENTE
          almacenados en los SISTEMAS DE GESTIÓN.
        </ParagraphP3>
        <TitleH5 title="Ley y Jurisdicción" />
        <ParagraphP3>
          Los presentes Términos y Condiciones y el presente CONTRATO se regirán
          e interpretarán con arreglo a la legislación vigente en la República
          Argentina, debiendo cualquier divergencia resolverse ante los
          Tribunales Ordinarios en lo Comercial de la Ciudad de Buenos Aires.
        </ParagraphP3>
      </DialogBody>
    </DialogContainer>
  );
};
