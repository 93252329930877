import { Strings } from 'constants/strings';
import { useStores } from 'store/root-store/root-store-context';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api/api';

enum FieldNames {
  fieldName = 'nombre',
  fieldSurname = 'apellido',
}

export const useValidateTokenErp = (token?: string, origin?: string) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { sessionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    [FieldNames.fieldName]: '',
    [FieldNames.fieldSurname]: '',
  };
  const isAlbor = origin === Strings.domainErp.albor;

  const registerNewUser = async (
    token: string,
    nombre: string,
    apellido: string,
  ) => {
    const { sessionToken } = await api.invite.registerNewUserFromErp(
      token,
      nombre,
      apellido,
    );
    if (sessionToken) {
      sessionStore.setToken(sessionToken);
      api.api.setToken(sessionToken);
      enqueueSnackbar(Strings.forms.login.success, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      navigate('/', { replace: true });
    }
  };

  const registerFromErp = async (values: Record<string, any>) => {
    const nombre = values[FieldNames.fieldName];
    const apellido = values[FieldNames.fieldSurname];

    if (token) return registerNewUser(token, nombre, apellido);
    return;
  };

  return {
    navigate,
    openModal,
    setOpenModal,
    initialValues,
    registerFromErp,
    isAlbor,
  };
};