import { ApiResponse } from 'apisauce';
import { DarumaAccionista } from 'models/finance';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export interface ApiDarumaResponse<T> {
  data: T[];
}

export class DarumaApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async altaDarumaInit(
    idSolicitante: string,
    countryTaxes: string,
    nif: string,
    fromArgenpymes: boolean,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        '/tramites/financiamiento/alta_daruma/iniciar/',
        {
          cuit_solicitante: { id: idSolicitante },
          codigo_pais_tributado: countryTaxes,
          nif,
          fromArgenpymes,
        },
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async sendFoundsDaruma(
    procedure: string,
    product: string,
    code: string,
    body: any,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/${procedure}/${product}/${code}/enviar_solicitud/`,
        body,
      );

      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async sendRequestArgenpymes(alias: string, body: any): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/financiamiento/${alias}/enviar_solicitud/`,
        body,
      );

      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getDarumaState(id: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/tramites/financiamiento/${id}/`,
      );

      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async sendTermsCondDaruma(
    procedure: string,
    product: string,
    code: string,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/${procedure}/${product}/${code}/confirmar_representante/`,
      );

      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async sendAccionista(code: string, body: DarumaAccionista): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/daruma/accionista/${code}/`,
        body,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async editAccionista(
    code: string,
    id: number,
    body: Partial<DarumaAccionista>,
  ): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.patch(
        `/daruma/accionista/${code}/${id}`,
        body,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async deleteAccionista(code: string, id: number): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.delete(
        `/daruma/accionista/${code}/${id}`,
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
