import TextInput from 'components/common/inputs/text';
import { Formik } from 'formik';
import Button from 'components/common/buttons/button';
import { Box } from '@mui/material';
import { H6, P4 } from '../../typography/styles';
import { CheckIcon } from '../../icon/icons/check';
import colors from '../../../../theme/colors';
import { FC, useState } from 'react';
import api from 'services/api/api';
import { useStores } from 'store/root-store/root-store-context';
import { useSnackbar } from 'notistack';
import { EspacioDeTrabajoSchema } from 'utils/schema-validations';

interface IFormValues {
  closeModalEspacioTrabajo: () => void;
  openModalAddNewCompany: () => void;
}
const condiciones = [
  {
    icon: (
      <CheckIcon color={colors.tertiaryMedium} width="14px" height="10px" />
    ),
    description: 'El nombre de la cuenta debe ser único',
  },
  {
    icon: (
      <CheckIcon color={colors.tertiaryMedium} width="14px" height="10px" />
    ),
    description: 'No debe estar registrado',
  },
  {
    icon: (
      <CheckIcon color={colors.tertiaryMedium} width="14px" height="10px" />
    ),
    description: 'No podrá ser modificado',
  },
  {
    icon: (
      <CheckIcon color={colors.tertiaryMedium} width="14px" height="10px" />
    ),
    description: 'No debe incluir espacios ni caracteres especiales',
  },
];
export const WorkSpaceForm: FC<IFormValues> = ({
  closeModalEspacioTrabajo,
  openModalAddNewCompany,
}) => {
  const { sessionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    dominio: '',
  };

  const submit = async (values: any) => {
    setLoading(true);
    const res = await api.me.patchEspacioDeTrabajo(values.dominio);

    if (!res.ok)
      return (
        enqueueSnackbar(
          'Ya existe cuenta con este espacio de trabajo. Intentá con uno distinto.',
          {
            variant: 'error',
          },
        ),
        setLoading(false)
      );

    return (
      await sessionStore.getMeUser(),
      closeModalEspacioTrabajo(),
      openModalAddNewCompany(),
      setLoading(false)
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={EspacioDeTrabajoSchema}
    >
      {({ values, handleSubmit, errors }) => {
        return (
          <Box sx={{ maxWidth: '100%' }}>
            <Box sx={{ marginBottom: '16px' }}>
              <TextInput
                disabled={false}
                id="espacio-trabajo"
                name="dominio"
                width="0px"
                height="48px"
                label="Nombre de cuenta"
                placeholder="Ingresá un nombre"
                type="text"
              />
            </Box>
            <Box sx={{ marginTop: '24px', marginBottom: '32px' }}>
              <H6 style={{ marginBottom: '8px' }}>
                Tene en cuenta lo siguiente:
              </H6>
              {condiciones.map((e) => (
                <Box
                  key={e.description}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  {e.icon}
                  <P4
                    style={{ color: colors.tertiaryMedium, fontWeight: '500' }}
                  >
                    {e.description}
                  </P4>
                </Box>
              ))}
            </Box>
            <Button
              variant="contained"
              preset="normal"
              size="large"
              onClick={handleSubmit}
              text="Crear cuenta"
              disabled={loading || !values.dominio || 'dominio' in errors}
              loading={loading}
            />
          </Box>
        );
      }}
    </Formik>
  );
};
