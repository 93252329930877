import styled from 'styled-components';

export const BannerContainer = styled.div`
  border-radius: 8px;

  .bannerlBlue {
    border-radius: 8px 8px 0 0;
    padding: 40px 24px;
    @media (max-width: 767px) {
      padding: 40px 16px;
    }
  }

  .bannerWhite {
    background-color: #ffffff;
    justify-content: space-between;
    display: flex;
    padding: 16px;
    border-radius: 0 0 8px 8px;
    gap: 8px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  .ContainerDescription {
    display: flex;
    width: 100%;
    gap: 8px;
    align-items: center;
    @media (min-width: 767) and (max-width: 1024) {
      display: flex;
    }
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
    }
  }
  .bannerImg {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 8px;
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: 15px;
      grid-column-gap: 15px;
      max-height: 280px;
    }
  }
  .logoBox {
    background-color: #ffffff;
    border-radius: 8px;
    /* margin: 0 5px; */
    padding: 10px 8px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .carousel-logo {
    max-width: 100%;
    height: max-content;
    align-self: center;
  }

  .carousel-logo-Argenpymes {
    max-width: 102px;
  }
`;
