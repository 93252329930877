/* eslint-disable consistent-return */
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import { useFinSimpleContext } from 'contexts/FinSimple/useFinSimpleContext';
import { LazyFinSimpleSeleccion } from 'screens/financiamiento/financiamientoSimple/lazyFinanciamiento';
import { Box } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';

export const RoutesWithoutProcedure = () => {
  const {
    state: { routeState },
  } = useFinSimpleContext();
  if (routeState === 'seleccion')
    return (
      <ProcedureStepSuspense>
        <LazyFinSimpleSeleccion />
      </ProcedureStepSuspense>
    );

  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
};
