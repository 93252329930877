/* eslint-disable no-param-reassign */
import { types, Instance, SnapshotOut } from 'mobx-state-tree';
import { Campana, ProcedureStatus } from 'models/procedure';

const AliasProductModel = types.model('AliasProduct').props({
  alias: types.string,
  nombre: types.string,
});

const CampanaModel = types.model('Campana').props({
  certificacionDesde: types.string,
  certificacionHasta: types.string,
  descripcion: types.string,
  fin: types.string,
  id: types.number,
  inicio: types.string,
  nombre: types.string,
});

type ProcedureInfo = {
  alias: string;
  productName: string;
  markdownTemplate: string;
  images: string[];
  headerImg?: string;
  status: ProcedureStatus;
};

export const ProcedureHeaderStoreModel = types
  .model('ProcedureHeaderStore')
  .props({
    aliasProduct: types.maybeNull(AliasProductModel),
    path: types.string,
    pathName: types.string,
    campana: types.maybeNull(CampanaModel),
    alias: types.maybeNull(types.string),
    productName: types.maybeNull(types.string),
    markdownTemplate: types.maybeNull(types.string),
    title: types.string,
    headerImg: types.optional(types.string, ''),
    images: types.optional(types.array(types.string), []),
    status: types.maybeNull(
      types.union(
        types.literal('ongoing'),
        types.literal('finished'),
        types.literal('rejected'),
      ),
    ),
  })
  .views((self) => ({
    get getPath() {
      return self.path;
    },
    get getPathName() {
      return self.pathName;
    },
    get getTitle() {
      return self.title;
    },
  }))
  .actions((self) => ({
    setProcedureInfo: (info: ProcedureInfo) => {
      const {
        alias,
        productName,
        markdownTemplate,
        images,
        status,
        headerImg,
      } = info;
      self.alias = alias;
      self.productName = productName;
      self.markdownTemplate = markdownTemplate;
      self.headerImg = headerImg ?? '';
      self.images.replace(images);
      self.status = status;
    },

    setProcedure: (path: string, pathName: string, title: string) => {
      self.path = path;
      self.pathName = pathName;
      self.title = title;
    },

    setAliasProduct: (aliasProduct: { alias: string; nombre: string }) => {
      self.aliasProduct = aliasProduct;
    },

    /**
     * Para pantalla se selección de campos en Huella de Carbono
     */
    setCampana: (campana: Campana) => {
      self.campana = campana;
    },
    resetHeaderProcedure: () => {
      self.path = '';
      self.pathName = '';
      self.title = '';
      self.aliasProduct = {
        nombre: '',
        alias: '',
      };
      self.campana = {
        certificacionDesde: '',
        certificacionHasta: '',
        descripcion: '',
        fin: '',
        id: 0,
        inicio: '',
        nombre: '',
      };
      self.alias = '';
      self.productName = '';
      self.markdownTemplate = '';
      self.images.clear();
      self.status = 'ongoing';
    },
  }));

type ProcedureHeaderStoreModelType = Instance<typeof ProcedureHeaderStoreModel>;
export type ProcedureHeaderStore = ProcedureHeaderStoreModelType;
type ProcedureHeaderStoreSnapshotType = SnapshotOut<
  typeof ProcedureHeaderStoreModel
>;
export type ProcedureHeaderStoreSnapshot = ProcedureHeaderStoreSnapshotType;
export const createProcedureHeaderStoreDefaultModel = () =>
  types.optional(ProcedureHeaderStoreModel, {
    path: '',
    pathName: '',
    headerImg: '',
    title: '',
    status: 'ongoing',
    images: [],
  });
