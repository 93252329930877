import { format, isToday, isYesterday } from 'date-fns';

export const formatCultivos = (cultivos: { id: number; nombre: string }[]) => {
  const cultivosList = cultivos.map(
    (cultivo) =>
      cultivo.nombre.charAt(0) + cultivo.nombre.slice(1).toLowerCase(),
  );

  if (cultivosList.length === 1) {
    return cultivosList[0];
  }

  if (cultivosList.length === 2) {
    return `${cultivosList[0]} y ${cultivosList[1]}`;
  }

  const lastCultivo = cultivosList[cultivosList.length - 1];
  const firstCultivos = cultivosList.slice(0, -1);
  return `${firstCultivos.join(', ')} y ${lastCultivo}`;
};

export const lastAlborSync = (dateString: string): string => {
  const date = new Date(dateString);

  let formattedDate = '';

  if (isToday(date)) {
    formattedDate = `Hoy ${format(date, 'HH:mm')}`;
  } else if (isYesterday(date)) {
    formattedDate = `Ayer ${format(date, 'HH:mm')}`;
  } else {
    formattedDate = format(date, 'dd/MM/yyyy HH:mm');
  }

  return formattedDate;
};
