import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, SwipeableDrawer, styled, useMediaQuery } from '@mui/material';
import { Tabs as BaseTabs } from '@mui/base/Tabs';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import LotesSelection from 'components/common/map/panels/lotesSelection';
import LotesSelectionPuma from 'components/common/map/panels/lotesSelectionPuma';
import { tabModes } from 'utils/common';
import { useStores } from 'store/root-store/root-store-context';
import { LotPolygon } from 'models/map';
import PerimetersNotSave from 'components/common/alerts/perimetersNotSave';
import { FieldPanel } from './panels/FieldPanel';
import Tabs from './tabs';
import LotesExternals from './panels/lotesExternals';
import { Global } from '@emotion/react';
import GrassRoundedIcon from '@mui/icons-material/GrassRounded';
import { H4, P4 } from '../typography/styles';
import colors from 'theme/colors';
import TabsOutline from './tabsOutline';

interface TabPanelProps {
  children: React.ReactNode;
  value: number | string;
}

interface FieldPanelProps {
  hiddenFileInput: React.RefObject<HTMLInputElement>;
  uploadKML: (e: any) => void;
  perimeters: any;
  selectedPerimeter: any;
  setSelectedPerimeter: (e: any) => void;
}

export interface MapTabsProps extends FieldPanelProps {
  isLoading: boolean;
  handleSubmit: () => void;
  closeDrawer: (state: boolean) => void;
}
interface MapPanelsProps extends MapTabsProps {
  lots: LotPolygon[];
  setLots: (lots: any) => void;
  loadingKML: boolean;
  setZoomPolygon: (a: any) => void;
  fieldId: number;
  fieldNameLocation?: string;
  fieldName?: string;
  setPerimeters: (a: any) => void;
  handleDeletePerimeter: () => void;
}
export const HiddenInput = styled('input')({
  display: 'none',
});

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value } = props;

  return (
    <BaseTabPanel
      value={value}
      style={{
        display: 'flex',
        flex: 0.4,
        padding: '0',
        flexDirection: 'column',
      }}
    >
      {children}
    </BaseTabPanel>
  );
};

const MapTabs = (props: MapPanelsProps) => {
  const {
    fieldId,
    hiddenFileInput,
    uploadKML,
    fieldNameLocation,
    perimeters,
    selectedPerimeter,
    setSelectedPerimeter,
    isLoading,
    handleSubmit,
    closeDrawer,
    lots,
    setLots,
    fieldName,
    loadingKML,
    setZoomPolygon,
    setPerimeters,
    handleDeletePerimeter,
  } = props;
  const { alias } = useParams();
  const {
    fieldsStore,
    sessionStore: { isExternal },
  } = useStores();
  const { splitioTabMode, changesSaved, setChangesSaved } = fieldsStore;

  const [acceptToChangeTab, setAcceptToChangeTab] = useState<'Campo' | 'Lotes'>(
    fieldsStore.tabMode as 'Campo' | 'Lotes',
  );
  const [openAlert, setOpenAlert] = useState(false);

  // width <= 767;
  const maxWidth = useMediaQuery('(max-width:767px)');

  // width >= 768
  const minWidth = useMediaQuery('(min-width:768px)');

  const filter = perimeters.filter((a: any) => a.drawnOrKMLPolygons || a.load);

  const handleOnChange = (e: any) => {
    const hasChanges =
      (fieldsStore.tabMode === tabModes.CAMPO ||
        fieldsStore.tabMode === tabModes.LOTES) &&
      filter.length >= 1;
    if (hasChanges && !changesSaved) {
      setOpenAlert(true);
    } else {
      fieldsStore.setTabMode(e.target.outerText);
      setChangesSaved(false);
    }
    setAcceptToChangeTab(e.target.outerText);
  };

  const renderTabLotes = () => {
    if (splitioTabMode && !isExternal) {
      if (alias === 'huella_carbono') {
        return (
          <LotesSelectionPuma
            setZoomPolygon={setZoomPolygon}
            loadingKML={loadingKML}
            hiddenFileInput={hiddenFileInput}
            perimeters={perimeters}
            uploadKML={uploadKML}
            closeDrawer={closeDrawer}
            lots={lots}
            setLots={setLots}
          />
        );
      }
      return (
        <LotesSelection
          fieldId={fieldId}
          setZoomPolygon={setZoomPolygon}
          loadingKML={loadingKML}
          hiddenFileInput={hiddenFileInput}
          perimeters={perimeters}
          uploadKML={uploadKML}
          closeDrawer={closeDrawer}
          lots={lots}
          setLots={setLots}
        />
      );
    }

    if (splitioTabMode && isExternal) {
      return (
        <LotesExternals
          fieldId={fieldId}
          setZoomPolygon={setZoomPolygon}
          loadingKML={loadingKML}
          hiddenFileInput={hiddenFileInput}
          perimeters={perimeters}
          setPerimeters={setPerimeters}
          uploadKML={uploadKML}
          selectedPerimeter={selectedPerimeter}
          setSelectedPerimeter={setSelectedPerimeter}
          handleDeletePerimeter={handleDeletePerimeter}
        />
      );
    }

    return null;
  };

  const [open, setOpen] = React.useState(false);
  const drawerBleeding = 170;
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      position={maxWidth ? 'unset' : 'relative'}
    >
      <BaseTabs
        aria-label="Tipo de polígonos"
        value={fieldsStore.tabMode}
        onChange={handleOnChange}
      >
        <PerimetersNotSave
          setOpenAlert={setOpenAlert}
          openAlert={openAlert}
          acceptToChangeTab={acceptToChangeTab}
        />
        <Box
          position="absolute"
          top={maxWidth ? '120px' : '-39px'}
          left="0"
          right="0"
        >
          {minWidth && <Tabs alias={alias} fieldId={fieldId} />}
        </Box>
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              overflow: 'visible',
            },
          }}
        />

        {minWidth ? (
          <>
            <TabPanel value={tabModes.CAMPO}>
              <FieldPanel
                loadingKML={loadingKML}
                hiddenFileInput={hiddenFileInput}
                uploadKML={uploadKML}
                perimeters={[...perimeters]}
                selectedPerimeter={selectedPerimeter}
                setSelectedPerimeter={setSelectedPerimeter}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
                closeDrawer={closeDrawer}
              />
            </TabPanel>
            <TabPanel value={tabModes.LOTES}>{renderTabLotes()}</TabPanel>
          </>
        ) : (
          <SwipeableDrawer
            container={undefined}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Box
              sx={{
                bgcolor: 'white',
                position: 'absolute',
                height: '100%',
                top: -drawerBleeding,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                visibility: 'visible',
                right: 0,
                left: 0,
              }}
            >
              <Box
                width="100%"
                pt="10px"
                pb="10px"
                display="flex"
                justifyContent="center"
              >
                <Box
                  width="25px"
                  height="4px"
                  borderRadius="20px"
                  sx={{
                    backgroundColor: '#D9D9D9',
                  }}
                />
              </Box>
              <Box
                display="flex"
                gap="7px"
                my="10px"
                px="16px"
                alignItems="center"
              >
                <GrassRoundedIcon
                  sx={{
                    color: colors.tertiaryBase,
                    width: '40px',
                    height: '40px',
                  }}
                />
                <Box>
                  <H4 sx={{ color: colors.tertiaryBase }}>{fieldName}</H4>
                  {fieldNameLocation !== '' ? (
                    <P4 sx={{ pt: '2px', color: colors.tertiaryMedium }}>
                      {fieldNameLocation}
                    </P4>
                  ) : null}
                </Box>
              </Box>
              <Box
                sx={{
                  padding: '2.5px 0px',
                  boxShadow: '0px 2px 4px 0px rgba(146, 129, 118, 0.20);',
                }}
              >
                <TabsOutline alias={alias} fieldId={fieldId} />
              </Box>
            </Box>
            <Box
              sx={{
                px: 2,
                height: '100%',
                overflow: 'auto',
              }}
            />
            <TabPanel value={tabModes.CAMPO}>
              <FieldPanel
                loadingKML={loadingKML}
                hiddenFileInput={hiddenFileInput}
                uploadKML={uploadKML}
                perimeters={[...perimeters]}
                selectedPerimeter={selectedPerimeter}
                setSelectedPerimeter={setSelectedPerimeter}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
                closeDrawer={closeDrawer}
              />
            </TabPanel>
            <TabPanel value={tabModes.LOTES}>{renderTabLotes()}</TabPanel>
          </SwipeableDrawer>
        )}
      </BaseTabs>
    </Box>
  );
};

export default observer(MapTabs);
