/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import colors from 'theme/colors';
import { Box } from '@mui/material';
import { CapsMedium, P3 } from 'components/common/typography/styles';

export const EmpresaSolicitante: FC<{
  cuit: string;
  razonSocial: string;
  widthBox?: string;
}> = ({ cuit, razonSocial, widthBox = '50%' }) => {
  return (
    <Box width={widthBox}>
      <CapsMedium color={colors.tertiaryMedium} style={{ marginBottom: '8px' }}>
        Empresa Solicitante
      </CapsMedium>
      <Box>
        <P3 color={colors.tertiaryBase}>CUIT {cuit}</P3>
        <P3 color={colors.tertiaryBase}>{razonSocial}</P3>
      </Box>
    </Box>
  );
};
