import React from 'react';
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import { Box } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { useViterraContext } from '../useViterraContext';
import { LazyNewCertificationScreen } from '../lazyRoutes';

export const RoutesWithoutProcedure = () => {
  const { state } = useViterraContext();

  if (state.stateProcedure === 'seleccion_empresa')
    return (
      <ProcedureStepSuspense>
        <LazyNewCertificationScreen />
      </ProcedureStepSuspense>
    );
  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
};
