import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { P4 } from 'components/common/typography/styles';
import styled from 'styled-components';
import colors from 'theme/colors';

export const ListItemWithoutPolygon = styled(ListItem)`
  & > .MuiButtonBase-root {
    &:hover {
      background-color: ${colors.white} !important;
    }
  }
`;

export const ListItemStyled = styled(ListItemWithoutPolygon)`
  color: ${colors.tertiaryBase};

  &:hover {
    color: ${colors.primaryBase};
    background-color: ${colors.white} !important;
  }
`;

export const ListHoverItemLot = styled(ListItemButton)`
  &.MuiButtonBase-root {
    &:hover {
      background-color: ${colors.white} !important;
    }
  }

  &:hover * {
    color: ${colors.primaryBase} !important;
    stroke: ${colors.primaryBase};
  }
`;

export const ItemP4 = styled(P4)`
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
