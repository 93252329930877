import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import LoginForm from 'components/common/forms/login';
import { H1 } from 'components/common/typography/styles';
import AuthAlert from 'components/common/alerts/authAlert';
import { useStores } from 'store/root-store/root-store-context';
import { AuthContainer } from '../index.styled';

const Login: React.FC = observer(() => {
  const navigate = useNavigate();
  const { sessionStore } = useStores();

  useEffect(() => {
    if (sessionStore.token !== null) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContainer>
      <Helmet>
        <title>Agrology | Inicio de Sesión</title>
      </Helmet>
      <AuthAlert btnPrimText="Aceptar" />
      <Box className="wrapper-auth">
        <H1
          className="auth-title"
          sx={{
            fontSize: '32px',
            fontWeight: '350',
          }}
        >
          Ingresá a tu cuenta de Agrology
        </H1>
        <LoginForm />
      </Box>
    </AuthContainer>
  );
});

export default Login;
