import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class ChatApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getChatAiToken(): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/chat_ai/generate-token/`,
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
