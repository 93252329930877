/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton, Box } from '@mui/material';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { H6 } from 'components/common/typography/styles';
import AgrologyIcon from 'components/common/icon/icons/agrologyIcon';
import ProfileDropdown from 'components/common/dropdowns/profile';
import colors from 'theme/colors';
import api from 'services/api/api';
import { observer } from 'mobx-react';
import { rootStore } from 'store/root-store/root-store';
import { VerticalDivider, Wrapperlogo } from './styles';

interface HeaderProps {
  userData: any;
  openMenu: boolean;
  setOpenMenu: (openMenu: boolean) => void;
}

declare const window: Window &
  typeof globalThis & {
    ReactNativeWebView?: any;
  };

const Header: React.FC<HeaderProps> = observer((props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, openMenu, setOpenMenu } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const profileColor = () => {
    if (location.pathname === '/perfil') {
      return colors.secondaryBase;
    }
    return colors.white;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('signout');
    }
    await api.auth.logout();
    localStorage.removeItem('insumos-token');
    rootStore.resetStore();
    navigate('/portal/iniciar-sesion', {
      state: { from: { ...location, action: 'logout' } },
    });
  };

  return (
    <AppBar
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex',
        backgroundColor: colors.tertiaryLow,
        height: 48,
        position: 'relative',
        paddingLeft: '16px',
        paddingRight: '16px',
      }}
    >
      <Box display="flex" flexDirection="row" alignSelf="center" gap="10px">
        <IconButton
          onClick={() => setOpenMenu(!openMenu)}
          sx={{
            color: colors.tertiaryHigh,
            '&:hover': { color: colors.secondaryBase },
          }}
        >
          {openMenu ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
      </Box>

      <Wrapperlogo>
        <Button onClick={() => navigate('/')} disableRipple>
          <AgrologyIcon />
        </Button>
      </Wrapperlogo>

      <Box
        display="flex"
        flexDirection="row"
        alignSelf="center"
        justifyContent="center"
        height="48px"
      >
        <Box alignSelf="center">
          <VerticalDivider />
        </Box>
        <Box
          display={{
            xs: 'none',
            tablet: 'flex',
          }}
          alignItems="center"
        >
          <PersonIcon
            sx={{
              color: 'inherit',
              marginRight: '5px',
            }}
            fontSize="small"
          />
          <Button
            id="dropdown-button"
            onClick={handleClick}
            endIcon={
              <ArrowDropDownIcon
                sx={{ width: 24, height: 24, color: profileColor() }}
              />
            }
            sx={{
              paddingLeft: '0px',
              paddingRight: '0px',
            }}
          >
            <H6 color={profileColor()}>{userData?.firstName}</H6>
          </Button>
        </Box>
        <Box
          display={{
            xs: 'flex',
            tablet: 'none',
          }}
        >
          <IconButton
            id="dropdown-button"
            onClick={handleClick}
            sx={{
              'MuiButtonBase-root': {
                borderRadius: 0,
              },
            }}
          >
            <PersonIcon sx={{ width: 24, height: 24, color: profileColor() }} />
          </IconButton>
        </Box>

        <ProfileDropdown
          anchorEl={anchorEl}
          open={open}
          setAnchorEl={setAnchorEl}
          handleLogout={handleLogout}
        />
      </Box>
    </AppBar>
  );
});

export default Header;
