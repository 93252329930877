/* eslint-disable consistent-return */
import { ProcedureStepSuspense } from 'components/sections/suspense/ProcedureStepSuspense';
import { useSancorContext } from 'contexts/Sancor/useSancorContext';
import {
  LazyInsuranceReportReviewScreen,
  LazyInsuranceSelectionScreen,
} from '../lazySancor';
import { Box } from '@mui/material';
import { SpinnerXL } from 'components/common/icon/icons/spinner';

export const RoutesWithoutProcedure = () => {
  const {
    state: { routeState },
  } = useSancorContext();

  if (routeState === 'seleccion')
    return (
      <ProcedureStepSuspense>
        <LazyInsuranceSelectionScreen />
      </ProcedureStepSuspense>
    );

  if (routeState === 'revision_informe')
    return (
      <ProcedureStepSuspense>
        <LazyInsuranceReportReviewScreen />
      </ProcedureStepSuspense>
    );

  return (
    <Box
      height="calc(100vh - 48px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerXL />
    </Box>
  );
};
