import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)`
  user-select: none;
  text-transform: none !important;
  // Loading
  position: relative;
  .btn-loading {
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 10px);
  }

  &:has(.btn-loading) {
    .MuiButton-endIcon,
    .MuiButton-startIcon {
      color: transparent !important;
    }
  }
`;
