import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Box, Button as MUIButton } from '@mui/material';
import { H4, H6, P4 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import WorkIcon from '@mui/icons-material/Work';
import LedesmaLogo from 'assets/Logos/Allaria Ledesma@3x.png';
import AllariaPlusLogo from 'assets/Logos/allariaPlusLogo.png';
import { BannerContainer } from './index.styled';
import ArgenpymesLogo from 'assets/Logos/argenpymes3.png';
import AllariaAgroLogo from 'assets/Logos/allariaAgroLogo.svg';
import BannerImage from 'assets/banner.png';
import InitialDaruma from 'components/common/modals/initialDaruma';
import CustomTooltip from 'components/common/tooltips';
import { DarumaAlert } from 'components/common/modals/darumaAlert';
import Button from 'components/common/buttons/button';
import BusinessDarumaModal from 'components/common/modals/businessDarumaModal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStores } from 'store/root-store/root-store-context';
import { ProcedureAvailable } from 'models/procedure';

type TBannerDaruma = {
  darumaProcedure: ProcedureAvailable;
  setOpenUploadCompanies: (
    v: ProcedureAvailable,
    isProcedureInfo: boolean,
  ) => void;
};
const BannerDaruma = ({
  setOpenUploadCompanies,
  darumaProcedure,
}: TBannerDaruma) => {
  const {
    cuitsStore: { cuitsByEstados, cuitsByTercero, activeCuitsDaruma },
  } = useStores();
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openBusinessModal, setOpenBusinessModal] = useState(false);
  const navigate = useNavigate();

  const handleRequestDaruma = () => {
    navigate('/financiamiento/alta_daruma');
    setOpenModal(false);
  };

  const width = useMediaQuery('(min-width:767px)');

  const cuits = cuitsByEstados();
  const cuitsDaruma = cuitsByTercero({
    estadosAllowed: ['aprobado'],
    tercero: 'daruma',
  });
  const activeCuits = activeCuitsDaruma();

  return (
    <BannerContainer>
      <InitialDaruma
        open={openModal}
        onClose={() => setOpenModal(false)}
        onJoin={() => {
          if (cuitsDaruma.length === cuits.length) {
            setOpenAlert(true);
          } else {
            handleRequestDaruma();
          }
        }}
      />
      <BusinessDarumaModal
        allCuits={cuits.length}
        cuits={activeCuits}
        open={openBusinessModal}
        onClose={() => setOpenBusinessModal(false)}
        onJoin={() => {
          if (cuitsDaruma.length === cuits.length) {
            setOpenAlert(true);
          } else {
            handleRequestDaruma();
          }
        }}
        openModalInfo={() => {
          setOpenBusinessModal(false);
          setOpenModal(true);
        }}
      />
      <DarumaAlert open={openAlert} onClose={() => setOpenAlert(false)} />
      <Box
        className="bannerlBlue"
        style={{
          backgroundImage: `url(${BannerImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          backgroundSize: 'cover',
        }}
      >
        <Box className="ContainerDescription">
          <Box
            width={{ sx: '100%' }}
            style={{ minWidth: '50%' }}
            minHeight={{ mobileL: '80%' }}
          >
            <Box>
              <H4 color={colors.white} style={{ marginBottom: '6px' }}>
                La primera cuenta inteligente para empresas que crecen
              </H4>
              <P4
                color={colors.white}
                style={{ marginBottom: width ? '0' : '16px' }}
              >
                Sumate al ecosistema de agrofinanzas más grande del mercado
              </P4>
            </Box>
          </Box>
          <Box className="bannerImg">
            <CustomTooltip title="Allaria Ledesma & Cía." arrowLocation="top">
              <Box className="logoBox">
                <img
                  loading="lazy"
                  src={LedesmaLogo}
                  alt="ledesma"
                  className="carousel-logo"
                />
              </Box>
            </CustomTooltip>
            <CustomTooltip title="Argenpymes" arrowLocation="top">
              <Box className="logoBox">
                <img
                  loading="lazy"
                  src={ArgenpymesLogo}
                  alt="argenpymes"
                  className="carousel-logo"
                />
              </Box>
            </CustomTooltip>
            <CustomTooltip
              title="Morgan, García Mansilla & Cía."
              arrowLocation="top"
            >
              <Box className="logoBox">
                <img
                  loading="lazy"
                  src={AllariaAgroLogo}
                  alt="morgan"
                  className="carousel-logo"
                />
              </Box>
            </CustomTooltip>
            <CustomTooltip title="Allaria Fondos" arrowLocation="top">
              <Box className="logoBox">
                <img
                  loading="lazy"
                  src={AllariaPlusLogo}
                  alt="allaria"
                  className="carousel-logo"
                />
              </Box>
            </CustomTooltip>
          </Box>
        </Box>
      </Box>
      <Box className="bannerWhite">
        <Box style={{ display: 'flex', gap: 20 }}>
          <Box>
            <img
              loading="lazy"
              src={AllariaPlusLogo}
              alt="logo de daruma"
              width="125px"
              height="45px"
            />
          </Box>
          <Box className="description">
            {activeCuits.length > 0 && (
              <MUIButton
                style={{ padding: '0' }}
                onClick={() => setOpenBusinessModal(true)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: colors.tertiaryMedium,
                    padding: '2px 8px',
                    borderRadius: '4px',
                    gap: '4px',
                    width: 'max-content',
                    marginBottom: '5px',
                    ':hover': {
                      background: `-webkit-linear-gradient(left, ${colors.primaryBase}, ${colors.complementaryGreen})`,
                    },
                  }}
                >
                  <WorkIcon
                    sx={{
                      color: colors.white,
                      fontSize: 13,
                    }}
                  />
                  <P4 color={colors.white}>
                    {activeCuits.length}{' '}
                    {activeCuits.length <= 1
                      ? 'empresa activa'
                      : 'empresas activas'}
                  </P4>
                </Box>
              </MUIButton>
            )}
            <H6 style={{ marginBottom: '5px' }}>
              Ecosistema de Agrofinanzas Allaria
            </H6>
            <P4 style={{ color: colors.tertiaryMedium }}>
              Confiá en el ecosistema de reconocida trayectoria financiera
            </P4>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{
            xs: 'column',
            tabletS: 'row',
          }}
          alignItems="center"
          justifyContent="center"
          gap="8px"
        >
          <Button
            variant="outlined"
            preset="daruma"
            size="small"
            onClick={() => setOpenModal(true)}
            text="Más información"
          />

          <Button
            variant="contained"
            preset="daruma"
            size="small"
            onClick={() => {
              if (cuits.length === 0) {
                setOpenUploadCompanies(darumaProcedure, false);
              } else if (cuitsDaruma.length === cuits.length) {
                setOpenAlert(true);
              } else {
                handleRequestDaruma();
              }
            }}
            text="Quiero sumarme"
          />
        </Box>
      </Box>
    </BannerContainer>
  );
};
const ObserverDarumaBanner = observer(BannerDaruma);
export default ObserverDarumaBanner;
