import React from 'react';
import { Box, Divider } from '@mui/material';
import { EmpresaSolicitante } from 'components/common/EnvioSolicitud/EmpresaSolicitante';
import { Estado } from 'components/common/EnvioSolicitud/Estado';
import { trazabilidadEstado } from 'components/common/EnvioSolicitud/utils';
import colors from 'theme/colors';
import { IDarumaEstadoSolicitud } from 'models/models.daruma';
import DatosDeProducto from './DatosDeProducto';
import { DatosDeContacto } from './DatosDeContacto';
import { DatosRepresentante } from './DatosRepresentante';

export const DarumaEstadoSolicitud: React.FC<IDarumaEstadoSolicitud> = ({
  estadoSolicitudBody,
  cuit,
}) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        gap={2}
      >
        <EmpresaSolicitante
          cuit={estadoSolicitudBody.cuitSolicitante?.cuit}
          razonSocial={estadoSolicitudBody.cuitSolicitante?.razonSocial}
        />
        <Estado estado={trazabilidadEstado(estadoSolicitudBody.isAprobado)} />
      </Box>
      <Divider
        style={{
          margin: '28px 0px 20px 0px',
          borderWidth: '1px',
          color: colors.tertiaryMedium,
        }}
      />
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        gap={2}
      >
        <DatosDeContacto
          nombre={cuit.contacto?.nombre}
          apellido={cuit.contacto?.apellido}
          telefono={cuit.contacto?.telefono}
          email={cuit.contacto?.email}
        />
        <DatosRepresentante
          nombre={estadoSolicitudBody.firmante?.nombre}
          apellido={estadoSolicitudBody.firmante?.apellido}
          telefono={estadoSolicitudBody.firmante?.telefono}
          email={estadoSolicitudBody.firmante?.email}
          domicilio={estadoSolicitudBody.firmante?.domicilio}
        />
      </Box>
      {cuit.terceros?.argenpymes &&
      estadoSolicitudBody.estado === 'solicitud_finalizada' ? (
        <>
          <Divider
            style={{
              margin: '28px 0px 20px 0px',
              borderWidth: '1px',
              color: colors.tertiaryMedium,
            }}
          />
          <DatosDeProducto product="Combo Argenpymes" />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
