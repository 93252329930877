/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import {
  TextField,
  InputLabel,
  Slider,
  InputBase,
  Checkbox,
  Radio,
  SxProps,
} from '@mui/material';
import { Button } from '@mui/base/Button';
import colors from 'theme/colors';

interface StyledTextInputProps {
  width: string;
  height?: string;
  padding?: string;
  error?: boolean;
  disabled?: boolean;
  float?: boolean;
}

interface IconBtnProps {
  location: 'left' | 'right';
}

export const IconBtn = styled(Button)<IconBtnProps>`
  position: absolute;
  left: ${({ location }) => (location === 'left' ? '11px' : 'auto')};
  right: ${({ location }) => (location === 'left' ? 'auto' : '11px')};
  border: none;
  background: ${colors.transparent};
  cursor: pointer;
`;

export const StyledInput = styledMUI('input')<StyledTextInputProps>`
  box-sizing: border-box; 
  width: 100%;
  height: ${(props) => props.height || '48px'};
  padding: ${({ float }) => (float ? '8px 40px' : '8px 14px')};
  border: 1px solid;
  border-color: ${(props) =>
    props.error
      ? colors.complementaryError
      : props.disabled
        ? colors.tertiaryIntermediate
        : colors.tertiaryBase};
  border-radius: 8px;
  font-family: 'Work Sans';
  font-style: normal;
  font-size: ${(props) => (props.float ? '16px' : '18px')};
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: ${(props) =>
    props.disabled ? colors.tertiaryMedium : colors.tertiaryBase}; 
  background: ${(props) =>
    props.disabled ? colors.tertiaryIntermediate : colors.transparent};

  .icon-btn-search {
    .search-icon {
      color: red;
    }
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & {
    -moz-appearance: textfield;
  }
  
  &::placeholder {
    color: ${colors.tertiaryMedium};
    opacity: 1;
  }

  &:focus {
    outline: 3px solid ${colors.primaryMedium};
    color: ${colors.tertiaryBase};
  }
`;

export const StyledTextarea = styledMUI('textarea')<StyledTextInputProps>`
  resize: none;
  width: ${(props) => props.width || '430px'};
  height: ${(props) => props.height || '48px'};
  padding: ${(props) => props.padding || '8px 14px'};
  border: 1px solid;
  border-color: ${(props) =>
    props.error
      ? colors.complementaryError
      : props.disabled
        ? colors.tertiaryIntermediate
        : colors.tertiaryBase};
  border-radius: 8px;
  font-family: 'Work Sans';
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: ${(props) =>
    props.disabled ? colors.tertiaryHigh : colors.tertiaryBase}; 
  background: ${(props) =>
    props.disabled ? colors.tertiaryIntermediate : colors.transparent};

  &::-webkit-scrollbar-track, &::-webkit-scrollbar {
    border-radius: 0 8px 8px 0;
  }

  .icon-btn-search {
    .search-icon {
      color: red;
    }
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & {
    -moz-appearance: textfield;
  }
  
  &::placeholder {
    color: ${colors.tertiaryMedium};
    opacity: 1;
    fontSize: 18px;
  }

  &:focus {
    outline: 3px solid ${colors.primaryMedium};
    color: ${colors.tertiaryBase};
  }
`;
interface StyledSelectProps {
  width?: string;
  height?: string;
}
export const StyledSelect = styledMUI(InputBase)<StyledSelectProps>`
  width: 100%;
  height: 48px;

  & .MuiInputBase-input {
    font-family: Work Sans;
    font-style: normal;
    font-size: 18px;
    margin: 0px;
    line-height: 23.4px;
    font-weight: 500;
    color: ${colors.tertiaryBase} !important;
  }
  
  & .MuiInputBase-input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: ${colors.tertiaryMedium};
    color: ${colors.tertiaryMedium};
  }

  & .MuiSelect-iconOutlined {
    color: ${(props) =>
      props.disabled ? colors.tertiaryMedium : colors.tertiaryBase};
  }
  & .MuiSelect-outlined {
    border: ${(props) => (props.disabled ? 'none' : '1px solid')};
    border-color: ${(props) =>
      props.error ? colors.complementaryError : colors.tertiaryBase};
    background-color: ${(props) =>
      props.disabled ? colors.tertiaryIntermediate : colors.transparent};
    padding: 11.5px 11px;
    border-radius: 8px;
    &:focus {
      outline: 3px solid ${colors.primaryMedium};
      border-radius: 8px;
    }
  }
`;

export const StyledLabel = styled(InputLabel)`
  color: ${colors.tertiaryBase};
  font-family: Work Sans !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px;
  margin: 0px 0px 4px 0px !important;
  line-height: 24px !important;
`;

export const StyledEmailInput = styled(TextField)`
  & .MuiInputBase-root {
    width: 100%;
    height: 48px;
    border: 1px solid #4b484b;
    border-radius: 8px;
    color: ${colors.tertiaryBase};
    font-family: 'Work Sans';
    font-style: normal;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.01em;
  }
`;
export const StyledError = styled.div`
  margin-top: 4px;
`;
interface StyledLoadFileProps {
  hasFile: boolean;
  error: boolean;
}

export const StyledLoadFile = styled.div<StyledLoadFileProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* max-width: 354px; */
  height: 128px;
  cursor: pointer;
  border: 1px solid
    ${(props) => (props.error ? colors.red : colors.tertiaryBase)} !important;
  border-radius: 8px;
  color: ${colors.tertiaryBase};
  background-color: #ffffff;

  & > * {
    color: ${(props) =>
      props.hasFile ? colors.tertiaryBase : colors.tertiaryMedium};
  }

  &:hover {
    .edit-icon {
      background: ${colors.complementaryGreen};
    }
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const ZoomSlider = styledMUI(Slider)({
  color: colors.primaryBase,
  height: 4,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 22,
    width: 22,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '@media (max-width: 767px)': {
      height: 10,
      width: 10,
    },
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    backgroundColor: colors.tertiaryIntermediate,
  },
});

interface CheckboxProps {
  error?: boolean;
  disabled?: boolean;
  checked?: boolean;
  warning?: boolean;
}

export const StyledCheckbox = styledMUI(Checkbox)<CheckboxProps>`
  color: ${({ error, disabled, checked, warning }) =>
    warning && checked
      ? colors.complementaryYellow
      : error && checked
        ? colors.complementaryError
        : disabled
          ? colors.tertiaryIntermediate
          : colors.tertiaryBase};
  padding: 0;
  margin: 0 2px;
  border-radius: 0;

  &.Mui-checked {
    color: ${({ error, disabled, warning }) =>
      warning
        ? colors.complementaryYellow
        : error
          ? colors.complementaryError
          : disabled
            ? colors.tertiaryIntermediate
            : colors.complementaryGreen};
  }

  &:focus {
    color: ${colors.tertiaryBase};
    outline: 3px solid ${colors.primaryMedium};
    outline-offset: -3px;
    border-radius: 4px;
  }
`;

export const StyledRadio = styledMUI(Radio)<CheckboxProps>`
color: ${({ error, disabled }) =>
  error
    ? colors.complementaryError
    : disabled
      ? colors.tertiaryIntermediate
      : colors.tertiaryBase};
padding: 0;
margin: 0 2px;

&.Mui-checked {
  color: ${({ error, disabled }) =>
    error
      ? colors.complementaryError
      : disabled
        ? colors.tertiaryIntermediate
        : colors.complementaryGreen};
}

&:focus {
  color: ${colors.tertiaryBase};
  outline: 3px solid ${colors.primaryMedium};
  outline-offset: -3px;
}
`;

export const popperSx: SxProps = {
  '*': {
    fontSize: '16px !important',
    fontFamily: 'Work Sans !important',
  },
  '& .MuiCalendarPicker-root': {
    backgroundColor: `${colors.white} !important`,
  },
  '& .MuiPickersDay-dayWithMargin': {
    backgroundColor: `${colors.white} !important`,
    color: `${colors.tertiaryBase} !important`,
    '&:hover': {
      backgroundColor: `${colors.primaryBase} !important`,
      color: `${colors.white} !important`,
    },
    '&:focus': {
      backgroundColor: `${colors.primaryBase} !important`,
      color: `${colors.white} !important`,
    },
  },
  '& .Mui-selected': {
    backgroundColor: `${colors.primaryBase} !important`,
    color: `${colors.white} !important`,
    '&:hover': {
      backgroundColor: `${colors.primaryBase} !important`,
      color: `${colors.white} !important`,
    },
    '&:focus': {
      backgroundColor: `${colors.primaryBase} !important`,
      color: `${colors.white} !important`,
    },
  },
  '& .MuiPickersDay-today': {
    border: `1px solid ${colors.secondaryBase} !important`,
    color: `${colors.secondaryBase} !important`,
    '&:hover': {
      backgroundColor: `${colors.secondaryBase} !important`,
      color: `${colors.white} !important`,
    },
    '&:focus': {
      backgroundColor: `${colors.secondaryBase} !important`,
      color: `${colors.white} !important`,
    },
  },
  '& .MuiTabs-root': { backgroundColor: 'rgba(120, 120, 120, 0.4)' },
};

export const CustomContainerInput = styledMUI('div')<StyledTextInputProps>`
  box-sizing: border-box; 
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${(props) => props.height || '48px'};
  padding: ${({ float }) => (float ? '8px 40px' : '8px 14px 8px 5px')};
  border: 1px solid;
  border-color: ${(props) =>
    props.error
      ? colors.complementaryError
      : props.disabled
        ? colors.tertiaryIntermediate
        : colors.tertiaryBase};
  border-radius: 8px;
  font-family: 'Work Sans';
  font-style: normal;
  font-size: ${(props) => (props.float ? '16px' : '18px')};
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: ${(props) =>
    props.disabled ? colors.tertiaryMedium : colors.tertiaryBase}; 
  background: ${(props) =>
    props.disabled ? colors.tertiaryIntermediate : colors.transparent};

  .icon-btn-search {
    .search-icon {
      color: red;
    }
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & {
    -moz-appearance: textfield;
  }
  
  &::placeholder {
    color: ${colors.tertiaryMedium};
    opacity: 1;
  }

  &:focus {
    outline: 3px solid ${colors.primaryMedium};
    color: ${colors.tertiaryBase};
  }
  &::file-selector-button {
   display: none;
  }
  &::file-input {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
`;
