import React from 'react';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import colors from 'theme/colors';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    PopperProps={{ style: { zIndex: 5000 } }}
  />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.black,
    zIndex: 9999,
  },
}));
