import React from 'react';
import { observer } from 'mobx-react';
import { Box, Paper } from '@mui/material';
import { P3, P4, H5 } from 'components/common/typography/styles';
import { useStores } from 'store/root-store/root-store-context';
import colors from 'theme/colors';
import Button from 'components/common/buttons/button';

interface ProfileInfoProps {
  setOpenDrawerData: (value: boolean) => void;
  setOpenDrawerPassword: (value: boolean) => void;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({
  setOpenDrawerData,
  setOpenDrawerPassword,
}) => {
  const {
    sessionStore: { currentUser },
  } = useStores();

  return (
    <Paper elevation={2} sx={{ width: 'calc(100% - 32px)', padding: '16px' }}>
      <H5 style={{ marginBottom: '16px' }}>Mis datos</H5>
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          tablet: 'row',
        }}
        gap={{
          xs: '8px',
          tablet: '40px',
        }}
      >
        <Box
          display="flex"
          flexDirection={{
            xs: 'column',
            mobileM: 'row',
          }}
          gap={{
            xs: '8px',
            tablet: '40px',
          }}
        >
          <Box
            width={{
              xs: '100%',
              mobileM: '50%',
              tabletS: '50%',
              tablet: 'max-content',
            }}
          >
            <P4 color={colors.tertiaryMedium}>NOMBRES</P4>
            <P3 color="#000000">{currentUser?.firstName || '-'}</P3>
          </Box>
          <Box
            width={{
              xs: '100%',
              mobileM: '50%',
              tablet: 'max-content',
            }}
          >
            <P4 color={colors.tertiaryMedium}>APELLIDOS</P4>
            <P3 color="#000000">{currentUser?.lastName || '-'}</P3>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{
            xs: 'column',
            mobileM: 'row',
          }}
          gap={{
            xs: '8px',
            tablet: '40px',
          }}
        >
          <Box
            width={{
              xs: '100%',
              mobileM: '49%',
              tabletS: '50%',
              tablet: 'max-content',
            }}
          >
            <P4 color={colors.tertiaryMedium}>EMAIL</P4>
            <P3
              color="#000000"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {currentUser?.email}
            </P3>
          </Box>
          <Box
            width={{
              xs: '100%',
              mobileM: '50%',
              tablet: 'max-content',
            }}
          >
            <P4 color={colors.tertiaryMedium}>TELÉFONO</P4>
            <P3 color="#000000">{currentUser?.telefono || '-'}</P3>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          tabletS: 'row',
        }}
        justifyContent={{
          xs: 'center',
          tablet: 'flex-start',
        }}
        alignItems="center"
        marginTop="24px"
        gap="8px"
      >
        <Button
          variant="contained"
          preset="normal"
          size="small"
          onClick={() => setOpenDrawerData(true)}
          text="Modificar mis datos"
        />
        <Button
          variant="outlined"
          preset="normal"
          size="small"
          onClick={() => setOpenDrawerPassword(true)}
          text={
            currentUser?.hasPassword
              ? 'Modificar contraseña'
              : 'Generar contraseña'
          }
        />
      </Box>
    </Paper>
  );
};

export default observer(ProfileInfo);
