import React from 'react';
import { SvgProps } from '..';

const LanConnectIcon: React.FC<SvgProps> = ({ color, height, width }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_34530_92446)">
        <path
          d="M2.6665 0.664062C1.9265 0.664062 1.33317 1.2574 1.33317 1.9974V4.66406C1.33317 5.40406 1.9265 5.9974 2.6665 5.9974H0.666504V7.33073H8.6665V5.9974H6.6665C7.4065 5.9974 7.99984 5.40406 7.99984 4.66406V1.9974C7.99984 1.2574 7.4065 0.664062 6.6665 0.664062H2.6665ZM2.6665 1.9974H6.6665V4.66406H2.6665V1.9974ZM1.99984 8.66406V11.9974V13.3307H6.6665V11.9974H3.33317V8.66406H1.99984ZM9.33317 8.66406C8.59317 8.66406 7.99984 9.2574 7.99984 9.9974V12.6641C7.99984 13.4041 8.59317 13.9974 9.33317 13.9974H7.33317V15.3307H15.3332V13.9974H13.3332C14.0732 13.9974 14.6665 13.4041 14.6665 12.6641V9.9974C14.6665 9.2574 14.0732 8.66406 13.3332 8.66406H9.33317ZM9.33317 9.9974H13.3332V12.6641H9.33317V9.9974Z"
          fill={color || '#928176'}
        />
      </g>
      <defs>
        <clipPath id="clip0_34530_92446">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LanConnectIcon;
