import { Card, CardContent, styled } from '@mui/material';
import colors from 'theme/colors';

export const ProductCard = styled(Card)(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '8px',
}));

export const ProductCardContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: 0,
  '&:last-child': {
    paddingBottom: 0,
  },
}));

export const CardMediaHeaderStyle = {
  width: '100%',
  height: '55.4px',
  position: 'absolute',
  zIndex: 1,
};

export const CardMediaProductStyle = {
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  border: '1px solid #fff',
  backgroundColor: colors.white,
  zIndex: 2,
};
