import React from 'react';
import { SxProps } from '@mui/material';
import { StyledCheckbox } from '../index.styled';

interface CheckboxProps {
  sx?: SxProps;
  defaultChecked?: boolean;
  disabled?: boolean;
  checked: boolean;
  name?: string;
  error?: boolean;
  warning?: boolean;
  indeterminate?: boolean;
  onChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
}

const InputCheckbox = (props: CheckboxProps) => {
  return <StyledCheckbox {...props} disableRipple />;
};

export default InputCheckbox;
