import { useState, MouseEvent } from 'react';
import Popover from '@mui/material/Popover';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import colors from 'theme/colors';
import { H6, P4 } from 'components/common/typography/styles';

type Props = {
  setDeleteField: () => void;
  setNewFieldName: () => void;
};

const FieldOptions = ({ setNewFieldName, setDeleteField }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const defBtnStyle = () => {
    if (open) {
      return {
        border: `1px solid ${colors.primaryBase} `,
        color: colors.primaryBase,
      };
    }
    return {
      border: `1px solid ${colors.tertiaryBase} `,
      color: colors.tertiaryBase,
    };
  };

  return (
    <>
      <Box
        display={{ xs: 'none', sm: 'flex' }}
        alignItems="center"
        onClick={handleClick}
        bgcolor={colors.white}
        sx={{
          ...defBtnStyle(),
          cursor: 'pointer',
        }}
        height="31px"
        p="0px 16px"
        borderRadius="4px"
      >
        <H6
          color="inherit"
          style={{
            lineHeight: '16.8px',
            letterSpacing: '-0.28px',
          }}
        >
          Opciones
        </H6>
      </Box>
      <Box display={{ xs: 'block', sm: 'none' }} onClick={handleClick}>
        <MoreVertIcon sx={{ color: colors.tertiaryLow }} />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 52,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{ paddingY: '4px' }}>
          <ListItem
            disablePadding
            secondaryAction={<EditIcon fontSize="small" />}
          >
            <ListItemButton
              dense
              role={undefined}
              onClick={() => {
                setNewFieldName();
                handleClose();
              }}
            >
              <ListItemText
                primary={
                  <P4 color={colors.black} style={{ fontWeight: 600 }}>
                    Modificar nombre
                  </P4>
                }
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem
            disablePadding
            secondaryAction={
              <DeleteIcon
                fontSize="small"
                sx={{ color: colors.complementaryError }}
              />
            }
          >
            <ListItemButton
              dense
              role={undefined}
              onClick={() => {
                setDeleteField();
                handleClose();
              }}
            >
              <ListItemText
                primary={
                  <P4
                    color={colors.complementaryError}
                    style={{ fontWeight: 600 }}
                  >
                    Eliminar campo
                  </P4>
                }
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default FieldOptions;
