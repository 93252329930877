import { useState } from 'react';
import { Box, Collapse, useMediaQuery } from '@mui/material';
import { UserData } from 'utils/types';
import MenuDesktop from 'components/sections/menu/desktop';
import MenuMobile from 'components/sections/menu/mobile';
import { StyledMenuDrawer } from '../../common/drawer/index.styled';
import { useStores } from 'store/root-store/root-store-context';

interface DrawerProps {
  open: boolean;
  setOpenMenu: (a: boolean) => void;
  onClose: () => void;
  userData: UserData;
}

const Menu = ({ open, setOpenMenu, onClose, userData }: DrawerProps) => {
  const {
    sessionStore: { menuDesplegablesCodigos },
  } = useStores();
  const [openDropdown, setOpenDropdown] = useState<{
    [codigo: string]: boolean;
  }>(menuDesplegablesCodigos);

  const handleOpenDropdown = (codigo: string, state: boolean) => {
    setOpenDropdown({
      ...openDropdown,
      [codigo]: state,
    });
  };

  return (
    <Box className="elevation4" style={{ zIndex: 10 }}>
      {/* width < 1280  */}
      {useMediaQuery('(max-width:1280px)') ? (
        <>
          <StyledMenuDrawer
            anchor="left"
            open={open}
            onClose={onClose}
            width="284px"
          >
            <Box className="drawer-container">
              <MenuDesktop
                userData={userData}
                openMenu={open}
                setOpenMenu={setOpenMenu}
                openDropdown={openDropdown}
                setOpenDropdown={handleOpenDropdown}
              />
            </Box>
          </StyledMenuDrawer>
          {/* width >= 1024  */}
        </>
      ) : (
        <Collapse in={open} orientation="horizontal" collapsedSize="59px">
          <MenuDesktop
            userData={userData}
            openMenu={open}
            setOpenMenu={setOpenMenu}
            openDropdown={openDropdown}
            setOpenDropdown={handleOpenDropdown}
          />
          <MenuMobile
            userData={userData}
            openMenu={!open}
            setOpenMenu={setOpenMenu}
            setOpenDropdown={handleOpenDropdown}
          />
        </Collapse>
      )}
    </Box>
  );
};

export default Menu;
