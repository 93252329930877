import React from 'react';
import Header from 'components/sections/header';
import { Box, useMediaQuery } from '@mui/material';
import colors from '../../theme/colors';
import { useStores } from 'store/root-store/root-store-context';
import { useLocation } from 'react-router-dom';
import SubHeader from 'components/sections/subHeader';
import Menu from 'components/sections/menu';

interface MainLayoutProps {
  userData: any;
  children: React.ReactNode;
}

const PROCEDURES = ['certificaciones', 'financiamiento', 'seguros'];

const MainLayout = (props: MainLayoutProps) => {
  const { children, userData } = props;
  const { sessionStore } = useStores();
  const location = useLocation();
  const { pathname } = location;
  const page = sessionStore.getMenuByFullUrl(pathname);
  const isSubPage = pathname.split('/').filter((x) => x).length >= 2;

  // width >= 1280
  const minWidth = useMediaQuery('(min-width:1280px)');

  // width >= 1024
  const minWidth1024 = useMediaQuery('(min-width:1024px)');
  const maxWidth767 = useMediaQuery('(max-width:767px)');

  // width <= 1024
  const maxWidth1024 = useMediaQuery('(max-width:1024px)');
  const [openMenu, setOpenMenu] = React.useState(minWidth);

  const getWidthByMenu = () => {
    if (maxWidth1024) {
      return '100%';
    }

    if (minWidth) {
      return `calc(100vw - ${openMenu ? '286px' : '59px'})`;
    }

    if (minWidth1024) {
      return '100vw';
    }

    return '100%';
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      overflow="hidden"
    >
      {page && PROCEDURES.includes(page.codigo) && isSubPage && maxWidth767 ? (
        <SubHeader title={page.seccion} />
      ) : (
        <Header
          userData={userData}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
        />
      )}
      <Box display="flex" flex={1} position="relative">
        <Menu
          open={openMenu}
          setOpenMenu={setOpenMenu}
          onClose={() => setOpenMenu(false)}
          userData={userData}
        />
        <Box
          component="main"
          sx={{
            flex: 1,
            backgroundColor: colors.tertiaryHigh,
            width: '100%',
            transition: `${minWidth && openMenu ? 'all 0.5s ease-out 0s' : ''}`,
            maxWidth: getWidthByMenu(),
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
