import { InitialStateAgree } from './index.types';

export const initialStateAgree: InitialStateAgree = {
  cuits: [],
  stateProcedure: 'seleccion_empresa',
  refused: false,
  products: [],
  moveForward: false,
  productName: '',
  cuitSelected: null,
  estadoSolicitud: null,
  routeState: 'seleccion_empresa',
};
