import React, { FC } from 'react';
import { Box, LinearProgress } from '@mui/material';
import colors from 'theme/colors';
import { ColorByCheckList } from 'utils/checklistPassUtils';
import { P3, P4 } from '../typography/styles';
import CheckSvg from '../../../assets/icons/check.svg';

type TCheckListPass = {
  label: string;
  checked: boolean;
  value: number;
};

interface ICheckListPass {
  checksList: TCheckListPass[];
  progress: number;
}

export const ChekcListPass: FC<ICheckListPass> = ({ checksList, progress }) => {
  const colorByCheckList = ColorByCheckList(progress, colors);
  return (
    <Box marginTop="12px" marginBottom="12px">
      <LinearProgress
        value={progress}
        variant="determinate"
        sx={{
          '&, .MuiLinearProgress-root': {
            borderRadius: '8px',
            '.css-5xe99f-MuiLinearProgress-bar1': {
              width: '100%',
              position: 'absolute',
              left: 0,
              bottom: 0,
              top: 0,
              '-webkit-transition': '-webkit-transform .4s linear',
              transition: 'transform .4s linear',
              transformOrigin: 'left',
              backgroundColor: colorByCheckList,
            },
            '&, bar1Determinate': {
              backgroundColor: colors.tertiaryHigh,
            },
          },
        }}
      />
      <P4 style={{ margin: '16px 12px' }}>
        Tu contraseña debe incluir al menos:
      </P4>
      {checksList.map((e: TCheckListPass) => {
        return (
          <Box
            key={e.label}
            marginLeft="16px"
            display="flex"
            justifyContent="flex-start"
            flexDirection="row"
            alignContent="center"
            alignItems="center"
            textAlign="center"
          >
            <P3 style={{ color: colors.tertiaryMedium }}>
              {e.checked ? (
                <img loading="lazy" width="11px" src={CheckSvg} alt="check" />
              ) : (
                '.'
              )}
            </P3>
            <P4
              style={{
                color: e.checked
                  ? colors.complementaryGreen
                  : colors.tertiaryMedium,
                marginLeft: e.checked ? '11px' : '17px',
                marginTop: e.checked ? '0px' : '4px',
              }}
            >
              {e.label}
            </P4>
          </Box>
        );
      })}
    </Box>
  );
};
