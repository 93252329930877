import React from 'react';
import { Box } from '@mui/material';
import { Caps, H6 } from 'components/common/typography/styles';
import colors from 'theme/colors';
import {
  darumaWithoutArgenypmes,
  procedureStates,
  ProcedureStateType,
} from 'utils/procedureStates';

interface StateProcess {
  procedure:
    | 'financiamiento'
    | 'certificaciones'
    | 'viterra'
    | 'puma'
    | 'seguro'
    | 'daruma'
    | 'argenpymes'
    | 'daruma_without_argenpymes'
    | 'sancor_seguro'
    | 'agree';
  state: string; // States of financing or certifications
  refused: boolean;
}

const insuranceStates = {
  ongoing: {
    seleccion_campos: procedureStates.seguro.seleccion_campos,
    revision_informe: procedureStates.seguro.revision_informe,
    monto_asegurar: procedureStates.seguro.monto_asegurar,
    envio_solicitud: procedureStates.seguro.envio_solicitud,
    cotizacion: procedureStates.seguro.cotizacion,
    solicitud_enviada: procedureStates.seguro.solicitud_enviada,
  },
  refused: {
    solicitud_enviada: procedureStates.seguro.solicitud_enviada,
  },
};

const sancorSeguroStates = {
  ongoing: {
    seleccion_campos: procedureStates.sancor.seleccion_campos,
    revision_informe: procedureStates.sancor.revision_informe,
    monto_asegurar: procedureStates.sancor.monto_asegurar,
    envio_solicitud: procedureStates.sancor.envio_solicitud,
    cotizacion: procedureStates.sancor.cotizacion,
    solicitud_enviada: procedureStates.sancor.solicitud_enviada,
  },
  refused: {
    solicitud_enviada: procedureStates.seguro.solicitud_enviada,
  },
};

const financingStates = {
  ongoing: {
    seleccion_empresa: procedureStates.financiamiento.seleccion_empresa,
    generacion_informe: procedureStates.financiamiento.generacion_informe,
    revision_informe: procedureStates.financiamiento.revision_informe,
    solicitud_enviada: procedureStates.financiamiento.solicitud_enviada,
  },
  refused: {
    seleccion_empresa: procedureStates.financiamiento.seleccion_empresa,
    generacion_informe: procedureStates.financiamiento.generacion_informe,
    fin_rechazado: procedureStates.financiamiento.fin_rechazado,
  },
};

const certificationsStates = {
  ongoing: {
    seleccion_campos: procedureStates.certificaciones.seleccion_campos,
    validacion_campos: procedureStates.certificaciones.validacion_campos,
    asignacion_hectareas: procedureStates.certificaciones.asignacion_hectareas,
    revision_firmas: procedureStates.certificaciones.revision_firmas,
    finalizado: procedureStates.certificaciones.finalizado,
  },
  refused: {
    seleccion_campos: procedureStates.certificaciones.seleccion_campos,
    validacion_campos: procedureStates.certificaciones.validacion_campos,
    cert_rechazado: procedureStates.certificaciones.cert_rechazado,
  },
};

const viterraStates = {
  ongoing: {
    seleccion_campos: procedureStates.viterra.seleccionar_campos,
    validacion_campos: procedureStates.viterra.validacion_campos,
    asignacion_hectareas: procedureStates.viterra.asignacion_hectareas,
    revision_firmas: procedureStates.viterra.revision_firmas,
    finalizado: procedureStates.viterra.finalizado,
  },
  refused: {
    seleccion_campos: procedureStates.viterra.seleccionar_campos,
    validacion_campos: procedureStates.viterra.validacion_campos,
    cert_rechazado: procedureStates.viterra.cert_rechazado,
  },
};

const pumaStates = {
  ongoing: {
    seleccion_empresa: procedureStates.puma.seleccion_empresa,
    seleccion_campos: procedureStates.puma.seleccion_campos,
    revision_cultivos: procedureStates.puma.revision_cultivos,
    envio_solicitud: procedureStates.puma.envio_solicitud,
    solicitud_procesada: procedureStates.puma.solicitud_procesada,
    solicitud_terminada: procedureStates.puma.solicitud_terminada,
  },
  refused: {
    seleccion_empresa: procedureStates.puma.seleccion_empresa,
    seleccion_campos: procedureStates.puma.seleccion_campos,
    revision_cultivos: procedureStates.puma.revision_cultivos,
    envio_solicitud: procedureStates.puma.envio_solicitud,
    solicitud_procesada: {
      ...procedureStates.puma.solicitud_procesada,
      color: '#FF3C00',
    },
    solicitud_terminada: {
      ...procedureStates.puma.solicitud_enviada,
      color: '#FF3C00',
    },
  },
};

const agreeStates = {
  ongoing: {
    seleccion_empresa: procedureStates.agree.seleccion_empresa,
    seleccion_productos: procedureStates.agree.seleccion_productos,
    solicitud_enviada: procedureStates.agree.solicitud_enviada,
    solicitud_finalizada: procedureStates.agree.solicitud_finalizada,
  },
  refused: {
    seleccion_empresa: procedureStates.agree.seleccion_empresa,
    seleccion_productos: procedureStates.agree.seleccion_productos,
    solicitud_enviada: procedureStates.agree.solicitud_enviada,
    solicitud_finalizada: {
      ...procedureStates.agree.solicitud_finalizada,
      color: '#FF3C00',
    },
  },
};

const darumaState = {
  ongoing: {
    seleccion_empresa: procedureStates.daruma.seleccion_empresa,
    representante: procedureStates.daruma.representante,
    info_adicional: procedureStates.daruma.info_adicional,
    productos_adicionales: procedureStates.daruma.productos_adicionales,
    envio_solicitud: procedureStates.daruma.envio_solicitud,
    solicitud_finalizada: procedureStates.daruma.solicitud_finalizada,
  },
  refused: {
    seleccion_empresa: procedureStates.daruma.seleccion_empresa,
    representante: procedureStates.daruma.representante,
    info_adicional: procedureStates.daruma.info_adicional,
    productos_adicionales: procedureStates.daruma.productos_adicionales,
    envio_solicitud: procedureStates.daruma.envio_solicitud,
    solicitud_finalizada: {
      ...procedureStates.daruma.solicitud_finalizada,
      color: '#FF3C00',
    },
  },
};

const darumaWhitoutArgenpymesState = {
  ongoing: {
    seleccion_empresa: darumaWithoutArgenypmes.seleccion_empresa,
    representante: darumaWithoutArgenypmes.representante,
    info_adicional: darumaWithoutArgenypmes.info_adicional,
    envio_solicitud: darumaWithoutArgenypmes.envio_solicitud,
    solicitud_finalizada: darumaWithoutArgenypmes.solicitud_finalizada,
  },
  refused: {
    seleccion_empresa: darumaWithoutArgenypmes.seleccion_empresa,
    representante: darumaWithoutArgenypmes.representante,
    info_adicional: darumaWithoutArgenypmes.info_adicional,
    envio_solicitud: darumaWithoutArgenypmes.envio_solicitud,
    solicitud_finalizada: {
      ...darumaWithoutArgenypmes.solicitud_finalizada,
      color: '#FF3C00',
    },
  },
};

const argenpymestate = {
  ongoing: {
    seleccion_empresa: procedureStates.argenpymes.seleccion_empresa,
    envio_solicitud: procedureStates.argenpymes.envio_solicitud,
    solicitud_finalizada: procedureStates.argenpymes.solicitud_finalizada,
  },
  refused: {
    seleccion_empresa: procedureStates.argenpymes.seleccion_empresa,
    envio_solicitud: procedureStates.argenpymes.envio_solicitud,
    solicitud_finalizada: {
      ...procedureStates.argenpymes.solicitud_finalizada,
      color: '#FF3C00',
    },
  },
};

const statesByProcedure = {
  financiamiento: financingStates,
  seguro: insuranceStates,
  viterra: viterraStates,
  puma: pumaStates,
  agree: agreeStates,
  daruma: darumaState,
  daruma_without_argenpymes: darumaWhitoutArgenpymesState,
  argenpymes: argenpymestate,
  certificaciones: certificationsStates,
  sancor_seguro: sancorSeguroStates,
};

const ProcedureProgress: React.FC<StateProcess> = ({
  procedure,
  state,
  refused,
}) => {
  const states: ProcedureStateType = refused
    ? statesByProcedure[procedure].refused
    : statesByProcedure[procedure].ongoing;
  const valuesLength = Object.values(states).length;

  return (
    <Box
      width="100%"
      height="32px"
      position="relative"
      marginBottom="24px"
      display={states[state].percentage === 100 ? 'none' : 'block'}
    >
      <Box
        sx={{
          display: {
            xs: 'none',
            tablet: 'flex',
          },
          justifyContent: 'space-between',
          marginBottom: '2px',
          fontSize: '12px',
        }}
      >
        {Object.values(states).map((item, index) => {
          return (
            <H6
              key={item.name.split(' ').join('_')}
              style={{
                color:
                  Object.keys(states).indexOf(state) >= index
                    ? colors.tertiaryBase
                    : colors.tertiaryIntermediate,
                fontSize: '12px',
                width: '100px',
                textAlign:
                  index === 0
                    ? 'start'
                    : valuesLength - 1 === index
                    ? 'end'
                    : 'center',
                alignSelf: 'center',
                transition: 'color 0.7s',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ':hover': {
                  color:
                    Object.keys(states).indexOf(state) >= index
                      ? colors.tertiaryBase
                      : colors.tertiaryIntermediate,
                },
              }}
            >
              {item.name.toUpperCase()}
            </H6>
          );
        })}
      </Box>
      <Box
        sx={{
          display: {
            xs: 'flex',
            tablet: 'none',
          },
          justifyContent: 'space-between',
          marginBottom: '2px',
          fontSize: '12px',
        }}
      >
        <Caps
          key={`${states[state].name.split(' ').join('_')}_1`}
          style={{
            color: colors.tertiaryBase,
            fontWeight: 350,
            alignSelf: 'center',
            transition: 'color 0.7s',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ':hover': {
              color: colors.tertiaryBase,
            },
          }}
        >
          {states[state].name.toUpperCase()}
        </Caps>
        <Caps
          key={`${states[state].name.split(' ').join('_')}_2`}
          style={{
            color: colors.tertiaryBase,
            fontWeight: 350,
            alignSelf: 'center',
            transition: 'color 0.7s',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ':hover': {
              color: colors.tertiaryBase,
            },
          }}
        >
          {Object.keys(states).indexOf(state) + 1}/{Object.keys(states).length}
        </Caps>
      </Box>
      <Box
        sx={{
          width: `${states[state].percentage}%`,
          height: '4px',
          background: states[state].color,
          borderRadius: '29px',
          position: 'absolute',
          transition: 'width 0.7s',
          ':hover': {
            width: `${states[state].percentage}%`,
          },
        }}
      />
      <Box
        sx={{
          width: '100%',
          height: '4px',
          background: colors.tertiaryIntermediate,
          borderRadius: '29px',
        }}
      />
    </Box>
  );
};

export default ProcedureProgress;
