import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { H4, H6 } from 'components/common/typography/styles';
import LinkBtn from 'components/common/buttons/linkBtn';
import ProcedureModal from 'components/common/modals/procedureModal';
import InitialDaruma from 'components/common/modals/initialDaruma';
import colors from 'theme/colors';
import { useStores } from 'store/root-store/root-store-context';
import { ProcedureStatus } from 'models/procedure';
import {
  StyledChildrenContainer,
  StyledChildrenWrapper,
  StyledContainer,
  StyledIcon,
  StyledProcedureInfo,
} from './index.styled';

interface IProcedureLayoutProps {
  procedure: 'certificaciones' | 'seguros' | 'financiamiento' | null;
  children: React.ReactNode;
}

const ProcedureLayout: React.FC<IProcedureLayoutProps> = ({
  procedure = null,
  children,
}) => {
  const { sessionStore, procedureHeaderStore } = useStores();
  const [open, setOpen] = useState(false);

  const baseApi = import.meta.env.VITE_API_BASE_URL || '';

  if (!procedure) {
    throw new Error('ProcedureLayout: procedure prop is required');
  }
  const basePath = `/${procedure}`;

  const { alias, productName, markdownTemplate, images, status, headerImg } =
    procedureHeaderStore;

  useEffect(() => {
    procedureHeaderStore.resetHeaderProcedure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer>
      {alias === 'alta_daruma' ? (
        <InitialDaruma
          open={open}
          onClose={() => setOpen(false)}
          onJoin={() => setOpen(false)}
        />
      ) : (
        <ProcedureModal
          imgHeader={headerImg}
          redirectTo={`/${procedure}/${alias}/seleccion`}
          markdownTemplate={markdownTemplate || ''}
          logos={(images || ['']) as string[]}
          title={productName || ''}
          subtitle="" // Hardcoded
          open={open}
          onClose={() => setOpen(false)}
          onlyRead
          alias={alias as string}
        />
      )}

      <StyledProcedureInfo status={status || 'ongoing'} className="elevation2">
        <Stack
          direction="row"
          gap="8px"
          justifyContent="space-between"
          alignItems="center"
        >
          {alias && (
            <>
              <Box width="42px" height="42px">
                <StyledIcon
                  alt="icon"
                  status={(status || 'ongoing') as ProcedureStatus}
                  src={sessionStore.getMenuIconByUrl(basePath)}
                />
              </Box>
              <Stack direction="column" gap="8px">
                <Box display={{ xs: 'none', tablet: 'inline' }}>
                  <Link to={basePath}>
                    <H6
                      color={
                        status === 'ongoing'
                          ? colors.tertiaryBase
                          : colors.white
                      }
                      style={{ textDecoration: 'underline' }}
                    >
                      {procedure.charAt(0).toUpperCase() + procedure.slice(1)}
                    </H6>
                  </Link>
                </Box>
                <Stack
                  direction={{
                    xs: 'column',
                    tablet: 'row',
                  }}
                  gap="8px"
                >
                  <Box display={{ xs: 'none', tablet: 'inline' }}>
                    <H4
                      color={
                        status === 'ongoing'
                          ? colors.tertiaryBase
                          : colors.white
                      }
                    >
                      {productName}
                    </H4>
                  </Box>
                  <Box display={{ xs: 'inline', tablet: 'none' }}>
                    <H6
                      color={
                        status === 'ongoing'
                          ? colors.tertiaryBase
                          : colors.white
                      }
                    >
                      {productName}
                    </H6>
                  </Box>
                  <LinkBtn onClick={() => setOpen(true)}>
                    <InfoOutlinedIcon
                      sx={{
                        width: '19px',
                        height: '19px',
                        color:
                          status === 'ongoing'
                            ? colors.complementaryGreen
                            : colors.white,
                      }}
                    />
                    <H6
                      style={{
                        marginLeft: '8px',
                        color:
                          status === 'ongoing'
                            ? colors.complementaryGreen
                            : colors.white,
                        textDecoration: 'underline',
                        textUnderlineOffset: '1px',
                      }}
                    >
                      Más info
                    </H6>
                  </LinkBtn>
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" gap="8px">
          {status === 'ongoing' &&
            images.map((image, index) => (
              <img
                src={baseApi + image}
                alt="logo"
                className="logo"
                key={`img-${index + 1 - 1}`}
                loading="lazy"
              />
            ))}
        </Stack>
      </StyledProcedureInfo>

      <StyledChildrenContainer id="content-scroll">
        <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
      </StyledChildrenContainer>
    </StyledContainer>
  );
};

export default observer(ProcedureLayout);
