/* eslint-disable no-return-await */
import { Box, Grid } from '@mui/material';
import { H4, P3 } from 'components/common/typography/styles';
import { CommonCardHome } from 'components/sections/cards/home/commonCardHome';
import { Grass } from '@mui/icons-material';
import { Maletin } from 'components/common/icon/icons/maletinIcon';
import colors from 'theme/colors';
import { FeaturedProducts } from 'components/common/cards/productosDestacados';
import ProcedureModal from 'components/common/modals/procedureModal';
import { SpinnerXL } from 'components/common/icon/icons/spinner';
import { useExternalScreenHook } from './useExternalScreen';
import { ProcedureAvailable } from 'models/procedure';

export const ExternalUserScreen = () => {
  const { state, action } = useExternalScreenHook();
  const {
    areThereCompanies,
    areThereFields,
    completeCompanies,
    completeFields,
    companiesByState,
    incompleteCompanies,
    incompleteFields,
    loadingCompanies,
    loadingFields,
    loadingProdcedures,
    open,
    procedureSelected,
    procedures,
    redirectWithAliasProcedure,
  } = state;
  const { handleClose, handleOpen, navigate } = action;

  if (
    procedures === undefined ||
    (loadingFields && loadingCompanies && loadingProdcedures)
  ) {
    return (
      <Box
        width="100%"
        height="calc(50vh)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SpinnerXL />
      </Box>
    );
  }

  return (
    <Grid container direction="column">
      {procedureSelected && (
        <ProcedureModal
          imgHeader={procedureSelected.imgHeader}
          redirectTo={`/${redirectWithAliasProcedure}/${procedureSelected.alias}`}
          markdownTemplate={procedureSelected.markdownTemplate}
          logos={[procedureSelected.entidad.logo ?? '']}
          title={procedureSelected.nombre}
          subtitle={procedureSelected.entidad.nombre}
          open={open}
          onClose={handleClose}
          onlyRead={false}
          alias={procedureSelected.alias}
        />
      )}
      <Grid item xs>
        <Box marginBottom="10px">
          <H4>Administrar mis datos</H4>
        </Box>
        <P3 style={{ color: colors.tertiaryMedium }}>
          Mantené tus campos y empresas actualizados para poder realizar
          operaciones
        </P3>
      </Grid>
      <Grid container margin="16px 0px 32px 0px" gap="16px" flexWrap="nowrap">
        <Grid item xs={6}>
          <CommonCardHome
            icon={
              <Grass
                sx={{
                  color: colors.tertiaryBase,
                  width: '50px',
                  height: '50px',
                }}
              />
            }
            description="Todavía no cargaste ningún campo"
            title="Mis Campos"
            complete={completeFields}
            incomplete={incompleteFields}
            navigate={() => navigate('/misdatos/campos')}
            isThereData={areThereFields}
          />
        </Grid>
        <Grid item xs={6}>
          <CommonCardHome
            icon={
              <Maletin color={colors.tertiaryBase} width="50px" height="50px" />
            }
            description="Todavía no cargaste ninguna empresa"
            title="Mis Empresas"
            complete={completeCompanies}
            incomplete={incompleteCompanies}
            navigate={() => navigate('/micuenta')}
            isThereData={areThereCompanies}
          />
        </Grid>
      </Grid>
      <Grid item marginBottom="16px">
        <H4>Destacado</H4>
      </Grid>
      <Grid container spacing="16px" justifyContent="space-between">
        {procedures.map((procedure: ProcedureAvailable) => {
          return (
            <Grid item xs={12} tablet={6} key={procedure.alias}>
              <FeaturedProducts
                procedure={procedure}
                backgroundImage={`${import.meta.env.VITE_API_BASE_URL}${
                  procedure.imgHeader
                }`}
                bgImage={procedure.imgHeader}
                imgLogo={procedure.entidad.logo as string}
                nombre={procedure.nombre}
                companies={companiesByState}
                nombreEntidad={procedure.entidad.nombre}
                alias={procedure.alias}
                leyenda={procedure.leyenda}
                setProcedure={() => handleOpen(procedure)}
                aliasTipoTramite={procedure.subTipoTramite.tipoTramite.alias}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
