import { SancorAction, InitialStateSancor } from './index.types';

export default function sancorReducer(
  initialValues: InitialStateSancor,
  action: SancorAction,
) {
  switch (action.type) {
    case 'set-stateProcedure': {
      return { ...initialValues, stateProcedure: action.value };
    }

    case 'set-refused': {
      return { ...initialValues, refused: action.value };
    }

    case 'set-moveForward': {
      return { ...initialValues, moveForward: action.value };
    }

    case 'set-routeState': {
      return { ...initialValues, routeState: action.value };
    }

    case 'set-selectedFields': {
      return { ...initialValues, selectedFields: action.value };
    }

    case 'set-cuitApplicant': {
      return { ...initialValues, cuitApplicant: action.value };
    }

    case 'set-social': {
      return { ...initialValues, social: action.value };
    }

    case 'set-nameInsurance': {
      return { ...initialValues, nameInsurance: action.value };
    }

    case 'clear-state': {
      return initialValues; // check what to return here
    }

    default:
      return initialValues;
  }
}
