import { FinSimpleAction, InitialStateFinSimple } from './index.types';

export default function finSimpleReducer(
  initialValues: InitialStateFinSimple,
  action: FinSimpleAction,
) {
  switch (action.type) {
    case 'set-stateProcedure': {
      return { ...initialValues, stateProcedure: action.value };
    }

    case 'set-refused': {
      return { ...initialValues, refused: action.value };
    }

    case 'set-moveForward': {
      return { ...initialValues, moveForward: action.value };
    }

    case 'set-productName': {
      return { ...initialValues, productName: action.value };
    }

    case 'set-routeState': {
      return { ...initialValues, routeState: action.value };
    }

    default:
      return initialValues;
  }
}
