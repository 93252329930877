import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledChip = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '2px',
  borderRadius: '100px',
  width: 'fit-content',
  padding: '4px 12px',

  '& > p': {
    textTransform: 'none',
    fontWeight: '500',
  },
}));
