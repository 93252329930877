import { FinSimpleStateContext, FinSimpleDispatchContext } from './index';
import { useContext } from 'react';

export function useFinSimpleContext() {
  const state = useContext(FinSimpleStateContext);
  const dispatch = useContext(FinSimpleDispatchContext);
  if (!state || !dispatch) {
    throw new Error('useFinSimpleContext debe ser usado con FinSimpleProvider');
  }
  return {
    state,
    dispatch,
  };
}
