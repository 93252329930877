/* eslint-disable no-nested-ternary */
import React from 'react';
import { Select, Box } from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { P2, P3, P4 } from 'components/common/typography/styles';
import CustomTooltip from 'components/common/tooltips';
import AlborSyncIcon from 'components/common/icon/icons/alborSyncIcon';
import colors from 'theme/colors';
import { addFormikSelectProps } from 'hooks/useAddFormikSelectProps';
import { StyledLabel, StyledError, StyledSelect } from '../index.styled';
import {
  ItemSelectP4,
  ItemSelectP3,
  ItemSelectContainer,
} from './index.styled';
import { ICuit } from 'models/models.daruma';

type OptionsSimple = {
  value: number | string | any;
  label: string;
};

type OptionsDouble = OptionsSimple & {
  subValue: number | string;
};

interface ISelectInput {
  id: string;
  label: string | React.ReactNode;
  synced?: boolean;
  disabled: boolean;
  options: Array<OptionsSimple | OptionsDouble>;
  handleChange?: (id: string | ICuit) => void;
  explanation?: string | undefined;
  name: string;
  width?: string;
  height?: string;
  placeholder?: string;
  customError?: string;
  defaultValue?: string | null | boolean;
}

const SelectInput: React.FC<ISelectInput> = (props) => {
  const {
    id,
    label,
    disabled,
    options,
    handleChange = () => null,
    name,
    explanation,
    synced = false,
    width = '383px',
    height = '48px',
    placeholder = 'Seleccioná una opción',
    customError,
    defaultValue = '',
  } = props;
  const { error, value, onChange } = addFormikSelectProps(name, options, false);

  return (
    <Box
      style={{
        marginBottom: error || customError || explanation ? '16px' : '0px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StyledLabel htmlFor={id}>
        <P3 color={colors.tertiaryBase}>{label}</P3>
      </StyledLabel>
      <Select
        name={name}
        disabled={disabled}
        id={id}
        error={error}
        value={value || defaultValue}
        onChange={(event, child) => {
          if (handleChange) handleChange(event.target.value as string);
          onChange(event, child);
        }}
        displayEmpty
        renderValue={(selected: string | number) => {
          if (selected === 0 || selected === '') {
            return (
              <P2 style={{ color: colors.tertiaryMedium }}>{placeholder}</P2>
            );
          }
          const findForID = options.find((item) => item.value === selected);
          return findForID?.label;
        }}
        IconComponent={
          synced
            ? () => (
                <Box position="absolute" right="7px" top="calc(50% - 1rem)">
                  <AlborSyncIcon width="35" height="35" />
                </Box>
              )
            : ArrowDropDownRoundedIcon
        }
        input={<StyledSelect width={width} height={height} />}
      >
        {options.map((option) => {
          if ('subValue' in option) {
            return (
              <ItemSelectContainer
                value={option.value}
                key={option.value}
                className="selectOption"
              >
                <CustomTooltip
                  title={option.label}
                  arrowLocation="bottom-start"
                >
                  <ItemSelectP4>{option.label}</ItemSelectP4>
                </CustomTooltip>
                <ItemSelectP4>{option.subValue}</ItemSelectP4>
              </ItemSelectContainer>
            );
          }
          return (
            <ItemSelectContainer
              value={option.value}
              key={option.value}
              className="selectOption"
            >
              <ItemSelectP3>{option.label}</ItemSelectP3>
            </ItemSelectContainer>
          );
        })}
      </Select>
      {(error || customError) && (
        <StyledError>
          <P4 color={colors.complementaryError}>{error || customError}</P4>
        </StyledError>
      )}
      {explanation && (
        <StyledError>
          <P4 color={colors.tertiaryMedium}>{explanation}</P4>
        </StyledError>
      )}
    </Box>
  );
};

export default SelectInput;
