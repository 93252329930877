import {
  Sun,
  LightCloud,
  PartlyCloud,
  Cloud,
  LightRainSun,
  LightRainThunderSun,
  SleetSun,
  SnowSun,
  LightRain,
  Rain,
  RainThunder,
  Sleet,
  Snow,
  SnowThunder,
  Fog,
  SleetSunThunder,
  SnowSunThunder,
  LightRainThunder,
  SleetThunder,
  DrizzleThunderSun,
  RainThunderSun,
  LightSleetThunderSun,
  HeavySleetThunderSun,
  LightSnowThunderSun,
  HeavySnowThunderSun,
  DrizzleThunder,
  LightSleetThunder,
  HeavySleetThunder,
  LightSnowThunder,
  HeavySnowThunder,
  DrizzleSun,
  RainSun,
  LightSleetSun,
  HeavySleetSun,
  LightSnowSun,
  HeavysnowSun,
  Drizzle,
  LightSleet,
  HeavySleet,
  LightSnow,
  HeavySnow,
  Clear,
} from './weather.icons';

export const getShortDateAndDayName = (date: number) => {
  const dateStr = date.toString();
  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(4, 6);
  const day = dateStr.slice(6, 8);

  const newFromatDate = [month, day, year].join('/');
  const event = new Date(newFromatDate);
  const shortDate = [day, month].join('/');

  return {
    dayName: event.toLocaleDateString('es-AR', { weekday: 'short' }),
    shortDate,
  };
};

export const buildArrObjWeather = (weatherApiData: any) =>
  Object.entries(weatherApiData);

export const arrWeatherFormatted = (weatherData: any) => {
  return weatherData.map(([key, value]: any[]) => {
    return {
      ...value,
      ...getShortDateAndDayName(key),
    };
  });
};

interface IconWeather {
  [icon: string]: string;
}

export const getIconWeather = (iconName: string) => {
  const icons: IconWeather = {
    Sun,
    LightCloud,
    PartlyCloud,
    Cloud,
    LightRainSun,
    LightRainThunderSun,
    SleetSun,
    SnowSun,
    LightRain,
    Rain,
    RainThunder,
    Sleet,
    Snow,
    SnowThunder,
    Fog,
    SleetSunThunder,
    SnowSunThunder,
    LightRainThunder,
    SleetThunder,
    DrizzleThunderSun,
    RainThunderSun,
    LightSleetThunderSun,
    HeavySleetThunderSun,
    LightSnowThunderSun,
    HeavySnowThunderSun,
    DrizzleThunder,
    LightSleetThunder,
    HeavySleetThunder,
    LightSnowThunder,
    HeavySnowThunder,
    DrizzleSun,
    RainSun,
    LightSleetSun,
    HeavySleetSun,
    LightSnowSun,
    HeavysnowSun,
    Drizzle,
    LightSleet,
    HeavySleet,
    LightSnow,
    HeavySnow,
    Clear,
  };
  return icons[iconName] || Clear;
};

export const CapitalizeLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const MIS_CAMPOS_PATHNAME = '/miscampos';
