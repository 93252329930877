import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { H1, P2, P3 } from 'components/common/typography/styles';
import LinkBtn from 'components/common/buttons/linkBtn';
import colors from 'theme/colors';
import { AuthContainer } from '../index.styled';

const RequestAccountDone: React.FC = () => {
  const navigate = useNavigate();

  return (
    <AuthContainer>
      <Box className="wrapper-auth">
        <H1 className="auth-title">Solicitud recibida</H1>
        <P2 className="auth-parag">
          Hemos recibido tu solicitud para sumarte a Agrology. En breve nos
          estaremos contactando con vos para terminar el proceso de alta.
          ¡Gracias por querer ser parte de nuestra comunidad!
        </P2>
        <LinkBtn onClick={() => navigate('/portal/iniciar-sesion')}>
          <P3
            style={{
              color: colors.primaryBase,
              textDecoration: 'underline',
              textUnderlineOffset: '1px',
              marginTop: '23px',
            }}
          >
            Volver al inicio
          </P3>
        </LinkBtn>
      </Box>
    </AuthContainer>
  );
};

export default RequestAccountDone;
