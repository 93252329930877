import React, { Suspense } from 'react';
import { SpinnerSM } from 'components/common/icon/icons/spinner';
import RoutesWrapper from 'components/layouts/RoutesWrapper';

export const ProcedureStepSuspense = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Suspense
      fallback={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SpinnerSM />
        </div>
      }
    >
      <RoutesWrapper>{children}</RoutesWrapper>
    </Suspense>
  );
};
