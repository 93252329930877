import React, { Suspense, useState } from 'react';
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Stack,
} from '@mui/material';
import GrassRoundedIcon from '@mui/icons-material/GrassRounded';
import EditIcon from '@mui/icons-material/Edit';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import LayersIcon from '@mui/icons-material/Layers';
import RainyCloud from 'components/common/icon/icons/rainyCloud';
import {
  P3Ellipsis,
  P4,
  P4Ellipsis,
} from 'components/common/typography/styles';
import { GridPatternBreaks, PartialGridPatternValues } from 'models/fields';
import colors from 'theme/colors';
import api from 'services/api/api';
import { arrWeatherFormatted, buildArrObjWeather } from 'utils/utilsWeather';
import Weather from 'components/common/weather';
import WeatherButton from 'components/common/tables/Fields/buttons/weather';
import { SpinnerSM } from 'components/common/icon/icons/spinner';
import InputCheckbox from 'components/common/inputs/checkbox';
import FieldDataButton from 'components/common/buttons/fieldDataBtn';
import EventBtn from 'components/common/buttons/eventBtn';
import OptionsBtn from 'components/common/buttons/optionsBtn';
import CultivoIcon from 'components/common/icon/icons/cultivoIcon';
import { ScreenField, ValidationResult } from 'store/fields-store/fields-store';

interface IGridField {
  field: any; // TODO cambiar por el type Field;
  fieldValidation: ValidationResult;
  gridPattern: { [key in GridPatternBreaks]: PartialGridPatternValues };
  checked?: boolean;
  grows?: { [key: string]: boolean };
  withWeather?: boolean;
  withCheckbox?: boolean;
  splitioClima: boolean;
  screen?: ScreenField;
  setChecked?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  openMap?: () => void;
  openEdit?: () => void;
}

const GridField: React.FC<IGridField> = (props) => {
  const {
    field,
    fieldValidation,
    gridPattern,
    checked = false,
    grows,
    withWeather = false,
    withCheckbox = false,
    splitioClima,
    screen,
    setChecked,
    openMap,
    openEdit,
  } = props;

  const [isOpenWeather, setOpenWeather] = useState(false);
  const [dataWeather, setDataWeather] = useState<any>([]); // TODO: Type
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const { xs, tablet } = gridPattern;
  const provincia = field.localidad?.partido.provincia.nombre;
  const partido = field.localidad?.partido.nombre;
  const localidad = field.localidad?.nombre;
  const underName = `${localidad}, ${partido}, ${provincia}`;
  const hasPolygon = field.poligono && field.poligono.length > 0;

  const { error, isWarning } = fieldValidation;

  const getWeather = async () => {
    const { data } = await api.weather.getWeatherInfo(field.id);
    const arrObjWeather = buildArrObjWeather(data);
    const newArrWeatherFormatted = arrWeatherFormatted(arrObjWeather);
    setDataWeather(newArrWeatherFormatted);
  };

  const firstLetterUpperCase = (text: string) => {
    return text
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const onHandleOpenWeather = () => {
    if (!isOpenWeather) {
      getWeather();
    } else {
      setDataWeather([]);
    }
    setOpenWeather(!isOpenWeather);
  };

  const getColorLegend = () => {
    if (error && checked && !isWarning) {
      return colors.complementaryError;
    }

    if (error && checked && isWarning) {
      return colors.complementaryYellow;
    }

    return colors.tertiaryMedium;
  };

  return (
    <Paper
      sx={{
        width: '100%',
        border: `2px solid ${
          error && !isWarning ? colors.tertiaryIntermediate : colors.white
        }`,
        bgcolor: error && !isWarning ? colors.transparent : colors.white,
        borderRadius: '8px',
      }}
      elevation={error && !isWarning ? 0 : 2}
    >
      <Grid container minHeight="65px" alignItems="center" px="16px" py="12px">
        {withCheckbox && (
          <Grid item xs={xs.checkbox}>
            <InputCheckbox
              defaultChecked={false}
              disabled={false}
              checked={!!checked}
              name="field"
              error={Boolean(error)}
              warning={isWarning}
              onChange={setChecked}
            />
          </Grid>
        )}

        {xs.icon && (
          <Grid item sx={{ width: '60px' }}>
            <Box display="flex" justifyContent="center">
              {!error ? (
                <GrassRoundedIcon
                  sx={{
                    color: colors.tertiaryBase,
                    width: '40px',
                    height: '40px',
                  }}
                />
              ) : (
                <WarningRoundedIcon
                  sx={{
                    color:
                      checked && !isWarning
                        ? colors.complementaryError
                        : checked && isWarning
                          ? colors.complementaryYellow
                          : colors.tertiaryBase,
                    width: '40px',
                    height: '40px',
                  }}
                />
              )}
            </Box>
          </Grid>
        )}

        <Grid item xs={xs.name} tablet={tablet.name}>
          <Stack direction="column" pr="16px">
            <P3Ellipsis
              color={colors.tertiaryBase}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              {firstLetterUpperCase(field.nombre)}{' '}
              {field.razonSocial
                ? ` - ${firstLetterUpperCase(field.razonSocial)}`
                : ''}
            </P3Ellipsis>
            <FieldNameMobile
              fieldName={firstLetterUpperCase(field.nombre)}
              razonSocial={field.razonSocial}
            />
            <Box display={{ xs: error ? 'none' : 'block', tablet: 'block' }}>
              <P4Ellipsis color={getColorLegend()}>
                {error || firstLetterUpperCase(underName)}
              </P4Ellipsis>
            </Box>
          </Stack>
        </Grid>

        {grows && xs.grows && (
          <Grid item xs={xs.grows}>
            <Box
              display="flex"
              justifyContent="start"
              ml={{
                sm: 3,
                md: 7,
              }}
            >
              {Object.entries(grows).map((grow) => (
                <CultivoIcon
                  nombre={grow[0]}
                  preset={grow[1] ? 'active' : 'disabled'}
                  key={grow[0]}
                />
              ))}
            </Box>
          </Grid>
        )}

        <Grid item xs>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="8px"
          >
            {openMap && !error && (
              <Box
                display={{
                  xs: 'none',
                  tablet: 'block',
                }}
              >
                <EventBtn
                  onPress={openMap}
                  text="Ver en mapa"
                  primary={false}
                  icon={
                    <LayersIcon
                      sx={{
                        color: colors.primaryBase,
                        fontSize: 16,
                        marginRight: '4px',
                      }}
                    />
                  }
                />
              </Box>
            )}

            {openEdit && (
              <Box
                display={{
                  xs: error && !isWarning ? 'block' : 'none',
                  tablet: 'block',
                }}
              >
                <FieldDataButton
                  hasError={Boolean(error && !isWarning)}
                  onPress={openEdit}
                />
              </Box>
            )}

            {withWeather && splitioClima && !error ? (
              <WeatherButton
                showWeather={isOpenWeather}
                hasPolygon
                handleWeather={onHandleOpenWeather}
              />
            ) : null}
          </Box>

          <Box
            display={{
              xs: 'flex',
              tablet: 'none',
            }}
            justifyContent="flex-end"
          >
            {hasPolygon && !error && (
              <Box
                display={{
                  xs: 'block',
                  tablet: 'none',
                }}
              >
                <OptionsBtn onOpen={handleOpenPopover} />
                <Popover
                  open={open}
                  onClose={handleClosePopover}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <List>
                    <ListItem disablePadding divider>
                      <ListItemButton
                        disableRipple
                        onClick={() => {
                          openEdit?.();
                          handleClosePopover();
                        }}
                      >
                        <ListItemText>
                          <P4
                            style={{
                              fontWeight: 500,
                              color: colors.tertiaryLow,
                            }}
                          >
                            Modificar datos
                          </P4>
                        </ListItemText>
                        <ListItemIcon
                          sx={{
                            justifyContent: 'flex-end',
                          }}
                        >
                          <EditIcon
                            sx={{
                              fontSize: 16,
                              color: colors.black,
                            }}
                          />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding divider={screen === 'MIS_CAMPOS'}>
                      <ListItemButton
                        disableRipple
                        onClick={() => {
                          openMap?.();
                          handleClosePopover();
                        }}
                      >
                        <ListItemText>
                          <P4
                            style={{
                              fontWeight: 500,
                              color: colors.tertiaryLow,
                            }}
                          >
                            Ver en mapa
                          </P4>
                        </ListItemText>
                        <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                          <LayersIcon
                            sx={{
                              fontSize: 16,
                              color: colors.black,
                            }}
                          />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>

                    {screen === 'MIS_CAMPOS' ? (
                      <ListItem disablePadding>
                        <ListItemButton
                          disableRipple
                          onClick={() => {
                            onHandleOpenWeather();
                            handleClosePopover();
                          }}
                        >
                          <ListItemText>
                            <P4
                              style={{
                                fontWeight: 500,
                                color: colors.tertiaryLow,
                              }}
                            >
                              {!isOpenWeather
                                ? 'Mostrar pronóstico'
                                : 'Ocultar pronóstico'}
                            </P4>
                          </ListItemText>
                          <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                            <RainyCloud
                              height="16px"
                              width="16px"
                              color={colors.black}
                            />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    ) : null}
                  </List>
                </Popover>
              </Box>
            )}
          </Box>
        </Grid>

        {dataWeather && withWeather && (
          <Grid item xs={12}>
            <Collapse in={isOpenWeather}>
              <Suspense fallback={<SpinnerWeather />}>
                {!dataWeather.length && <SpinnerWeather />}
                {Boolean(dataWeather.length) && (
                  <Weather arrWeather={dataWeather} />
                )}
              </Suspense>
            </Collapse>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          display={{ xs: error ? 'flex' : 'none', tablet: 'none' }}
          mt="8px"
        >
          <P4 color={getColorLegend()}>
            {error || firstLetterUpperCase(underName)}
          </P4>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GridField;

const SpinnerWeather: React.FC = () => (
  <Box width="100%" display="flex" justifyContent="center" alignItems="center">
    <SpinnerSM />
  </Box>
);

const FieldNameMobile: React.FC<{
  fieldName: string;
  razonSocial: string | null;
}> = ({ fieldName, razonSocial }) => {
  return (
    <>
      <P3Ellipsis color={colors.tertiaryBase} sx={{ display: { sm: 'none' } }}>
        {fieldName}
      </P3Ellipsis>
      <P3Ellipsis sx={{ display: { sm: 'none' } }}>{razonSocial}</P3Ellipsis>
    </>
  );
};
