import { useContext } from 'react';
import {
  HuellaDeCarbonoStateContext,
  HuellaDeCarbonoDispatchContext,
} from 'contexts/HuellaDeCarbono/index';

export function useHuellaDeCarbonoContext() {
  const state = useContext(HuellaDeCarbonoStateContext);
  const dispatch = useContext(HuellaDeCarbonoDispatchContext);
  if (!state || !dispatch) {
    throw new Error('usePumaContext debe ser usado con PumaProvider');
  }
  return {
    state,
    dispatch,
  };
}