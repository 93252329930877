import { Inputs } from 'models/dynamicForms';

export enum FieldNames {
  fieldNames = 'names',
  fieldLastNames = 'lastNames',
  fieldEmail = 'email',
  fieldPhone = 'phone',
  fieldDomain = 'domain',
}

export const inputsForm: Inputs = [
  [
    {
      id: FieldNames.fieldNames as string,
      name: FieldNames.fieldNames as string,
      disabled: false,
      label: 'Nombres',
      placeholder: 'Ingresá tus nombres',
      type: 'text',
    },
    {
      id: FieldNames.fieldLastNames as string,
      name: FieldNames.fieldLastNames as string,
      disabled: false,
      label: 'Apellidos',
      placeholder: 'Ingresá tus apellidos',
      type: 'text',
    },
    {
      id: FieldNames.fieldEmail as string,
      name: FieldNames.fieldEmail as string,
      disabled: false,
      label: 'Email',
      placeholder: 'Ingresá tu email',
      type: 'email',
    },
    {
      id: FieldNames.fieldPhone as string,
      name: FieldNames.fieldPhone as string,
      disabled: false,
      label: 'Teléfono',
      placeholder: 'Ingresá tu teléfono (opcional)',
      type: 'text',
    },
    {
      id: FieldNames.fieldDomain as string,
      name: FieldNames.fieldDomain as string,
      disabled: false,
      label: 'Empresa o Cuenta ERP',
      placeholder: 'Ingresá tu empresa o cuenta ERP',
      type: 'text',
    },
    {
      type: 'button',
      text: 'Solicitar cuenta',
      variant: 'contained',
      size: 'large',
      preset: 'normal',
      fullWidth: true,
    },
  ],
  [],
];
