import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export interface ApiListResponse<T> {
  data: T[];
}

export class CertificationsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getInfoCUIT(cuit: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.get(
        `/certificaciones/firmante/${cuit}`,
      );
      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async putInfoCUIT(form: any, cuit: string): Promise<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Token ${this.api.token}`,
    };

    try {
      const response: ApiResponse<never> =
        await this.api.apisauce.axiosInstance.put(
          `/certificaciones/firmante/${cuit}`,
          form,
          { headers },
        );
      if (response.status !== 200) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async postInfoCUIT(form: any): Promise<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Token ${this.api.token}`,
    };

    try {
      const response: ApiResponse<never> =
        await this.api.apisauce.axiosInstance.post(
          `/certificaciones/firmante/`,
          form,
          { headers },
        );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async validateInsumos(code: string): Promise<any> {
    try {
      const response: ApiResponse<never> = await this.api.apisauce.post(
        `/tramites/certificacion/cert_emisiones/${code}/validar_cultivos/`,
        {},
      );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const { data } = response;
      if (data) return data;
      return response;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
