import { Box, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import { Title } from '../alerts/index.styled';
import colors from 'theme/colors';
import { P3 } from '../typography/styles';
import Button from '../buttons/button';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  title: string;
  name: string;
  description: string;
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  isLoading: boolean;
};

const DeleteFieldModal = ({
  open,
  handleClose,
  title,
  name,
  handleDelete,
  description,
  isLoading = false,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      PaperProps={{ elevation: 24 }}
    >
      <DialogTitle sx={{ padding: '23px 24px 16px 24px' }}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <DeleteIcon sx={{ color: colors.complementaryError }} />
          <Title sx={{ color: colors.complementaryError }}>{title}</Title>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          paddingX: '34px',
        }}
      >
        <Stack gap="24px" mb="19px">
          <P3 color={colors.tertiaryMedium}>
            {description} <b>{name}</b>
          </P3>
          <P3 color={colors.tertiaryMedium}>
            Esta acción no se puede revertir.
          </P3>
        </Stack>

        <Stack
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          }}
          justifyContent="space-between"
          gap="13px"
        >
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '100px',
              },
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              preset="normal"
              size="small"
              onClick={handleClose}
              text="Cancelar"
              disabled={isLoading}
            />
          </Box>
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '163px',
              },
            }}
          >
            <Button
              fullWidth
              variant="contained"
              preset="danger"
              size="small"
              onClick={handleDelete}
              text="Eliminar"
              disabled={isLoading}
              loading={isLoading}
            />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteFieldModal;
